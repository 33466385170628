import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import StartTimeFields from '../../ReusableFormFields/StartTimeFields'

import { START_DATE, START_TIME } from '../../ReusableFormFields/StartTimeFields/fields'

import useStepFormStyles from '../../../styles/common/stepForms'

const stepFields = [START_TIME, START_DATE]

const LineItemStartTimeStep = ({ formik, handleStepChange, stepTitle }) => {
  const stepFormClasses = useStepFormStyles()

  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={stepFormClasses.stepTitle}>{t(stepTitle)}</div>
      <StartTimeFields formik={formik} />
      <div className={stepFormClasses.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default LineItemStartTimeStep
