import React, { useCallback, useContext } from 'react'

import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'

import {
  BUYER_DISAPPROVED,
  DISAPPROVED,
  PENDING_APPROVAL,
  PENDING_BUYER_APPROVAL
} from '../../../../../../../features/components/UploadedFilesField/helpers'

export const FILE_STATUS = 'fileStatus'

export const pendingApprovalOption = {
  label: 'Pending Approval',
  value: PENDING_APPROVAL
}

export const pendingBuyerApprovalOption = {
  label: 'Pending Buyer Approval',
  value: PENDING_BUYER_APPROVAL
}

const pendingApprovalStatusOptions = [
  pendingApprovalOption,
  pendingBuyerApprovalOption,
  {
    label: 'Disapproved',
    value: DISAPPROVED
  },
  {
    label: 'Buyer Disapproved',
    value: BUYER_DISAPPROVED
  }
]

const FileStatusFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [FILE_STATUS]: fileStatus } = filtersState

  const onFileStatusChange = useCallback(
    option => {
      setFiltersState({
        ...filtersState,
        [FILE_STATUS]: option
      })
    },
    [filtersState, setFiltersState]
  )

  const setFileStatusesHandler = useCallback(
    (name, data) => {
      onFileStatusChange(data)
    },
    [onFileStatusChange]
  )

  return (
    <MultiSelectBox
      name="Status"
      options={pendingApprovalStatusOptions}
      value={fileStatus}
      onChange={onFileStatusChange}
      placeholder="File status"
      isSearchable={false}
      setFieldValue={setFileStatusesHandler}
      portaled={true}
    />
  )
}

export default FileStatusFilter
