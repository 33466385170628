import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'
import MinimisedSectionNew from '../../../../../../../components/MinimisedSectionNew'
import MinimisedSectionHeader from '../../../../../../../components/MinimisedSectionNew/MinimisedSectionHeader'
import BrandLogoUpload from './BrandLogoUpload'
import CategoriesRows from './CategoriesRows'

import {
  BRAND_ID,
  BRAND_NAME,
  LANDSCAPE_LOGO,
  LANDSCAPE_LOGO_FILE_NAME,
  SQUARE_LOGO,
  SQUARE_LOGO_FILE_NAME
} from '../../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import { keys } from '../../../../../../../helpers/common'
import { isStepFieldsValid } from '../../../../../../../features/components/Forms/StepForm/helpers'

import {
  landscapeLogoMinHeight,
  landscapeLogoMinWidth,
  landscapeLogoRatio,
  getLandscapeLogoValidationSchema,
  squareLogoMinHeight,
  squareLogoMinWidth,
  squareLogoRatio,
  getSquareLogoValidationSchema
} from '../../../../../../../forms/Google/AdForms/validations'

import useStyles from './styles'

const BrandsSection = ({
  formik,
  itemPath,
  selected,
  onSelect,
  onClose,
  onDelete,
  allowDelete,
  isEditForm = false
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, errors, touched } = formik

  const brandNameValue = getIn(values, `${itemPath}.${BRAND_NAME}`)
  const sectionError = getIn(errors, itemPath)
  const sectionTouched = getIn(touched, itemPath)

  // determine if section has errors only by checking touched fields
  const isSectionValid = sectionTouched && sectionError ? isStepFieldsValid(sectionError, keys(sectionTouched)) : true

  const onClickHandler = useCallback(() => {
    if (selected) {
      onClose()
    } else {
      onSelect()
    }
  }, [selected, onSelect, onClose])

  const squareLogoValidationSchema = useMemo(() => getSquareLogoValidationSchema(), [])
  const landscapeLogoValidationSchema = useMemo(() => getLandscapeLogoValidationSchema(), [])

  return (
    <MinimisedSectionNew
      headerContent={
        <MinimisedSectionHeader
          title={brandNameValue || t('Set brand name')}
          onDelete={allowDelete ? onDelete : undefined}
          deleteBtnText="Remove brand"
        />
      }
      className={classes.brandSection}
      headerClassName={classes.brandSectionHeader}
      isOpened={selected}
      onHeaderClick={onClickHandler}
      hasError={!isSectionValid}
    >
      <FieldRow title={t('Brand name')}>
        <Field formik={formik} name={`${itemPath}.${BRAND_NAME}`} placeholder={t('Brand name')} />
      </FieldRow>
      <FieldRow title={t('Brand id')}>
        <Field formik={formik} name={`${itemPath}.${BRAND_ID}`} placeholder={t('Brand id')} />
      </FieldRow>
      <CategoriesRows formik={formik} itemPath={itemPath} isEditForm={isEditForm} isActive={selected} />
      {/*currently logo are used inside the google ads creation forms so the validation rules are set according to
       these requirements*/}
      <FieldRow
        title="Square logo"
        description={`Ratio ${squareLogoRatio}:1, min ${squareLogoMinWidth}x${squareLogoMinHeight}`}
      >
        <BrandLogoUpload
          formik={formik}
          logoUrlPath={`${itemPath}.${SQUARE_LOGO}`}
          logoFileNamePath={`${itemPath}.${SQUARE_LOGO_FILE_NAME}`}
          logoValidationSchema={squareLogoValidationSchema}
        />
      </FieldRow>
      <FieldRow
        title="Landscape logo"
        description={`Ratio ${landscapeLogoRatio}:1, min ${landscapeLogoMinWidth}x${landscapeLogoMinHeight}`}
      >
        <BrandLogoUpload
          formik={formik}
          logoUrlPath={`${itemPath}.${LANDSCAPE_LOGO}`}
          logoFileNamePath={`${itemPath}.${LANDSCAPE_LOGO_FILE_NAME}`}
          logoValidationSchema={landscapeLogoValidationSchema}
        />
      </FieldRow>
    </MinimisedSectionNew>
  )
}

BrandsSection.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  isEditForm: PropTypes.bool
}

export default BrandsSection
