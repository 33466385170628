import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import GenderFields from '../../../../../ReusableFormFields/GenderFields'

import useStyles from '../../../../../../styles/common/stepForms'

const GenderStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('What genders would you like to target?')}</div>
      <div className={classes.stepBody}>
        <GenderFields values={values} setFieldValue={setFieldValue} />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={() => handleStepChange()}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default GenderStep
