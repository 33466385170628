import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import CostCell from './CostCell'
import ActionsCell from './ActionsCell'
import DatesCell from './DatesCell'
import PeriodsCell from './PeriodsCell'
import DiscountCell from './DiscountCell'
import QuantityCell from './QuantityCell'
import FooterSummary from './FooterSummary'
import ProductSelection from './ProductSelection'
import CategorySelection from './CategorySelection'
import Table from '../../../../../../components/Table'
import { ReactComponent as DragableDots } from '../../../../../../assets/icons/draggable-dots.svg'

import { defaultTableSort, ContractBuilderContext } from '../ContractBuilderContext'
import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

import useSaveData from './useSaveData'
import useOrderProducts from './useOrderProducts'

import { insertIf } from '../../../../../../helpers/common'
import { formatCurrency } from '../../../../../../helpers/numbers'
import { getOriginalProductPrice } from './helpers/getProductValue'

import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { getInitialProductSetup, MEDIA_PRODUCTS } from '../../fields'
import { DATA_COST, DATA_DATES, DATA_QUANTITY, DATA_RATE, SORT_PARAMETERS } from './constants'
import { SKIP_CELL_FOR_COPY } from '../../../../../../components/Table/constants'

import useStyles from './styles'

export const QUOTATION_BUILDER_TABLE_ID = 'quotation-builder-table'
export const tableColumnsSize = {
  dragBtn: 40,
  rate: 110,
  discount: 78,
  cost: 110
}

const ProductsTable = ({
  formik,
  isAdditionalInfoEdit,
  allowEdit,
  allowAutoSave,
  checkInventory = true,
  isAmendment
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // Create a client
  const queryClient = useMemo(() => new QueryClient(), [])

  const [selectedCategory, setSelectedCategory] = useState(null)
  const { tableSort, setTableSort, editProductId, setEditProductId } = useContext(ContractBuilderContext)
  const { values, setFieldValue } = formik

  const products = values[MEDIA_PRODUCTS]
  const selectedProductsIds = useMemo(() => {
    return products.map(product => product.data?.id)
  }, [products])

  const controllerId = useSelector(selectedControllerIdSelector)
  const {
    // contextSelfAccountData,
    currency
  } = useContext(MediaOrderFormContext)
  const currencySymbol = currency?.symbol

  const { handleSaveDataToBE } = useSaveData({ values, allowEdit, allowAutoSave })
  const { orderedProducts, handleProductsOrderUpdate, handleSortChange } = useOrderProducts({
    products,
    values,
    setFieldValue,
    setTableSort,
    allowEdit,
    allowAutoSave
  })

  const handleSelectProduct = useCallback(
    (productName, productIndex) => {
      // first we select product and start fetching it data
      const productValues = products[productIndex]

      const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`

      setFieldValue(productPath, {
        ...productValues,
        isLoading: true,
        name: productName
      })
    },
    [products, setFieldValue]
  )

  const handleProductDataAdd = useCallback(
    (productData, productIndex) => {
      // here the product is fetching and we add it data
      const productValues = products[productIndex]

      const newProductData = {
        ...productData,
        // format BE values to FE structure
        location_name: productData.location?.name,
        value: productData.id,
        label: productData.internal_id ? `${productData.internal_id} - ${productData.name}` : productData.name
      }
      const productFormattedData = getInitialProductSetup(newProductData)

      const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`

      setFieldValue(productPath, {
        ...productValues,
        isLoading: false,
        isNewProduct: true,
        ...productFormattedData
      })
      // set edit more for new selected product
      setEditProductId(productValues.id)
      setSelectedCategory(null) // reset selected category on product selection
      setTableSort(defaultTableSort) // reset sorting
    },
    [products, setEditProductId, setFieldValue, setTableSort]
  )

  const handleAddProductError = useCallback(
    productIndex => {
      // here the product has failed so we need to stop loading
      // don't add full products array to dependencies as it provokes infinite loop
      const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
      const isLoadingPath = `${productPath}.isLoading`

      setFieldValue(isLoadingPath, false)
    },
    [setFieldValue]
  )

  const handleDeleteProduct = useCallback(
    productId => {
      const newProducts = products.filter(product => product.id !== productId)
      setFieldValue(MEDIA_PRODUCTS, newProducts)

      handleSaveDataToBE({ updatedProductValues: newProducts })
      // reset selected category - cover case when empty row was deleted
      setSelectedCategory(null)
    },
    [products, setFieldValue, handleSaveDataToBE]
  )

  const handleEditProduct = useCallback(
    productId => {
      setEditProductId(productId)
    },
    [setEditProductId]
  )

  useEffect(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetMediaSubCategories())
    }
  }, [dispatch])

  const productPricesColumns = useMemo(() => {
    return [
      ...insertIf(allowEdit, {
        Cell: rowData => {
          return (
            // Do not show drag button until product is selected
            <div style={{ opacity: rowData.data ? 1 : 0 }}>
              <DragableDots />
            </div>
          )
        },
        attributes: { [SKIP_CELL_FOR_COPY]: true },
        style: { maxWidth: tableColumnsSize.dragBtn, padding: 0 }
      }),
      {
        header: 'Category',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 130, maxWidth: 130, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.CATEGORY,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          const productData = productValues.data
          const isProductLoading = productValues.isLoading
          const isProductSelected = !!productData
          const isProductAdded = isProductLoading || isProductSelected

          return isProductAdded ? (
            isProductSelected ? (
              productData.media_sub_category_name || '-'
            ) : (
              <Skeleton width="100%" />
            )
          ) : (
            <CategorySelection selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
          )
        }
      },
      {
        header: 'Location',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 150, maxWidth: 150, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.LOCATION,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          const productData = productValues.data
          const isProductLoading = productValues.isLoading

          return isProductLoading ? <Skeleton width="100%" /> : productData?.location_name || '-'
        }
      },
      {
        header: 'Asset ID',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 120, maxWidth: 120, whiteSpace: 'normal' },
        Cell: productValues => {
          const productData = productValues.data
          const isProductLoading = productValues.isLoading

          return isProductLoading ? <Skeleton width="100%" /> : productData?.internal_id || '-'
        }
      },
      {
        header: 'Asset',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 235, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.ASSET,
        onSortingChange: handleSortChange,
        Cell: (product, index) => {
          return product.data ? (
            <div className={classes.name}>{product.data.name}</div>
          ) : (
            // Provide the client to your App
            <QueryClientProvider client={queryClient}>
              <ProductSelection
                productIndex={index}
                onAddProductError={handleAddProductError}
                onSelectProduct={handleSelectProduct}
                onAddProduct={handleProductDataAdd}
                selectedSubCategory={selectedCategory}
                selectedProductsIds={selectedProductsIds}
              />
            </QueryClientProvider>
          )
        }
      },
      {
        header: 'Dates',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { flexWrap: 'wrap', minWidth: 200, maxWidth: 200 },
        attributes: { 'data-copy': DATA_DATES },
        sortParameter: SORT_PARAMETERS.DATES,
        onSortingChange: handleSortChange,
        Cell: (productValues, productIndex) => {
          const isProductLoading = productValues.isLoading

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <DatesCell
              formik={formik}
              productValues={productValues}
              productIndex={productIndex}
              checkInventory={checkInventory}
              isEditMode={editProductId === productValues.id}
              isAmendment={isAmendment}
            />
          )
        }
      },
      {
        header: 'Qty.',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 60, maxWidth: 60, padding: 0 },
        attributes: { 'data-copy': DATA_QUANTITY },
        Cell: (productValues, productIndex) => {
          const productRowId = productValues.id
          const isInEditMode = editProductId === productRowId
          const isProductLoading = productValues.isLoading

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <QuantityCell
              formik={formik}
              productValues={productValues}
              productIndex={productIndex}
              isEditMode={isInEditMode}
            />
          )
        }
      },
      {
        header: 'Period',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 70, maxWidth: 70 },
        Cell: productValues => {
          const isProductLoading = productValues.isLoading
          const isProductSelected = !!productValues.data
          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : isProductSelected ? (
            <PeriodsCell productValues={productValues} />
          ) : null
        }
      },
      {
        header: 'Rate',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: tableColumnsSize.rate, maxWidth: tableColumnsSize.rate, justifyContent: 'flex-end' },
        attributes: { 'data-copy': DATA_RATE },
        sortParameter: SORT_PARAMETERS.RATE,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          const isProductLoading = productValues.isLoading
          const isProductSelected = Boolean(productValues.data)

          if (isProductLoading) {
            return <Skeleton width="100%" />
          } else if (isProductSelected) {
            const totalPrice = getOriginalProductPrice(productValues)

            return formatCurrency(totalPrice, { min: 2, max: 2 }, { symbol: currencySymbol })
          } else {
            return ''
          }
        }
      },
      {
        header: 'Disc. %',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: tableColumnsSize.discount, maxWidth: tableColumnsSize.discount },
        Cell: (productValues, productIndex) => {
          const isProductLoading = productValues.isLoading
          const isProductSelected = !!productValues.data

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : isProductSelected ? (
            <DiscountCell
              formik={formik}
              productValues={productValues}
              productIndex={productIndex}
              isEditMode={editProductId === productValues.id}
            />
          ) : null
        }
      },
      {
        header: 'Cost',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: tableColumnsSize.cost, maxWidth: tableColumnsSize.cost, justifyContent: 'flex-end' },
        attributes: { 'data-copy': DATA_COST },
        sortParameter: SORT_PARAMETERS.COST,
        onSortingChange: handleSortChange,
        Cell: (productValues, productIndex) => {
          const productRowId = productValues.id
          const isProductLoading = productValues.isLoading
          const isEditMode = editProductId === productRowId

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <CostCell
              formik={formik}
              productValues={productValues}
              isEditMode={isEditMode}
              productIndex={productIndex}
              currencySymbol={currencySymbol}
            />
          )
        }
      },
      ...insertIf(allowEdit, {
        style: { minWidth: 56, maxWidth: 56, height: '100%', padding: 0, justifyContent: 'flex-end' },
        header: 'action',
        headClassName: classes.actionsHeader,
        attributes: { [SKIP_CELL_FOR_COPY]: true },
        Cell: (productValues, productIndex) => {
          const isProductLoading = productValues.isLoading

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <ActionsCell
              formik={formik}
              productIndex={productIndex}
              productValues={productValues}
              editProductId={editProductId}
              allowAutoSave={allowAutoSave}
              handleDeleteProduct={handleDeleteProduct}
              handleProductEdit={handleEditProduct}
            />
          )
        }
      })
    ]
  }, [
    allowAutoSave,
    allowEdit,
    checkInventory,
    classes.actionsHeader,
    classes.name,
    classes.quotationBuilderCol,
    currencySymbol,
    editProductId,
    formik,
    handleAddProductError,
    handleDeleteProduct,
    handleEditProduct,
    handleProductDataAdd,
    handleSelectProduct,
    handleSortChange,
    isAmendment,
    queryClient,
    selectedCategory,
    selectedProductsIds
  ])

  const hasNotSelectedProducts = products.some(product => !product.data)

  return (
    <Table
      className={classes.quotationsProductsTable}
      cols={productPricesColumns}
      data={orderedProducts}
      tableSort={tableSort}
      tableId={QUOTATION_BUILDER_TABLE_ID}
      footerClassName={classes.footer}
      onRowDrag={allowEdit ? handleProductsOrderUpdate : undefined}
      Footer={
        <FooterSummary
          formik={formik}
          allowEdit={allowEdit}
          allowAutoSave={allowAutoSave}
          disableProductAdd={Boolean(editProductId) || hasNotSelectedProducts || isAdditionalInfoEdit}
          editProductId={editProductId}
          handleTotalEdit={handleEditProduct}
        />
      }
    />
  )
}

ProductsTable.propTypes = {
  formik: PropTypes.object,
  isAdditionalInfoEdit: PropTypes.bool,
  allowEdit: PropTypes.bool
}

export default ProductsTable
