import React from 'react'

export const ExpandableRowsOpenStatesContext = React.createContext()

export function ExpandableRowsOpenStatesProvider({ children }) {
  const [openStates, setOpenStates] = React.useState({})

  return (
    <ExpandableRowsOpenStatesContext.Provider value={{ openStates, setOpenStates }}>
      {children}
    </ExpandableRowsOpenStatesContext.Provider>
  )
}
