import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import HighlightedValueSelect from '../../../../components/HighlightedValueSelect'

import { statusFilterOptions } from './options'

export const BOOKED_MEDIA_STATUS_FILTER = 'bookedMediaStatusFilter'

const BookedMediaStatusFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [BOOKED_MEDIA_STATUS_FILTER]: statusFilterOption } = filtersState

  const setStatusFilterOption = useCallback(
    option => {
      const updatedFiltersState = {
        ...filtersState,
        [BOOKED_MEDIA_STATUS_FILTER]: option
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const onStatusFilterChange = useCallback(
    option => {
      setStatusFilterOption(option?.value)
    },
    [setStatusFilterOption]
  )

  return (
    <HighlightedValueSelect
      placeholder="Status"
      value={statusFilterOption}
      onChange={onStatusFilterChange}
      options={statusFilterOptions}
      isSmall
      portaled
    />
  )
}

BookedMediaStatusFilter.propTypes = {
  Context: PropTypes.object
}

export default BookedMediaStatusFilter
