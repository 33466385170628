import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-arrow-down.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close-grey.svg'
import ButtonIcon from '../../features/components/Buttons/ButtonIcon'

import useStyles from './style'

function SpoilerFilter({ title, children, initialOpen, onReset, allowReset = true, allowMinimize }) {
  const { t } = useTranslation()
  const [height, setHeight] = useState(initialOpen ? 'auto' : 0)

  const classes = useStyles({ opened: !!height })

  const onToggleSpoiler = useCallback(
    e => {
      e.stopPropagation()

      if (height === 0) {
        setHeight('auto')
      } else {
        allowMinimize && setHeight(0)
      }
    },
    [height, allowMinimize]
  )

  const onClose = useCallback(() => {
    onReset && onReset()
  }, [onReset])

  return (
    <div className={classnames(classes.spoiler, 'spoiler', classes.filter)}>
      <div
        className={classnames(classes.spoilerTitle, classes.filterTitle, !!height ? classes.filterTitleOpened : {})}
        onClick={onToggleSpoiler}
      >
        {t(title)}
        {!height && <ArrowIcon className={classes.arrowIcon} />}
        {!!height && allowReset && <ButtonIcon Icon={CloseIcon} onClick={onClose} className={classes.buttonClose} />}
      </div>
      <AnimateHeight duration={300} height={height}>
        <div
          className={classnames(
            classes.filterContent,
            // Static filterContent class is needed to scroll to the bottom of the filter content
            'filterContent'
          )}
        >
          {children}
        </div>
      </AnimateHeight>
    </div>
  )
}

SpoilerFilter.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  initialOpen: PropTypes.bool,
  onReset: PropTypes.func,
  allowReset: PropTypes.bool,
  allowMinimize: PropTypes.bool
}

export default SpoilerFilter
