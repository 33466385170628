import { createStyles } from '../../styles/helpers'
import { darkGrey, mediumGrey, textGrey } from '../../styles/const/colors'
import { phonesDown } from '../../styles/const/breakpoints'
import { transitionShort } from '../../styles/const/common'

const useStyles = createStyles(theme => ({
  spoiler: {
    marginTop: 24
  },
  arrowIcon: {
    width: 12,
    height: 8,
    display: 'inline-block',
    marginLeft: 8,
    transition: transitionShort,
    transform: ({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')
  },
  spoilerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    color: darkGrey,
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 16
  },
  buttonClose: {
    padding: 0,
    '& path': {
      // reset default ButtonIcon styles
      fill: [theme.brandPrimary, '!important']
    }
  },
  [`@media screen and (${phonesDown})`]: {
    spoiler: {
      '& .radioBlock:first-child': {
        marginTop: 16
      }
    }
  },
  filter: {
    width: '100%',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${mediumGrey}`
    }
  },
  filterTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 13px',
    // font styles:
    fontSize: 12,
    fontWeight: 400,
    color: textGrey
  },
  filterTitleOpened: {
    fontWeight: 600
  },
  filterContent: {
    padding: '14px 16px',
    '& > div:not(:last-child)': {
      marginBottom: 5
    }
  }
}))

export default useStyles
