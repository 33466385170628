import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import ActionsDropdown from '../../../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../../../features/components/ActionsButtonsList'

import { insertIf } from '../../../../../../../helpers/common'

import {
  PRODUCT_STATUS,
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_PAUSED
} from '../../../../../../../forms/Multiplatform/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import { phonesDownSize } from '../../../../../../../styles/const/breakpoints'

const MediaProductAction = ({
  productId,
  status,
  openEditFormHandler,
  updatePackageStatusHandler,
  deleteProductHandler,
  openMangeInventoryFormHandler,
  isProductGroup
}) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const isActive = status === PRODUCT_STATUS_ACTIVE

  const dropdownOptions = useMemo(() => {
    return [
      ...insertIf(!isProductGroup, {
        text: t('Inventory'),
        onClickHandler: openMangeInventoryFormHandler
      }),
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      },
      {
        text: isActive ? t('Pause') : t('Activate'),
        onClickHandler: () =>
          updatePackageStatusHandler({
            id: productId,
            [PRODUCT_STATUS]: isActive ? PRODUCT_STATUS_PAUSED : PRODUCT_STATUS_ACTIVE
          })
      },
      {
        text: t('Delete'),
        onClickHandler: deleteProductHandler
      }
    ]
  }, [
    t,
    productId,
    openMangeInventoryFormHandler,
    openEditFormHandler,
    isActive,
    deleteProductHandler,
    updatePackageStatusHandler,
    isProductGroup
  ])

  const callbackData = useMemo(() => {
    return {
      status
    }
  }, [status])

  return isMobile ? (
    <ActionsButtonsList itemId={productId} callbackData={callbackData} options={dropdownOptions} />
  ) : (
    <ActionsDropdown itemId={productId} callbackData={callbackData} options={dropdownOptions} />
  )
}

MediaProductAction.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  status: PropTypes.string.isRequired,
  openEditFormHandler: PropTypes.func.isRequired,
  updatePackageStatusHandler: PropTypes.func.isRequired,
  deleteProductHandler: PropTypes.func.isRequired,
  openMangeInventoryFormHandler: PropTypes.func.isRequired,
  isProductGroup: PropTypes.bool
}

export default MediaProductAction
