import {
  APPROVED,
  BUYER_DISAPPROVED,
  DISAPPROVED,
  PENDING_APPROVAL,
  PENDING_BUYER_APPROVAL
} from '../../../../../../features/components/UploadedFilesField/helpers'

export const getAllowDisapprove = fileStatus =>
  // if 'pending_approval' - user can disapprove it, this will change status to 'disapproved'
  fileStatus === PENDING_APPROVAL ||
  // if 'pending_buyer_approval' - user can disapprove it, this will change status to 'disapproved'
  fileStatus === PENDING_BUYER_APPROVAL ||
  // if 'approved' - user can disapprove it, this will change status to 't buyer_disapproved'
  fileStatus === APPROVED ||
  // if 'buyer_disapproved' - user can disapprove it, this will change status to 'disapproved'
  fileStatus === BUYER_DISAPPROVED

export const getAllowApprove = fileStatus =>
  // if 'pending_approval' - user can approve it, this will change status to 'pending_buyer_approval'
  fileStatus === PENDING_APPROVAL ||
  // if 'pending_buyer_approval' - user can approve it, this will change status to 'approved'
  fileStatus === PENDING_BUYER_APPROVAL ||
  // if 'disapproved' - user can approve it, this will change status to 'pending_buyer_approval'
  fileStatus === DISAPPROVED ||
  // if 'buyer_disapproved' - user can approve it, this will change status to 'approved'
  fileStatus === BUYER_DISAPPROVED
