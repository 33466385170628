import { createStyles } from '../../../../../../../styles/helpers'
import { thumbnailMarginRight, thumbnailWidth } from '../NameField/styles'

const useStyles = createStyles({
  uploadedFileComment: {
    fontSize: 12,
    whiteSpace: 'break-spaces',
    // align file comment with the file name
    marginLeft: thumbnailWidth + thumbnailMarginRight,
    marginTop: 4
  }
})

export default useStyles
