import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaProductLocationCreateForm from './MediaProductLocationCreateForm'

import { clearGetController, getController } from '../../../../modules/actions/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { controllerWasLoadedSelector } from '../../../../modules/selectors/controller'
import { formsOpenStatesSelector } from '../../../../modules/selectors/forms'
import {
  clearGetMediaProductLocationCategories,
  clearGetMediaProductLocationRegions,
  getMediaProductLocationCategories,
  getMediaProductLocationRegions
} from '../../../../modules/actions/mediaOrdersProductLocations'
import {
  mediaProductLocationCategoriesWasLoadedSelector,
  mediaProductLocationRegionsWasLoadedSelector
} from '../../../../modules/selectors/mediaOrdersProductLocations'

import { MEDIA_PRODUCT_LOCATION_CREATE } from '../../../../constants/forms'

function MediaProductLocationCreate() {
  const dispatch = useDispatch()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const controllerWasLoaded = useSelector(controllerWasLoadedSelector)

  const { [MEDIA_PRODUCT_LOCATION_CREATE]: isFormOpen = false } = useSelector(formsOpenStatesSelector)
  const mediaProductLocationCategoriesWasLoaded = useSelector(mediaProductLocationCategoriesWasLoadedSelector)
  const mediaProductLocationRegionsWasLoaded = useSelector(mediaProductLocationRegionsWasLoadedSelector)

  const onAfterFormCloseHandler = useCallback(() => {
    dispatch(clearGetController())
    dispatch(clearGetMediaProductLocationCategories())
    dispatch(clearGetMediaProductLocationRegions())
  }, [dispatch])

  useEffect(() => {
    if (isFormOpen) {
      dispatch(getController({ id: selectedControllerId }))
      dispatch(
        getMediaProductLocationCategories({
          controller: selectedControllerId,
          ordering: 'name',
          limit: 999
        })
      )
      dispatch(
        getMediaProductLocationRegions({
          controller: selectedControllerId,
          ordering: 'name',
          limit: 999
        })
      )
    }
  }, [dispatch, isFormOpen, selectedControllerId])

  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_LOCATION_CREATE}
      title="Product Locations"
      description="Product locations are used to group media products into physical locations like malls or stores"
      openButtonText="Add new location"
      isFormLoading={
        !controllerWasLoaded || !mediaProductLocationCategoriesWasLoaded || !mediaProductLocationRegionsWasLoaded
      }
      onAfterFormClose={onAfterFormCloseHandler}
    >
      <MediaProductLocationCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaProductLocationCreate
