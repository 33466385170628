import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Dashboard from './Dashboard'
import InventoryReport from './InventoryReport'
import BookedMediaReport from './BookedMediaReport'
import InstallationReport from './InstallationReport'
import ContentTabs from '../../../../features/components/ContentTabs'
import BookedRevenueBreakdownReport from './BookedRevenueBreakdownReport'
import Approvals from './Approvals'
import ApprovalsTabTitle from './ApprovalsTabTitle'

import { replaceRouteParams } from '../../../../helpers/url'
import usePermissions from '../../../../hooks/usePermissions'

import { selectedSelfAccountSelector } from '../../../../modules/selectors/app'

import { dashboardTabId, ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'

export const MANAGEMENT_REPORTING_SUB_ROUTES = {
  BREAKDOWN_CHARTS: 'breakdown-charts',
  BOOKED_REVENUE_REPORT: 'booked-revenue-breakdown',
  BOOKED_MEDIA_REPORT: 'booked-media-calendar',
  INSTALLATION_REPORT: 'installation',
  APPROVALS: 'approvals',
  INVENTORY_CALENDAR_REPORT: 'inventory-calendar'
}

function ManagementReporting() {
  const { t } = useTranslation()

  const routeParams = useParams()
  const permissions = usePermissions()
  const subPagePath = routeParams[dashboardTabId]

  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)

  const getFormattedPath = useCallback(
    mainPath =>
      replaceRouteParams(mainPath, {
        [ROUTE_PARAMS.selfAccount]: selectedSelfAccount
      }),
    [selectedSelfAccount]
  )

  const contentTabs = useMemo(() => {
    return [
      {
        title: t('Dashboard'),
        mobileTitle: t('Dashboard'),
        path: getFormattedPath(ROUTES.breakdownCharts),
        pathParam: MANAGEMENT_REPORTING_SUB_ROUTES.BREAKDOWN_CHARTS,
        component: <Dashboard />,
        show: permissions && permissions.can('manage', ROUTES.breakdownCharts)
      },
      {
        title: <ApprovalsTabTitle />,
        mobileTitle: t('Approvals'),
        path: getFormattedPath(ROUTES.approvals),
        pathParam: MANAGEMENT_REPORTING_SUB_ROUTES.APPROVALS,
        component: <Approvals />,
        show: true
      },
      {
        title: t('Installation'),
        mobileTitle: t('Installation'),
        path: getFormattedPath(ROUTES.installationReport),
        pathParam: MANAGEMENT_REPORTING_SUB_ROUTES.INSTALLATION_REPORT,
        component: <InstallationReport />,
        show: true
      },
      {
        title: t('Booked Media Calendar'),
        mobileTitle: t('Booked Media Calendar'),
        path: getFormattedPath(ROUTES.bookedMediaCalendarReport),
        pathParam: MANAGEMENT_REPORTING_SUB_ROUTES.BOOKED_MEDIA_REPORT,
        component: <BookedMediaReport />,
        show: permissions && permissions.can('manage', ROUTES.bookedMediaCalendarReport)
      },
      {
        title: t('Available Inventory'),
        mobileTitle: t('Available Inventory'),
        path: getFormattedPath(ROUTES.inventoryCalendarReport),
        pathParam: MANAGEMENT_REPORTING_SUB_ROUTES.INVENTORY_CALENDAR_REPORT,
        component: <InventoryReport />,
        show: permissions && permissions.can('manage', ROUTES.inventoryCalendarReport)
      },
      {
        title: t('Booked Revenue Breakdown'),
        mobileTitle: t('Booked Revenue'),
        path: getFormattedPath(ROUTES.bookedRevenueReport),
        pathParam: MANAGEMENT_REPORTING_SUB_ROUTES.BOOKED_REVENUE_REPORT,
        component: <BookedRevenueBreakdownReport />,
        show: permissions && permissions.can('manage', ROUTES.bookedRevenueReport)
      }
    ]
  }, [t, getFormattedPath, permissions])

  return <ContentTabs tabs={contentTabs} subPagePath={subPagePath} />
}

export default ManagementReporting
