import React, { useMemo } from 'react'

import Can from '../../../../features/components/Can'
import { BookingsFiltersContext } from './BookingsFiltersContext'
import MultipleControllerMembersFilter, {
  MULTIPLE_CONTROLLER_MEMBERS_FILTER
} from '../../../../features/components/DropdownFilters/components/MultipleControllerMembersFilter'
import MediaOrderDownloadCSV from '../../BookedMediaAndFiles/BookedMediaAndFilesFilters/MediaOrderDownloadCSV'
import DropdownFilters from '../../../../features/components/DropdownFilters'
import DateFilter, { DATE_RANGE_FILTER } from '../../../../features/components/DropdownFilters/components/DateFilter'
import MultipleControllerSelfAccountsFilter, {
  MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER
} from '../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'

import { formatCreatedDateRangeLabel, formatDateRangeLabel } from './helpers'
import { getIsDateRangeFilterSelected } from '../../../../features/components/DropdownFilters/components/DateFilter/helpers'

import { BOOKINGS_SELF_ACCOUNT_FILTER } from '../../../../constants/permissions'

import useStyles from './styles'

export const CREATED_DATE_RANGE_FILTER = 'createdDateRangeFilter'

const BookingsFilters = () => {
  const classes = useStyles()

  const filters = useMemo(() => {
    return [
      {
        component: (
          <DateFilter
            Context={BookingsFiltersContext}
            formatDateRangeLabel={formatDateRangeLabel}
            placeholder="Go live date"
            isClearable={true}
            isHighlighted={true}
          />
        ),
        filterName: DATE_RANGE_FILTER,
        title: 'Go live date',
        show: true,
        getIsFilterSelected: getIsDateRangeFilterSelected
      },
      {
        component: (
          <DateFilter
            Context={BookingsFiltersContext}
            formatDateRangeLabel={formatCreatedDateRangeLabel}
            dateRangeFilterName={CREATED_DATE_RANGE_FILTER}
            placeholder="Created date"
            isClearable={true}
            isHighlighted={true}
          />
        ),
        filterName: CREATED_DATE_RANGE_FILTER,
        title: 'Created date',
        show: true,
        getIsFilterSelected: getIsDateRangeFilterSelected
      },
      {
        component: <MultipleControllerMembersFilter Context={BookingsFiltersContext} />,
        filterName: MULTIPLE_CONTROLLER_MEMBERS_FILTER,
        title: 'Owner',
        show: true
      },
      {
        component: <MultipleControllerSelfAccountsFilter Context={BookingsFiltersContext} />,
        title: 'Accounts',
        filterName: MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER,
        show: true
      }
    ]
  }, [])

  return (
    <div className={classes.filtersRow}>
      <div className={classes.rightFiltersRow}>
        <Can I="manage" a={BOOKINGS_SELF_ACCOUNT_FILTER}>
          <MediaOrderDownloadCSV />
          <DropdownFilters filters={filters} Context={BookingsFiltersContext} />
        </Can>
      </div>
    </div>
  )
}

export default BookingsFilters
