import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import Button from '../../../../../components/Button'

import { TARGET_OPTION, ANY_VISITORS, SPECIFIC_VISITORS, ON_TIME_BASED_VISITORS } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const TargetStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, setFieldValue } = formik

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Who do you want to target?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_any_visitors_to_website"
          name={TARGET_OPTION}
          value={ANY_VISITORS}
          selectedValue={values[TARGET_OPTION]}
          label={t('Any visitor to your website')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_visitors_to_specific_pages"
          name={TARGET_OPTION}
          value={SPECIFIC_VISITORS}
          selectedValue={values[TARGET_OPTION]}
          label={t('Visitors to specific pages')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_visitors_based_on_time"
          name={TARGET_OPTION}
          value={ON_TIME_BASED_VISITORS}
          selectedValue={values[TARGET_OPTION]}
          label={t('Visitors based on time spent on your website')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={() => handleStepChange()}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default TargetStep
