import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  tableActions: {
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '6px'
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px'
  },
  copyTableBtn: {
    extend: 'btn',
    width: '132px'
  }
})

export default useStyles
