import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import Chip from '../../../../components/Chip'

import { CHIP_COLORS } from '../../../../constants/other'

import useStyles from './styles'

const TabTitle = ({ title, chipText, showLoadingChip }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {t(title)}
      {showLoadingChip && <Skeleton width={20} height={20} />}
      {chipText && <Chip size="small" text={chipText} color={CHIP_COLORS.redWithWhiteColor} />}
    </div>
  )
}

export default TabTitle
