import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../formatters'
import { getLoadOptionsHandler } from '../../../helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { getControllerMembersService } from '../../../../modules/services/controller'

export const MULTIPLE_REPRESENTATIVE_FILTER = 'multipleRepresentativeFilter'

const RepresentativeFilter = ({ Context }) => {
  const controllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [MULTIPLE_REPRESENTATIVE_FILTER]: multipleRepresentativeFilterOption } = filtersState

  const setMultipleRepresentativeFilterOption = useCallback(
    newMultipleRepresentativeFilterOption => {
      const updatedFiltersState = {
        ...filtersState,
        [MULTIPLE_REPRESENTATIVE_FILTER]: newMultipleRepresentativeFilterOption
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const formatControllerMembers = useCallback(controllerMembers => {
    return formatOptionsList({
      list: controllerMembers,
      valueName: 'user',
      labelName: 'full_name'
    })
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      // Representative, is the user which is added to representatives to the selfAccount.
      // Currently, we are using the controllerMembers because there is no endpoint to get the representatives.
      // So just to admit - not all the controllerMembers are representatives.
      getOptionsService: getControllerMembersService,
      params: {
        controller: controllerId
      },
      formatOptions: formatControllerMembers,
      limit: 100
    })
  }, [controllerId, formatControllerMembers])

  const onMultipleRepresentativeFilterChange = useCallback(
    data => {
      setMultipleRepresentativeFilterOption(data)
    },
    [setMultipleRepresentativeFilterOption]
  )

  const setMultipleRepresentativeFilterOptionHandler = useCallback(
    (name, data) => {
      setMultipleRepresentativeFilterOption(data)
    },
    [setMultipleRepresentativeFilterOption]
  )

  return (
    <MultiSelectBox
      name="Representatives"
      loadOptions={loadOptions}
      value={multipleRepresentativeFilterOption}
      onChange={onMultipleRepresentativeFilterChange}
      placeholder="Representatives"
      setFieldValue={setMultipleRepresentativeFilterOptionHandler}
      portaled
    />
  )
}

RepresentativeFilter.propTypes = {
  Context: PropTypes.object
}

export default RepresentativeFilter
