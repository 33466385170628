import { v4 as uuidv4 } from 'uuid'

export const NAME = 'name'
export const ADDRESS = 'address'
export const ALT_TEXT = 'alt_text'
// notification recipients
export const BOOKING_NOTIFICATION_RECIPIENTS = 'recipients'
export const EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS = 'external_recipients'
export const FIRST_NAME = 'first_name'
export const LAST_NAME = 'last_name'
export const EMAIL = 'email'
export const SEND_BOOKING_SUMMARY_NOTIFICATION = 'send_booking_summary_notification'
export const SUMMARY_INTERVAL = 'summary_interval'
export const WEEK_S = 'week_s'
export const WEEK_M = 'week_m'
export const STORES = 'stores'
export const STORE_NAME = 'name'
export const INTERNAL_ID = 'internal_id'
export const SUMMARY_DAYS_ADVANCE = 'summary_days_advance'
export const MEDIA_PRODUCT_LOCATION_CATEGORY = 'category'
export const MEDIA_PRODUCT_LOCATION_REGION = 'region'

export const summaryIntervals = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
}

export const summaryIntervalsOptions = [
  {
    value: summaryIntervals.DAY,
    label: 'Daily'
  },
  {
    value: summaryIntervals.WEEK,
    label: 'Weekly'
  },
  {
    value: summaryIntervals.MONTH,
    label: 'Monthly'
  }
]

export const daysInAdvanceOptions = [
  {
    value: '0',
    label: 'On go-live day'
  },
  {
    value: '1',
    label: '1 day before'
  },
  {
    value: '2',
    label: '2 days before'
  },
  {
    value: '3',
    label: '3 days before'
  },
  {
    value: '4',
    label: '4 days before'
  },
  {
    value: '5',
    label: '5 days before'
  },
  {
    value: '6',
    label: '6 days before'
  },
  {
    value: '7',
    label: '7 days before'
  },
  {
    value: '14',
    label: '14 days before'
  },
  {
    value: '30',
    label: '30 days before'
  }
]

export const initialExternalRecipientValue = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [EMAIL]: ''
}

export const initialStoreValue = {
  id: uuidv4(),
  [STORE_NAME]: '',
  [INTERNAL_ID]: ''
}
