import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  mainSelectOrDeselectAllButton: {
    marginTop: 10,
    marginRight: 10,
    marginLeft: 'auto'
  }
})

export default useStyles
