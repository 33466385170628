import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../../components/Form/Field'
import Button from '../../../../../components/Button'

import { formatOptionsList } from '../../../../../features/formatters'

import { accessibleFacebookPagesSelector } from '../../../../../modules/selectors/socialAccounts'

import { FACEBOOK_PAGE_CHOICE } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const FacebookPageStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const formattedFacebookPages = formatOptionsList({
    list: facebookPages.filter(({ tasks }) => tasks.includes('advertise')),
    valueName: 'id',
    labelName: 'name'
  })

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Select a Facebook page')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Facebook page')}</h4>
          <Field
            placeholder="Facebook Page"
            formik={formik}
            name={FACEBOOK_PAGE_CHOICE}
            options={formattedFacebookPages}
          />
        </section>
        <div className={classes.stepFooter}>
          <Button type="button" onClick={() => handleStepChange()}>
            {t('Continue')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default FacebookPageStep
