import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Button from '../../../../../../../components/Button'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'
import Field from '../../../../../../../components/Form/Field'

import { BUDGET_DAILY, BUDGET_LIFETIME, BUDGET_OPTION } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { CHIP_COLORS } from '../../../../../../../constants/other'

import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

import useStyles from '../../../../../../../styles/common/stepForms'

const stepFields = [BUDGET_LIFETIME, BUDGET_DAILY]

const BudgetStep = ({ formik, handleStepChange, campaignBuyingType }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  const { setFieldValue, values } = formik

  const showLifetimeOption = campaignBuyingType !== 'BUDGET_MODE_DAY'

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('Would you like to set a budget for this ad group?')}</div>
      <div className={classes.stepBody}>
        {showLifetimeOption && (
          <RadioBlock
            setFieldValue={setFieldValue}
            id="radio_budget_lifetime"
            name={BUDGET_OPTION}
            value={BUDGET_LIFETIME}
            selectedValue={values[BUDGET_OPTION]}
            label={t('Set a lifetime budget')}
            badgeText={'Recommended'}
            badgeColor={CHIP_COLORS.green}
          >
            <p>{t('A total budget for the ad group.')}</p>
            <Field
              type="number"
              formik={formik}
              name={BUDGET_LIFETIME}
              placeholder="Lifetime Budget"
              symbol={currency_symbol}
              autoComplete="off"
              inputMode="numeric"
            />
          </RadioBlock>
        )}
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_budget_daily"
          name={BUDGET_OPTION}
          value={BUDGET_DAILY}
          selectedValue={values[BUDGET_OPTION]}
          label={t('Set a daily budget')}
        >
          <p>{t('The average amount to spend on this ad group per day.')}</p>
          <Field
            type="number"
            formik={formik}
            name={BUDGET_DAILY}
            placeholder="Daily Budget"
            symbol={currency_symbol}
            autoComplete="off"
            inputMode="numeric"
          />
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default BudgetStep
