import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../../components/Form/Field'

import { createJsonFromQueryString } from '../../../../../../../../../helpers/url'
import { formatOptionsList } from '../../../../../../../../../features/formatters'

import { getSelfAccountCategoriesService } from '../../../../../../../../../modules/services/selfAccounts'
import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'

import { CATEGORY } from '../../../../../fields'

const initialOptions = []
const CategoriesSelectPaginated = ({ formik, itemPath, onCategoryChange, limit }) => {
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const { values } = formik

  const categorySelectName = `${itemPath}.${CATEGORY}`
  const selectedCategory = getIn(values, categorySelectName)

  const formatCategoriesOptions = useCallback(
    categories =>
      formatOptionsList({
        list: categories,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const onChangeHandler = useCallback(() => {
    onCategoryChange && onCategoryChange()
  }, [onCategoryChange])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getSelfAccountCategoriesService({
        search,
        ordering: 'name',
        controller: selectedControllerId,
        limit,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newCategoriesOptions = formatCategoriesOptions(response.results)

      return {
        options: newCategoriesOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selectedControllerId, formatCategoriesOptions, limit]
  )

  return (
    <Field
      formik={formik}
      name={categorySelectName}
      placeholder="Select Category"
      value={selectedCategory}
      onValueChange={onChangeHandler}
      loadOptions={loadOptions}
      setFullOptionObject={true}
      options={initialOptions}
    />
  )
}

CategoriesSelectPaginated.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func,
  defaultOptionsList: PropTypes.array
}

export default CategoriesSelectPaginated
