import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import StepForm from '../../../../../features/components/Forms/StepForm'
import CommentStep from './Steps/CommentStep'
import SelectFilesStep from './Steps/SelectFilesStep'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useProgressItems } from './useProgressItems'
import { transformValuesToBE } from './formatters'

import {
  bulkUpdateBookingMediaFileErrorSelector,
  bulkUpdateBookingMediaFileIsLoadingSelector,
  bulkUpdateBookingMediaFileWasUpdatedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'
import {
  bulkUpdateBookingMediaFile,
  clearBulkUpdateBookingMediaFile
} from '../../../../../modules/actions/mediaOrdersBookings'

import { ADD_COMMENTS_TO_UPLOADED_FILES } from '../../../../../constants/forms'
import { getInitialValues } from './fields'
import { getValidationSchema } from './validation'

function AddCommentsToUploadedFilesForm({ selectedFileMediaData, selectedFileId, uploadedFiles }) {
  const dispatch = useDispatch()
  const progressItems = useProgressItems(uploadedFiles)

  const bulkUpdateBookingMediaFileWasUpdated = useSelector(bulkUpdateBookingMediaFileWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE({
        values,
        selectedFileId,
        uploadedFiles
      })

      dispatch(bulkUpdateBookingMediaFile(formattedData))
    },
    [dispatch, selectedFileId, uploadedFiles]
  )

  const validationSchema = useMemo(() => getValidationSchema(uploadedFiles), [uploadedFiles])

  const initialValues = useMemo(() => getInitialValues(uploadedFiles, selectedFileId), [uploadedFiles, selectedFileId])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <CommentStep mediaData={selectedFileMediaData} uploadedFiles={uploadedFiles} />,
        show: true
      },
      {
        component: <SelectFilesStep uploadedFiles={uploadedFiles} />,
        show: uploadedFiles.length > 1
      }
    ],
    [selectedFileMediaData, uploadedFiles]
  )

  const clearAddCommentToUploadedFilesHandler = useCallback(() => {
    dispatch(clearBulkUpdateBookingMediaFile())
  }, [dispatch])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={ADD_COMMENTS_TO_UPLOADED_FILES}
      // processing
      isLoadingSelector={bulkUpdateBookingMediaFileIsLoadingSelector}
      successSubmit={bulkUpdateBookingMediaFileWasUpdated}
      errorSelector={bulkUpdateBookingMediaFileErrorSelector}
      // after form submit
      clearSubmitHandler={clearAddCommentToUploadedFilesHandler}
      formProgressItems={progressItems}
    />
  )
}

AddCommentsToUploadedFilesForm.propTypes = {
  selectedFileMediaData: PropTypes.object.isRequired,
  selectedFileId: PropTypes.number.isRequired,
  uploadedFiles: PropTypes.array.isRequired
}

export default AddCommentsToUploadedFilesForm
