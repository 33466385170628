import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import DateRange from '../../DateRange'

const DateRangeFilter = ({
  Context,
  formatDateRangeLabel,
  dateRangeFilterName = 'dateRangeFilter',
  setDateRangeFilterHandlerName: setDateRangeFilterName = 'setDateRangeFilter',
  className,
  dropdownClassName,
  rangeClassName,
  dateRangesClassName,
  placement,
  placeholder = 'Dates',
  allowPlacementFlip,
  portaled,
  isFloatingDropdown,
  onOpen,
  onClose,
  isClearable,
  isHighlighted
}) => {
  const filtersContext = useContext(Context)
  const { filtersState, setFiltersState } = filtersContext

  // DateRangeFilter currently supports both filtersState and filtersContext,
  // it probably will be refactored when new solution of DropdownFilters will be applied on all pages
  const dateRangeFilter = useMemo(() => {
    if (filtersState) {
      return filtersState[dateRangeFilterName]
    } else {
      return filtersContext[dateRangeFilterName]
    }
  }, [filtersState, filtersContext, dateRangeFilterName])

  const setDateRangeFilter = useMemo(() => {
    if (filtersState) {
      return newDateRange => {
        const updatedFiltersState = {
          ...filtersState,
          [dateRangeFilterName]: newDateRange
        }

        setFiltersState(updatedFiltersState)
      }
    } else {
      return filtersContext[setDateRangeFilterName]
    }
  }, [filtersState, filtersContext, dateRangeFilterName, setFiltersState, setDateRangeFilterName])

  const onDateRangeChange = useCallback(
    rangeValues => {
      setDateRangeFilter({
        startDate: rangeValues.startDate,
        endDate: rangeValues.endDate
      })
    },
    [setDateRangeFilter]
  )

  return (
    <DateRange
      // key is added to rerender DateRange component when dateRangeFilter is changed from outside
      key={+dateRangeFilter.startDate + +dateRangeFilter.endDate}
      placeholder={placeholder}
      dateRangeData={dateRangeFilter}
      onDateRangeChange={onDateRangeChange}
      formatDateRangeLabel={formatDateRangeLabel}
      isSelectedValueBrandPrimary={!isHighlighted}
      isClearable={isClearable}
      isHighlighted={isHighlighted}
      className={className}
      dropdownClassName={dropdownClassName}
      rangeClassName={rangeClassName}
      dateRangesClassName={dateRangesClassName}
      placement={placement}
      allowPlacementFlip={allowPlacementFlip}
      portaled={portaled}
      isFloatingDropdown={isFloatingDropdown}
      onOpen={onOpen}
      onClose={onClose}
    />
  )
}

DateRangeFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  formatDateRangeLabel: PropTypes.func,
  dateRangeFilterName: PropTypes.string,
  setDateRangeFilterHandlerName: PropTypes.string,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  rangeClassName: PropTypes.string,
  dateRangesClassName: PropTypes.string,
  placement: PropTypes.string,
  placeholder: PropTypes.string,
  allowPlacementFlip: PropTypes.bool,
  portaled: PropTypes.bool,
  isFloatingDropdown: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isClearable: PropTypes.bool,
  isHighlighted: PropTypes.bool
}

export default DateRangeFilter
