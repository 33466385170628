import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  mobileHeaderStyle: {
    minHeight: 24
  },
  mobileNameFieldStyle: {
    display: 'block'
  }
})

export default useStyles
