import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { fileStatusFilterOptions } from '../../../Filters/BookedMediaMultipleFileStatusesFilter/options'
import {
  filesApprovalStatusOptions,
  STATUS_FILTER_FILES_OVERDUE
} from '../../../Filters/BookedMediaStatusFilter/options'

export const SELECTED_FILE_STATUSES = 'selectedFileStatuses'

export const FileStatusesFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_FILE_STATUSES]: selectedFileStatuses } = filtersState

  const setSelectedFileStatuses = useCallback(
    newFileStatuses => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_FILE_STATUSES]: newFileStatuses
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const [selectedOptions, setSelectedOptions] = useState([])

  const formattedOptions = useMemo(() => {
    const isFilesOverdueIncluded = selectedOptions.map(({ value }) => value).includes(STATUS_FILTER_FILES_OVERDUE)
    if (isFilesOverdueIncluded) {
      return fileStatusFilterOptions.map(item => {
        const isFileApprovalStatus = filesApprovalStatusOptions.some(option => option.value === item.value)
        if (isFileApprovalStatus) {
          // if Files Overdue is selected then disable all file approval statuses
          return { ...item, isDisabled: true }
        }
        return item
      })
    } else {
      return fileStatusFilterOptions
    }
  }, [selectedOptions])

  const modifySelectedFileStatusesHandler = useCallback(options => {
    setSelectedOptions(options)
    const mappedOptions = options.map(({ value }) => value)
    const isFilesOverdueIncluded = mappedOptions.includes(STATUS_FILTER_FILES_OVERDUE)
    const someOfFilesApprovalStatusesIncluded = filesApprovalStatusOptions.some(option =>
      mappedOptions.includes(option.value)
    )
    if (isFilesOverdueIncluded && someOfFilesApprovalStatusesIncluded) {
      // if Files Overdue is selected then remove all file approval statuses
      const filesApprovalStatusOptionsValues = filesApprovalStatusOptions.map(option => option.value)
      return [...options].filter(({ value }) => !filesApprovalStatusOptionsValues.includes(value))
    } else {
      return options
    }
  }, [])

  const selectStatusHandler = useCallback(
    data => {
      setSelectedFileStatuses(modifySelectedFileStatusesHandler(data))
    },
    [modifySelectedFileStatusesHandler, setSelectedFileStatuses]
  )

  const setStatusesHandler = useCallback(
    (name, data) => {
      selectStatusHandler(modifySelectedFileStatusesHandler(data))
    },
    [modifySelectedFileStatusesHandler, selectStatusHandler]
  )

  return (
    <MultiSelectBox
      name="File Status"
      options={formattedOptions}
      value={selectedFileStatuses}
      onChange={selectStatusHandler}
      placeholder="File Status"
      isSearchable={false}
      setFieldValue={setStatusesHandler}
      portaled={true}
    />
  )
}

FileStatusesFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default FileStatusesFilter
