import React from 'react'
import { useTranslation } from 'react-i18next'

import MarginField from '../../../../../forms/ReusableFormFields/MarginFields/MarginField'
import Field from '../../../../../components/Form/Field'
import Button from '../../../../../components/Button'
import RadioBlock from '../../../../../components/Form/RadioBlock'

import { getAppDomainName } from '../../../../../features/helpers/getAppDomainName'

import {
  SPECIFIC_CAMPAIGN_PLATFORM,
  SPECIFIC_CAMPAIGN_MARGIN,
  SPECIFIC_MARGIN_OPTION,
  SPECIFIC_CAMPAIGN
} from '../fields'
import platformList from '../../../../../constants/selectLists/platformList'

import { OPTION_NO, OPTION_YES } from '../../../../../constants/forms'

import useCommonStyles from '../../../../../styles/common/stepForms'
import useStyles from '../styles'

function SpecificCampaignsMarginStep({ formik, handleStepChange }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const appName = getAppDomainName()

  const { values, setFieldValue } = formik
  const stepFields = [SPECIFIC_MARGIN_OPTION, SPECIFIC_CAMPAIGN_PLATFORM, SPECIFIC_CAMPAIGN, SPECIFIC_CAMPAIGN_MARGIN]

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={commonClasses.stepSubTitle}>{t('Margins')}</div>
      <div className={commonClasses.stepTitle}>{t('Please enter the platform, campaign id and margin override')}</div>
      <div className={commonClasses.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_campaign_margin"
          name={SPECIFIC_MARGIN_OPTION}
          value={OPTION_YES}
          selectedValue={values[SPECIFIC_MARGIN_OPTION]}
          label={t('I want to apply a campaign margin override')}
        >
          <p>{t('Please enter the % amount that you want to make.')}</p>
          <p>{t('ManageMarginPage.marginText', { appName })}</p>
          <Field
            name={SPECIFIC_CAMPAIGN_PLATFORM}
            formik={formik}
            placeholder="PLATFORM"
            options={platformList}
            isSearchable={false}
          />
          <Field className={classes.campaignID} name={SPECIFIC_CAMPAIGN} formik={formik} placeholder="Campaign ID" />
          <MarginField formik={formik} name={SPECIFIC_CAMPAIGN_MARGIN} />
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_no_margin"
          name={SPECIFIC_MARGIN_OPTION}
          value={OPTION_NO}
          selectedValue={values[SPECIFIC_MARGIN_OPTION]}
          label={t('I don’t want to apply a margin on this specific campaign')}
        >
          <Field
            name={SPECIFIC_CAMPAIGN_PLATFORM}
            formik={formik}
            placeholder="PLATFORM"
            options={platformList}
            isSearchable={false}
          />
          <Field className={classes.campaignID} name={SPECIFIC_CAMPAIGN} formik={formik} placeholder="Campaign ID" />
        </RadioBlock>
      </div>
      <div className={commonClasses.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default SpecificCampaignsMarginStep
