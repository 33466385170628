import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import FiltersSearch from '../../../../features/components/Filters/FiltersSearch'
import BookedMediaStatusFilter, {
  BOOKED_MEDIA_STATUS_FILTER
} from '../../../../features/components/Filters/BookedMediaStatusFilter'
import BookedMediaDownloadCSV from './BookedMediaDownloadCSV'
import ProductTagsFilter, { SELECTED_TAG } from '../../../../features/components/Filters/ProductTagsFilter'
import { BookedMediaDataProvider, BookedMediaFiltersContext } from './BookedMediaFiltersContext'
import DropdownFilters from '../../../../features/components/DropdownFilters'
import MultipleControllerSelfAccountsFilter, {
  MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER
} from '../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'
import {
  BRAND_SUB_CATEGORY,
  BrandSubCategoryFilter
} from '../../../../features/components/DropdownFilters/components/BrandSubCategoryFilter'
import DateFilter, { DATE_RANGE_FILTER } from '../../../../features/components/DropdownFilters/components/DateFilter'
import SubCategoriesFilter, {
  SELECTED_SUB_CATEGORIES
} from '../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { mediaSubCategoriesSelector } from '../../../../modules/selectors/mediaOrdersProducts'
import SubSubCategoriesFilter, {
  SELECTED_SUB_SUB_CATEGORIES
} from '../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import {
  BRAND_CATEGORY,
  BrandCategory
} from '../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import CategoriesFilter, {
  SELECTED_CATEGORIES
} from '../../../../features/components/DropdownFilters/components/CategoriesFilter'
import LocationsFilter, {
  SELECTED_LOCATIONS
} from '../../../../features/components/DropdownFilters/components/LocationsFilter'
import ProductsFilter, {
  SELECTED_PRODUCTS
} from '../../../../features/components/DropdownFilters/components/ProductsFilter'
import CustomBookingDateFilter from '../../../../features/components/Filters/CustomBookingDateFilter'

import { getAllSubSubCategories } from '../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter/helpers'
import { getIsDateRangeFilterSelected } from '../../../../features/components/DropdownFilters/components/DateFilter/helpers'

import { selectedControllerIdSelector, userSelfAccountTypeSelector } from '../../../../modules/selectors/app'
import { clearGetMediaSubCategories, getMediaSubCategories } from '../../../../modules/actions/mediaOrdersProducts'
import { clearGetSelfAccountSubCategories, getSelfAccountSubCategories } from '../../../../modules/actions/selfAccounts'
import { selfAccountSubCategoriesSelector } from '../../../../modules/selectors/selfAccounts'

import { formatDateRangeLabel } from '../../../../features/components/Filters/DateRangeFilter/helpers'

import { CONTROLLER_TYPE } from '../../../../constants/permissions'

import useStyles from './styles'

export const BOOKED_MEDIA_AND_FILES_SEARCH = 'booked_media_and_files_search'

const BookedMediaAndFilesFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const controllerId = useSelector(selectedControllerIdSelector)
  const selfAccountSubCategories = useSelector(selfAccountSubCategoriesSelector)

  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const allSubSubCategories = useMemo(() => getAllSubSubCategories(mediaSubCategories), [mediaSubCategories])

  const filters = useMemo(() => {
    return [
      {
        component: <BookedMediaStatusFilter Context={BookedMediaFiltersContext} />,
        title: 'Status',
        filterName: BOOKED_MEDIA_STATUS_FILTER,
        show: true
      },
      {
        component: <MultipleControllerSelfAccountsFilter Context={BookedMediaFiltersContext} />,
        title: 'Accounts',
        filterName: MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER,
        show: userAccountType === CONTROLLER_TYPE
      },
      {
        component: <CategoriesFilter Context={BookedMediaFiltersContext} />,
        title: 'Categories',
        filterName: SELECTED_CATEGORIES,
        show: true
      },
      {
        component: <SubCategoriesFilter Context={BookedMediaFiltersContext} />,
        title: 'Asset Type',
        filterName: SELECTED_SUB_CATEGORIES,
        show: !!mediaSubCategories.length
      },
      {
        component: <SubSubCategoriesFilter Context={BookedMediaFiltersContext} />,
        title: 'Asset Subtype',
        filterName: SELECTED_SUB_SUB_CATEGORIES,
        show: !!allSubSubCategories.length
      },
      {
        component: <BrandCategory Context={BookedMediaFiltersContext} />,
        title: 'Brand Category',
        filterName: BRAND_CATEGORY,
        show: true
      },
      {
        component: <BrandSubCategoryFilter Context={BookedMediaFiltersContext} />,
        title: 'Brand Sub Category',
        filterName: BRAND_SUB_CATEGORY,
        show: !!selfAccountSubCategories.length
      },
      {
        component: <ProductsFilter Context={BookedMediaFiltersContext} />,
        title: 'Products',
        filterName: SELECTED_PRODUCTS,
        show: true
      },
      {
        component: <LocationsFilter Context={BookedMediaFiltersContext} placeholder="Location" />,
        title: 'Location',
        filterName: SELECTED_LOCATIONS,
        show: true
      },
      {
        component: <ProductTagsFilter Context={BookedMediaFiltersContext} />,
        title: 'Filter',
        filterName: SELECTED_TAG,
        show: true
      },
      {
        component: <DateFilter Context={BookedMediaFiltersContext} formatDateRangeLabel={formatDateRangeLabel} />,
        filterName: DATE_RANGE_FILTER,
        title: 'Date',
        show: true,
        allowReset: false,
        getIsFilterSelected: getIsDateRangeFilterSelected
      }
    ]
  }, [userAccountType, mediaSubCategories, allSubSubCategories, selfAccountSubCategories])

  useEffect(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    dispatch(
      getSelfAccountSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    dispatch(clearGetMediaSubCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(clearGetSelfAccountSubCategories())
  }, [dispatch])

  return (
    <BookedMediaDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <BookedMediaDownloadCSV />
        <FiltersSearch searchId={BOOKED_MEDIA_AND_FILES_SEARCH} className={classes.searchInput} />
        <DropdownFilters filters={filters} Context={BookedMediaFiltersContext} />
        <CustomBookingDateFilter Context={BookedMediaFiltersContext} />
      </div>
    </BookedMediaDataProvider>
  )
}

BookedMediaAndFilesFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default BookedMediaAndFilesFilters
