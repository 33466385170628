import {
  BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION,
  BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION,
  FILE_REVIEWER_PERMISSION,
  FINANCE_ADMIN_PERMISSION,
  MODIFY_QUOTATION_PRICING,
  PACKAGE_APPROVE_PERMISSION
} from '../../../../../constants/permissions'

export const getInitialValues = (memberData = {}) => {
  const { permissions = [] } = memberData

  return {
    [BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION]: permissions.includes(BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION),
    [BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION]: permissions.includes(BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION),
    [MODIFY_QUOTATION_PRICING]: permissions.includes(MODIFY_QUOTATION_PRICING),
    [PACKAGE_APPROVE_PERMISSION]: permissions.includes(PACKAGE_APPROVE_PERMISSION),
    [FINANCE_ADMIN_PERMISSION]: permissions.includes(FINANCE_ADMIN_PERMISSION),
    [FILE_REVIEWER_PERMISSION]: permissions.includes(FILE_REVIEWER_PERMISSION)
  }
}
