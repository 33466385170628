import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  filtersRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
    flexWrap: 'wrap'
  }
}))

export default useStyles
