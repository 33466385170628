import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import Checkbox from '../../../../../../components/Form/Checkbox'

import { insertIf } from '../../../../../../helpers/common'

import {
  BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION,
  BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION,
  BUYER,
  FILE_REVIEWER_PERMISSION,
  FINANCE_ADMIN_PERMISSION,
  MODIFY_QUOTATION_PRICING,
  PACKAGE_APPROVE_PERMISSION
} from '../../../../../../constants/permissions'

import useStyles from './styles'

const ControllerPermissionsEditFormContent = ({ formik, memberRole }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const checkboxHandler = useCallback(
    e => {
      const { name } = e.target
      setFieldValue(name, !values[name])
    },
    [values, setFieldValue]
  )

  const permissionsCheckboxes = useMemo(() => {
    return [
      {
        name: BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION,
        title: t('Booking confirmation emails (bcc)')
      },
      {
        name: BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION,
        title: t('Booking confirmation emails (cc)')
      },
      {
        name: MODIFY_QUOTATION_PRICING,
        title: t('Modify proposal pricing')
      },
      {
        name: PACKAGE_APPROVE_PERMISSION,
        title: t('Media Package Approver')
      },
      {
        name: FINANCE_ADMIN_PERMISSION,
        title: t('Finance Admin')
      },
      ...insertIf(memberRole !== BUYER, {
        name: FILE_REVIEWER_PERMISSION,
        title: t('File Reviewer')
      })
    ]
  }, [t, memberRole])

  const alphabeticallySortedPermissionsCheckboxes = useMemo(() => {
    return permissionsCheckboxes.sort((a, b) => a.title.localeCompare(b.title))
  }, [permissionsCheckboxes])

  return (
    <FieldsSection title="Permissions">
      {alphabeticallySortedPermissionsCheckboxes.map(({ name, title }) => (
        <Checkbox
          key={name}
          id={name}
          name={name}
          checked={values[name]}
          title={title}
          onCheck={checkboxHandler}
          className={classes.permissionCheckbox}
        />
      ))}
    </FieldsSection>
  )
}

export default ControllerPermissionsEditFormContent
