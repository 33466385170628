import PropTypes from 'prop-types'

import DateRangeFilter from '../../../Filters/DateRangeFilter'

import useStyles from './styles'

export const DATE_RANGE_FILTER = 'dateRangeFilter'

const DateFilter = ({ dateRangeFilterName = DATE_RANGE_FILTER, ...props }) => {
  const classes = useStyles()

  return (
    <DateRangeFilter
      {...props}
      dateRangeFilterName={dateRangeFilterName}
      isFloatingDropdown={false}
      // styles
      dropdownClassName={classes.dateRangeDropdown}
      rangeClassName={classes.range}
      dateRangesClassName={classes.dateRanges}
    />
  )
}

DateFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  dateRangeFilterName: PropTypes.string
}

export default DateFilter
