import {
  BRAND_ID,
  BRAND_NAME,
  BRANDS,
  CURRENCY,
  CUSTOMER_ID,
  LANDSCAPE_LOGO,
  LANDSCAPE_LOGO_FILE_NAME,
  OFFSITE_CAMPAIGN_TYPES,
  SQUARE_LOGO,
  SQUARE_LOGO_FILE_NAME
} from '../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'
import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import { NAME, TAGS } from './fields'
import { REPRESENTATIVE } from '../../../../../../constants/mediaOrders'
import { CATEGORY, SUBCATEGORY } from '../../fields'

export const transformValuesToBE = ({ values, isController, controllerId }) => {
  const formattedFilledBrands = formatEditBrandsToBE(values[BRANDS])

  const selectedTypesValues = values[OFFSITE_CAMPAIGN_TYPES]
  return {
    [NAME]: values[NAME],
    ...(formattedFilledBrands.length && { [BRANDS]: formattedFilledBrands }),
    ...(isController && {
      // controller type fields
      controller: controllerId,
      [TAGS]: values[TAGS].map(item => item.value),
      [ALLOW_CUSTOM_CLICK_THROUGH_LINK]: values[ALLOW_CUSTOM_CLICK_THROUGH_LINK],
      // went through the object and populate only keys which are "true"
      [OFFSITE_CAMPAIGN_TYPES]: Object.keys(selectedTypesValues).filter(key => selectedTypesValues[key])
    }),
    ...(values[REPRESENTATIVE] && { [REPRESENTATIVE]: values[REPRESENTATIVE] }),
    [REPRESENTATIVE]: values[REPRESENTATIVE],
    [CUSTOMER_ID]: values[CUSTOMER_ID],
    ...(values[CURRENCY] && { [CURRENCY]: values[CURRENCY] })
  }
}

const formatEditBrandsToBE = brands => {
  return (
    brands
      .filter(brand => brand[BRAND_NAME])
      // when there is id - the brand will be updated
      // when there is no id - the brand will be created
      // if brand wasn't passed - it will be removed
      .map(brand => {
        const brandId = brand.data?.id

        return {
          ...(brandId && { id: brandId }),
          ...brand.data,
          // pass all values as it is
          // when field is empty it will reset the value(for example remove category/logo..)
          [BRAND_NAME]: brand[BRAND_NAME],
          [BRAND_ID]: brand[BRAND_ID],
          [CATEGORY]: brand[CATEGORY]?.value || null,
          [SUBCATEGORY]: brand[SUBCATEGORY],
          [SQUARE_LOGO]: brand[SQUARE_LOGO],
          [SQUARE_LOGO_FILE_NAME]: brand[SQUARE_LOGO_FILE_NAME],
          [LANDSCAPE_LOGO_FILE_NAME]: brand[LANDSCAPE_LOGO_FILE_NAME],
          [LANDSCAPE_LOGO]: brand[LANDSCAPE_LOGO]
        }
      })
  )
}
