import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import RadioBlock from '../../../../../../components/Form/RadioBlock'
import SelectMultiLevel from '../../../../../../components/SelectMultiLevel'

import {
  getTikTokActionCategories,
  clearTikTokActionCategories
} from '../../../../../../modules/actions/tikTokConstants'
import {
  tikTokActionCategoriesIsLoadingSelector,
  tikTokActionCategoriesSelector
} from '../../../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { OPTION_NO } from '../../../../../../constants/forms'
import { VIDEO_CATEGORIES, ENGAGED_OPTION, TARGET_VIDEO_CATEGORIES_OPTION, WATCHED_OR_ENGAGED_OPTION } from '../fields'
import { VIDEO_RELATED } from '../../../../../../constants/lineItems'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [VIDEO_CATEGORIES]

const VideoCategoriesStep = ({ formik, handleStepChange, adAccountId: providedAdAccountId }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const actionCategories = useSelector(tikTokActionCategoriesSelector)
  const actionCategoriesIsLoading = useSelector(tikTokActionCategoriesIsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const videoCategories = useMemo(() => {
    return actionCategories.filter(item => item.action_scene === VIDEO_RELATED)
  }, [actionCategories])

  const { values, errors, touched, setFieldValue } = formik

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  // categories used both on this step, and AccountCategoriesStep
  useEffect(() => {
    dispatch(getTikTokActionCategories({ account: adAccountId }))

    return () => {
      dispatch(clearTikTokActionCategories())
    }
  }, [dispatch, adAccountId])

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>
        {t('Do you want to target based on the categories of videos people have interacted with in the past?')}
      </div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_not_target_categories_of_videos"
          name={TARGET_VIDEO_CATEGORIES_OPTION}
          value={OPTION_NO}
          selectedValue={values[TARGET_VIDEO_CATEGORIES_OPTION]}
          label={t('No')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_target_watched_or_engaged_videos"
          name={TARGET_VIDEO_CATEGORIES_OPTION}
          value={WATCHED_OR_ENGAGED_OPTION}
          selectedValue={values[TARGET_VIDEO_CATEGORIES_OPTION]}
          label={t('Yes, select categories of videos that people have watched or engaged with')}
          error={errors[VIDEO_CATEGORIES]}
          touched={touched[VIDEO_CATEGORIES]}
        >
          <SelectMultiLevel
            placeholder={t('Add a category')}
            setFieldValue={setFieldValue}
            value={values[VIDEO_CATEGORIES]}
            name={VIDEO_CATEGORIES}
            options={videoCategories}
            isLoading={actionCategoriesIsLoading}
          />
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_target_engaged_videos"
          name={TARGET_VIDEO_CATEGORIES_OPTION}
          value={ENGAGED_OPTION}
          selectedValue={values[TARGET_VIDEO_CATEGORIES_OPTION]}
          label={t('Yes, select categories of videos that people have engaged with')}
          error={errors[VIDEO_CATEGORIES]}
          touched={touched[VIDEO_CATEGORIES]}
        >
          <SelectMultiLevel
            placeholder={t('Add a category')}
            setFieldValue={setFieldValue}
            value={values[VIDEO_CATEGORIES]}
            name={VIDEO_CATEGORIES}
            options={videoCategories}
            isLoading={actionCategoriesIsLoading}
          />
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default VideoCategoriesStep
