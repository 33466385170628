import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import BookedMediaAndFilesFilters from './BookedMediaAndFilesFilters'
import BookedMediaAndFilesTable from './BookedMediaAndFilesTable'

import { clearGetOrderBookedMedia, getOrderBookedMedia } from '../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../modules/selectors/app'
import { clearControllerMembers, getControllerMembers } from '../../../modules/actions/controller'

import useSettingsClasses from '../styles'

const BookedMediaAndFiles = () => {
  const dispatch = useDispatch()
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const handleGetBookedMedia = useCallback(
    filterRequestParams => {
      const {
        date_start,
        date_end,
        account,
        media_product,
        location,
        media_category,
        media_sub_category,
        media_sub_sub_category,
        brand_category,
        brand_subcategory,
        ...filterParams
      } = filterRequestParams

      dispatch(
        getOrderBookedMedia(
          {
            controller: selfAccountControllerId,
            limit: 20,
            ordering: 'date_start',
            date_start_after: date_start,
            date_start_before: date_end,
            ...(account && { account: account.join(',') }), // support multiple products,
            ...(media_product && { media_product: media_product.join(',') }), // support multiple products,
            ...(location && { location: location.join(',') }), // support multiple products,
            ...(media_category && { media_category: media_category.join(',') }), // support multiple products,
            ...(media_sub_category && { media_sub_category: media_sub_category.join(',') }), // support multiple products,
            ...(media_sub_sub_category && { media_sub_sub_category: media_sub_sub_category.join(',') }), // support multiple products,
            ...(brand_category && { brand_category: brand_category.join(',') }), // support multiple products,
            ...(brand_subcategory && { brand_subcategory: brand_subcategory.join(',') }), // support multiple products,
            ...filterParams
          },
          {
            shouldClearExistingState: true
          }
        )
      )
    },
    [dispatch, selfAccountControllerId]
  )

  useEffect(
    () => () => {
      dispatch(clearGetOrderBookedMedia())
    },
    [dispatch]
  )

  useEffect(() => {
    // members are used in UploadedFilesCell
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Booked Media')}</h1>
      <BookedMediaAndFilesFilters onFiltersChange={handleGetBookedMedia} />
      <BookedMediaAndFilesTable />
    </>
  )
}

export default BookedMediaAndFiles
