import { addDays, format } from 'date-fns'

import {
  getProductStartDate,
  getProductStartPeriod
} from '../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'

import { DATES_FORMAT_BE } from '../../../../constants/dates'
import { calc } from '../../../../helpers/numbers'
import { AVAILABLE_QUANTITY } from './ProductsManage/ProductsTable/DatesCell/EditPeriods/DatePickerPeriodSelector/hooks/useInventoryPeriods'

export const PRODUCT_PERIODS_DATES = 'dates'
// grouped publications dates are to use only for UI representation for example:
// regular will be 01, 02, 03, 04 and 08,09 and grouped will be 01-04 and 08-09
// this is expected to be used only on BookingPeriodSelector component to group dates by selected ranges
export const PRODUCT_GROUPED_PUBLICATIONS_DATES = 'product_grouped_publications_dates'
export const QUANTITY = 'quantity'
export const ORIGINAL_PRODUCT_PRICE = 'original_product_price'
export const CUSTOM_PRODUCT_PRICE = 'custom_product_price'
export const CUSTOM_PRODUCT_DISCOUNT = 'custom_product_discount'
export const MEDIA_PRODUCTS = 'products'
export const CUSTOM_ORDER_DISCOUNT = 'custom_order_discount'
export const CUSTOM_ORDER_PRICE = 'custom_order_price'
// additional data:
export const CAMPAIGN_NAME = 'campaign_name'
export const BRAND = 'brand'
export const BUYER = 'buyer'

export const initialMediaProducts = []
export const initialBrand = ''

export const AVAILABLE_START_PERIOD_DATE = 'available_start_period_date'
export const initialProductValues = {
  [PRODUCT_PERIODS_DATES]: [],
  [PRODUCT_GROUPED_PUBLICATIONS_DATES]: [],
  [QUANTITY]: 1,
  [ORIGINAL_PRODUCT_PRICE]: 0,
  [CUSTOM_PRODUCT_PRICE]: 0,
  [CUSTOM_PRODUCT_DISCOUNT]: 0
}
export const getInitialProductSetup = productData => {
  const productStartDate = getProductStartDate(productData)

  const startPeriodDate = getProductStartPeriod(productData.period, productStartDate)

  return {
    data: {
      ...productData,
      // available start date - used during period selection
      [AVAILABLE_START_PERIOD_DATE]: format(startPeriodDate, DATES_FORMAT_BE)
    }
  }
}

export function splitPeriodsToGroups(periods) {
  const groups = []
  let currentGroup = []

  for (let i = 0; i < periods.length; i++) {
    const period = periods[i]
    currentGroup.push(period)

    if (i < periods.length - 1) {
      const nextPeriod = periods[i + 1]
      const nextDayAfterCurrent = format(addDays(new Date(period.date_end), 1), DATES_FORMAT_BE)

      if (nextPeriod.date_start !== nextDayAfterCurrent) {
        groups.push(currentGroup)
        currentGroup = []
      }
    }
  }

  if (currentGroup.length > 0) {
    groups.push(currentGroup)
  }

  return groups
}

export const getInitialValues = contract => {
  const contractData = contract?.media_order
  const products = contractData?.[MEDIA_PRODUCTS]

  const formattedProducts = products ? getFormattedProducts(contractData?.[MEDIA_PRODUCTS]) : initialMediaProducts

  const formattedBuyer = {
    value: contractData?.[BUYER]?.id,
    label: contractData?.[BUYER]?.full_name
  }

  return {
    [MEDIA_PRODUCTS]: formattedProducts,
    [CUSTOM_ORDER_DISCOUNT]: 0,
    [CUSTOM_ORDER_PRICE]: 0,
    [CAMPAIGN_NAME]: contractData?.[CAMPAIGN_NAME] || '',
    [BRAND]: contractData?.[BRAND]?.id || initialBrand,
    [BUYER]: contractData?.[BUYER]?.id ? formattedBuyer : undefined
  }
}

export const getFormattedProducts = products =>
  products?.map(product => {
    const periods = product.prices.map(period => {
      const inventoryDetail = period.inventory_detail
      return {
        ...period,
        inventory: parseInt(period.inventory), // format inventory to number, so it match with inventory from
        // inventory endpoint
        value: period.date_start,
        // set available quantity similar to the new periods formatting during create/update
        [AVAILABLE_QUANTITY]: inventoryDetail?.quantity - inventoryDetail?.booked_quantity
      }
    })
    const productOriginalPrice = periods.reduce((acc, { price }) => {
      return calc(acc).add(price).toDP(2).toNumber()
    }, 0)
    const productCustomPrice = product.prices.reduce((acc, { price, price_with_discount, discount }) => {
      // if the product has discount don't calculate it, it will be calculated with the CUSTOM_PRODUCT_DISCOUNT field
      if (product.discount) {
        return calc(acc).add(price).toDP(2).toNumber()
      } else {
        // if no discount is set pass the price with discount
        // it handles the case when the price is set manually(custom price was set for product)
        return calc(acc).add(price_with_discount).toDP(2).toNumber()
      }
    }, 0)

    const productStartDate = getProductStartDate(product) // todo
    const startPeriodDate = getProductStartPeriod(product.period, productStartDate)

    return {
      id: product.media_product,
      isNewProduct: false,
      data: {
        ...product,
        name: product.original_product_name,
        // original product id is stored in `media_product` field of the product data,
        id: product.media_product,
        // save media order product id to new separate field
        mediaOrderProductId: product.id,
        // BE products data differs in contract from the DETAIL endpoint, so fields should be mapped
        discounts: product.media_discounts,
        discount_preset: product.media_discount_preset
      },
      // split periods to groups if they are not continuous, so each group include only continuos periods:
      [PRODUCT_GROUPED_PUBLICATIONS_DATES]: splitPeriodsToGroups(periods, product.period), // todo
      [QUANTITY]: product[QUANTITY],
      // original product total periods, which is always without discount and is per one product quantity
      [ORIGINAL_PRODUCT_PRICE]: calc(productOriginalPrice).toNumber(),
      // don't calculate discount here it`s handled by the CUSTOM_PRODUCT_DISCOUNT field
      [CUSTOM_PRODUCT_PRICE]: calc(productCustomPrice).mul(product[QUANTITY]).toNumber(),
      [CUSTOM_PRODUCT_DISCOUNT]: calc(product.discount).mul(100).toDP(2).toNumber(),
      [PRODUCT_PERIODS_DATES]: periods,
      [AVAILABLE_START_PERIOD_DATE]: format(startPeriodDate, DATES_FORMAT_BE),
      order: product.order
    }
  })
