import { UPLOADED_MEDIA_TYPE } from '../../ReusableFormFields/AdFileUpload/fields'
import { CALL_TO_ACTION, LEARN_MORE } from '../../ReusableFormFields/CallToActionFields/fields'

// CAROUSEL IDENTIFIER
export const AD_PREVIEW_CAROUSEL_INDEX_KEY = 'ad_preview_carousel_index_key'
// NAME
export const NAME = 'name'
// FORMAT
export const FORMAT_OPTION = 'format_option'
export const FORMAT_PRODUCT = 'format_product'
export const FORMAT_SINGLE = 'format_single'
export const FORMAT_CAROUSEL = 'format_carousel'
export const FORMAT_STORY = 'format_story'
// PRIMARY TEXT
export const AD_TEXT = 'ad_text'
export const PRIMARY_TEXT_LIST = 'primary_text_list'
// FILE
export const MEDIA_FILE_OPTION = 'media_file_option'
export const MEDIA_FILE_IMAGE_USE_LIBRARY = 'media_file_image_use_library_image'
export const MEDIA_FILE_VIDEO_USE_LIBRARY = 'media_file_video_use_library_video'
export const MEDIA_FILE_PREVIEW = 'media_file_preview'
export const MEDIA_FILE_IMAGE_PREVIEW = 'media_file_image_preview'
export const AD_FILE = 'ad_file'
export const FILE_NAME = 'file_name'
export const FILE_URL = 'file_url'
export const FILE_URL_BLOB = 'file_url_blob'
export const MEDIA_TYPE = 'media_type'
export const FILE_TYPE = 'file_type'
export const FILE_HASH = 'file_hash'
export const FILE_ID = 'file_id'
export const VIDEO_PICTURE = 'video_picture'
// СLICK TROUGH LINK
export const AD_LINK = 'ad_link'
// DISPLAY URL
export const AD_CUSTOM_URL = 'ad_custom_url'
export const AD_DISPLAY_URL = 'ad_display_url'
// HEADLINE
export const AD_HEADLINE = 'ad_headline'
export const HEADLINES_LIST = 'headlines_list'
// DESCRIPTION
export const AD_DESCRIPTION = 'ad_description'
export const DESCRIPTIONS_LIST = 'descriptions_list'
// FACEBOOK
export const FB_PAGE_CHOICE = 'fb_page_choice'
export const INSTA_ACCOUNT_CHOICE = 'insta_account_choice'
// CAROUSEL
export const CAROUSEL_ITEMS = 'carousel_items'
// ITEM FORM
export const IS_ITEM_FORM_OPENED = 'is_item_form_focused'
//STORIES
export const STORIES_ITEMS = 'stories_items'
export const STORIES_ITEM_SELECTED_INDEX = 'stories_item_selected_index'
// MEDIA
export const SINGLE_MEDIA_ITEM = 'singleMediaItem'
export const MEDIA_SIZE = 'media_size'
export const MEDIA_WIDTH = 'media_width'
export const MEDIA_HEIGHT = 'media_height'
export const MEDIA_PHYSICAL_WIDTH = 'media_physical_width'
export const MEDIA_PHYSICAL_HEIGHT = 'media_physical_height'
export const MEDIA_FORMAT = 'media_format'
export const MEDIA_RATIO = 'media_ratio'
export const MEDIA_DPI = 'media_dpi'
export const MEDIA_DURATION = 'video_duration'

// IMAGE VALIDATION
export const IMAGE = 'image'
export const IMAGE_MAX_SIZE = 31457280 //31457280 bytes = 30 MB
export const IMAGE_MIN_WIDTH = 600
export const IMAGE_MIN_HEIGHT = 600
export const IMAGE_FORMATS = ['JPG', 'jpg', 'JPEG', 'jpeg', 'PNG', 'png']
// VIDEO VALIDATION
export const VIDEO = 'video'
export const VIDEO_MAX_SIZE = 4294967296 //4294967296 bytes = 4GB
export const STORY_VIDEO_MAX_SIZE = 262144000 // 262144000 bytes = 250MB
export const VIDEO_MIN_WIDTH = 120
export const VIDEO_MIN_HEIGHT = 120
export const VIDEO_FORMATS = ['MP4', 'mp4', 'MOV', 'mov', 'GIF', 'gif']
export const VIDEO_MIN_DURATION = 1
export const VIDEO_MAX_DURATION = 14460 // 241 m = 14460 s
export const STORY_VIDEO_MAX_DURATION = 60 // 60 s
// MEDIA VALIDATION
export const STORY_MEDIA_MIN_WIDTH = 500
export const MEDIA_MIN_RATIO_9_16 = 0.5625 // 9:16
export const MEDIA_MAX_RATIO_16_9 = 1.78 // 16:9
export const MEDIA_MIN_RATIO_1 = 1
export const MEDIA_MAX_RATIO_1_91 = 1.91
// CUSTOMIZED_MEDIA
export const IS_CUSTOMIZED_MEDIA = 'customized_media_option'
// STATUS
export const STATUS = 'status'
// PIXEL_CHOICE
export const PIXEL_CHOICE = 'pixel_choice'

export const initialLink = 'https://'
export const initialFileValues = {
  [FILE_NAME]: '',
  [FILE_URL]: '',
  [FILE_URL_BLOB]: '',
  [FILE_TYPE]: '',
  [MEDIA_TYPE]: '',
  [FILE_HASH]: '',
  [FILE_ID]: '',
  [UPLOADED_MEDIA_TYPE]: '',
  [VIDEO_PICTURE]: ''
}
export const initialMediaValues = {
  [MEDIA_SIZE]: '',
  [MEDIA_WIDTH]: '',
  [MEDIA_HEIGHT]: '',
  [MEDIA_FORMAT]: '',
  [MEDIA_RATIO]: '',
  [MEDIA_DURATION]: ''
}
export const initialCarouselItem = {
  ...initialFileValues,
  // HEADLINE
  [AD_HEADLINE]: '',
  [HEADLINES_LIST]: [{ text: '' }],
  // DESCRIPTION
  [AD_DESCRIPTION]: '',
  [DESCRIPTIONS_LIST]: [{ text: '' }],
  // CALL-TO-ACTION
  [CALL_TO_ACTION]: LEARN_MORE,
  // CLICK-TROUGH-LINK
  [AD_LINK]: initialLink,
  [FORMAT_OPTION]: FORMAT_CAROUSEL,
  [MEDIA_FILE_OPTION]: MEDIA_FILE_IMAGE_USE_LIBRARY
}
export const initialStoriesItem = {
  ...initialFileValues,
  // CALL-TO-ACTION
  [CALL_TO_ACTION]: LEARN_MORE,
  // CLICK-TROUGH-LINK
  [AD_LINK]: initialLink,
  [MEDIA_FILE_OPTION]: MEDIA_FILE_IMAGE_USE_LIBRARY
}
