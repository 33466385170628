import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Button from '../../../../../../../components/Button'
import InfoModal from '../../../../../../../features/components/Modals/InfoModal'

import useSaveData from '../../ProductsTable/useSaveData'

import { ReactComponent as DiscountIcon } from '../../../../../../../assets/icons/discount-icon.svg'
import { DiscountsContext } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext'

import { calc } from '../../../../../../../helpers/numbers'
import { getOriginalProductPrice } from '../../ProductsTable/helpers/getProductValue'
import { calculateDiscountPercentage } from '../../ProductsTable/DiscountCell/helpers'

import { CUSTOM_PRODUCT_DISCOUNT, CUSTOM_PRODUCT_PRICE, MEDIA_PRODUCTS } from '../../../fields'
import { DISCOUNT_PERCENTAGE } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext/helpers'

import useStyles from './styles'

function ApplyDiscount({ values, products, setFieldValue }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const discountChangesRef = useRef({})
  const [showApplyDiscountModal, setShowApplyDiscountModal] = useState(false)
  const [updatedProducts, setUpdatedProducts] = useState([])

  const { productsDiscount } = useContext(DiscountsContext)
  const { handleSaveDataToBE } = useSaveData({ values, allowEdit: true, allowAutoSave: true })

  const handleCloseModal = useCallback(() => {
    setShowApplyDiscountModal(false)
    discountChangesRef.current = {}
  }, [])

  const hasDiscountToReset = useMemo(() => {
    return productsDiscount?.some(product => product[DISCOUNT_PERCENTAGE] > 0)
  }, [productsDiscount])

  const handleDiscountChange = useCallback(() => {
    // set new discount values to the formik fields
    setFieldValue(MEDIA_PRODUCTS, updatedProducts)
    // save contract data to BE
    handleSaveDataToBE({ updatedProductValues: updatedProducts })
    handleCloseModal()
  }, [handleSaveDataToBE, updatedProducts, setFieldValue, handleCloseModal])

  const handleOpenModal = useCallback(() => {
    const discountsSchema = productsDiscount.reduce((acc, product) => {
      if (product[DISCOUNT_PERCENTAGE] > 0) {
        acc[product.id] = product[DISCOUNT_PERCENTAGE]
      }
      return acc
    }, {})

    const discountResetProduct = products.map(product => {
      const newDiscount = discountsSchema[product.data?.id]
      const hasNewDiscount = newDiscount !== undefined
      const totalOriginalPrice = getOriginalProductPrice(product)
      const hasCustomPrice = product[CUSTOM_PRODUCT_PRICE] !== totalOriginalPrice

      // apply new discounts and reset all other products discount to 0(for custom price - reset to original price)
      const shouldResetDiscount = hasNewDiscount || hasCustomPrice || product[CUSTOM_PRODUCT_DISCOUNT] > 0

      if (shouldResetDiscount) {
        const oldDiscount = product[CUSTOM_PRODUCT_DISCOUNT]
        const newDiscountValue = hasNewDiscount ? calc(newDiscount).mul(100).toDP(2).toNumber() : 0

        discountChangesRef.current[product.data?.id] = {
          // cover the case when the discount was set or product custom price was set
          oldDiscount: oldDiscount || calculateDiscountPercentage(totalOriginalPrice, product[CUSTOM_PRODUCT_PRICE]),
          newDiscount: newDiscountValue
        }

        return {
          ...product,
          // reset discount to 0 if it has a discount and don't have in a DiscountsContext provider
          [CUSTOM_PRODUCT_DISCOUNT]: hasNewDiscount ? calc(newDiscount).mul(100).toDP(2).toNumber() : 0,
          // reset custom price to original price if it has a custom price and don't have in a DiscountsContext provider
          [CUSTOM_PRODUCT_PRICE]: hasCustomPrice ? totalOriginalPrice : product[CUSTOM_PRODUCT_PRICE]
        }
      } else {
        return product
      }
    })

    setUpdatedProducts(discountResetProduct)
    setShowApplyDiscountModal(true)
  }, [products, productsDiscount])

  if (!hasDiscountToReset) {
    return null
  }

  return (
    <>
      <Button type="button" onClick={handleOpenModal} className={classes.applyDiscountsBtn} isSmall={true}>
        <DiscountIcon />
        {t('Apply Rate Card Discounts')}
      </Button>

      <InfoModal
        isOpen={showApplyDiscountModal}
        onClose={handleCloseModal}
        modalTitle={t('Apply Rate Card Discounts')}
        ModalMessage={<p>{t('Applying rate card discounts will reset any discounts already applied.')}</p>}
      >
        <div className={classes.btnsContainer}>
          <Button type="button" onClick={handleCloseModal}>
            {t('Cancel')}
          </Button>
          <Button type="button" solid onClick={handleDiscountChange}>
            {t('Apply')}
          </Button>
        </div>
      </InfoModal>
    </>
  )
}

ApplyDiscount.propTypes = {
  values: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
}
export default ApplyDiscount
