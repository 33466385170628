import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import InstallationReportFilters from './InstallationReportFilters'
import InstallationReportTable from './InstallationReportTable'

import { getInstallationBookedMediaReport } from '../../../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

const InstallationReport = () => {
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const dispatch = useDispatch()

  const handleFilterChange = useCallback(
    filters => {
      dispatch(
        getInstallationBookedMediaReport({
          controller: selectedControllerId,
          ...filters
        })
      )
    },
    [dispatch, selectedControllerId]
  )

  return (
    <>
      <InstallationReportFilters onFiltersChange={handleFilterChange} />
      <br />
      <InstallationReportTable />
    </>
  )
}

export default InstallationReport
