import React, { memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Col from '../Col'

import { TABLE_ROW_CLASSNAME } from '../../../../../constants/pdf'
import { ROW_WITH_EXPAND_BUTTON_SPACING } from '../../../constants'

import useStyles from '../../../styles'

const TableRow = memo(
  ({ cols, rowData, rowIndex, isBigRow, hasExpandRowSpacing, rowClassName, columnClassName, isRowLoading }) => {
    const classes = useStyles({ isBigRow })

    return (
      <div
        className={classnames(classes[TABLE_ROW_CLASSNAME], rowClassName, {
          [classes[ROW_WITH_EXPAND_BUTTON_SPACING]]: hasExpandRowSpacing
        })}
      >
        {cols.map(colData => (
          <Col
            colData={colData}
            rowData={rowData}
            rowIndex={rowIndex}
            key={colData?.fieldKey || typeof colData?.header === 'string' ? colData?.header : uuidv4()}
            columnClassName={columnClassName}
            isColLoading={isRowLoading}
          />
        ))}
      </div>
    )
  }
)

const colsPropTypeShape = {
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  isBigRow: PropTypes.bool,
  rowIndex: PropTypes.number,
  hasExpandRowSpacing: PropTypes.bool,
  rowClassName: PropTypes.string,
  columnClassName: PropTypes.string,
  isRowLoading: PropTypes.bool
}

export default TableRow
