import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import AppLoader from '../../../components/Loaders/AppLoader'
import AppCrashedPage from '../../../pages/AppCrashedPage'
import SimpleHeaderTemplate from '../Header/SimpleHeaderTemplate'
import BannersContainer from './BannersContainer'
import ToastNotifications from './ToastNotifications'
import UnauthorizedApp from './UnauthorizedApp'
import ErrorMessage from '../../../components/Form/ErrorMessage'

import history from '../../../history'

import { checkAuthStatus } from '../../../modules/actions/cognito'

import {
  currentUserIdSelector,
  getCurrentUserIsLoadingSelector,
  getCurrentUserWasLoadedSelector
} from '../../../modules/selectors/app'
import { isAuthorizedSelector, isInitialAuthCheckingSelector } from '../../../modules/selectors/cognito'

import useMainStyles from '../../../styles'

// lazy load Authorized app AuthorizedApp component to reduce the initial bundle file size and reduce time to show
// first content
const AuthorizedApp = lazy(() => import('./AuthorizedApp'))

function App() {
  const currentUserId = useSelector(currentUserIdSelector)
  const currentUserWasLoaded = useSelector(getCurrentUserWasLoadedSelector)
  const isUserProfileLoading = useSelector(getCurrentUserIsLoadingSelector)
  const isAuthorized = useSelector(isAuthorizedSelector)
  const isInitialLoading = useSelector(isInitialAuthCheckingSelector)
  const [isFramed, setIsFramed] = useState(false)

  const dispatch = useDispatch()

  // main index.js file with JSS setup
  // this is invoked as StylesPovider and JSS setup should be invoked before(in main index.js)
  useMainStyles()

  const isAuthorizedLoading = isAuthorized && !isInitialLoading && isUserProfileLoading

  const CustomRouter = ({ history, ...props }) => {
    // this component allows to have ability using custom history as Router in v6 is not supporting that
    const [state, setState] = useState({
      action: history.action,
      location: history.location
    })

    useLayoutEffect(() => history.listen(setState), [history])

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />
  }

  useEffect(() => {
    dispatch(checkAuthStatus())
  }, [dispatch])

  useEffect(() => {
    // Checking if the page is in an iframe: determine if the current window (window.self) is the top-level window (window.top).
    // If they are not equal, it means that the page is being displayed within an iframe.
    if (window.top !== window.self) {
      // If framed, set isFramed state to true
      setIsFramed(true)
    }
  }, [])

  if (isFramed) {
    // The application should utilise frame-busting code. Frame-busting code is JavaScript which attempts to block the page from loading within an iFrame in situations where the X-Frame-Options header has not been respected by the browser.
    return <ErrorMessage error="This content cannot be displayed within a frame." />
  }

  return (
    <Sentry.ErrorBoundary
      fallback={() => {
        // Represent the Fallback page when App is crashed:
        return (
          <>
            <SimpleHeaderTemplate logoUrl={'/'} />
            <AppCrashedPage />
          </>
        )
      }}
      beforeCapture={scope => {
        // include userId for sentry issue
        scope.setTag('currentUserId', currentUserId)
        scope.setTag('release', process.env.REACT_APP_BUILD_VERSION)
        scope.setTag('crashedPageShow', true)
      }}
    >
      <Suspense fallback={<AppLoader />}>
        <CustomRouter history={history}>
          {
            // doesn't render routes until not getting authorised from Cognito
            isInitialLoading || isAuthorizedLoading ? (
              <AppLoader />
            ) : (
              <>
                <BannersContainer />
                {isAuthorized ? (
                  // don't render AuthorizedApp until user profile data is loaded, as it's main data for app
                  // AuthorizedRoutes are App
                  // this also helps to reload app after language change
                  currentUserWasLoaded ? (
                    <AuthorizedApp />
                  ) : (
                    <AppLoader />
                  )
                ) : (
                  // UnauthorizedRoutes are Site
                  // put Suspense component here to prevent css missing in Header during the route switch
                  <Suspense fallback={<AppLoader />}>
                    <UnauthorizedApp />
                  </Suspense>
                )}
              </>
            )
          }
        </CustomRouter>
      </Suspense>
      <ToastNotifications />
    </Sentry.ErrorBoundary>
  )
}

export default App
