import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'
import { getLoadOptionsHandler } from '../../../../helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { getMediaProductLocationsService } from '../../../../../modules/services/mediaOrdersProductLocations'

export const SELECTED_LOCATIONS = 'selectedLocations'

export const LocationsFilter = ({ Context, placeholder }) => {
  const controllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_LOCATIONS]: selectedLocations } = filtersState

  const setSelectedLocations = useCallback(
    newLocations => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_LOCATIONS]: newLocations
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const formatLocations = useCallback(
    locations =>
      formatOptionsList({
        list: locations,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getMediaProductLocationsService,
      params: {
        controller: controllerId,
        ordering: 'name',
        fields: ['id', 'name'].join(',')
      },
      formatOptions: formatLocations
    })
  }, [controllerId, formatLocations])

  const onLocationsChange = useCallback(
    selectedOptions => {
      setSelectedLocations(selectedOptions)
    },
    [setSelectedLocations]
  )

  const setLocationsHandler = useCallback(
    (name, data) => {
      setSelectedLocations(data)
    },
    [setSelectedLocations]
  )

  return (
    <MultiSelectBox
      name="Locations"
      value={selectedLocations}
      onChange={onLocationsChange}
      loadOptions={loadOptions}
      placeholder={placeholder || 'Locations'}
      setFieldValue={setLocationsHandler}
      portaled
      allowSelectAll={true}
    />
  )
}

LocationsFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  placeholder: PropTypes.string
}

export default LocationsFilter
