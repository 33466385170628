import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import ErrorMessage from '../../../../../../../../../components/Form/ErrorMessage'
import DropFileUploader from '../../../../../../../../../components/Form/DropFileUploader'
import FileUploadInput from '../../../../../../../../../components/Form/FileUploadInput'
import FilePreviewOrIsLoading from './FilePreviewOrIsLoading'

import {
  clearUploadedFile,
  fileUpload,
  updateFileUploadProgress
} from '../../../../../../../../../modules/actions/files'
import { fileUploadErrorSelector } from '../../../../../../../../../modules/selectors/files'

import useFileUpload from '../../../../../../../../../features/hooks/useFileUpload'
import { getFileDetailsError } from '../../../../../../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/validation'
import { showToasts } from '../../../../../../../../../helpers/toasts'
import { getFileFormat, getFileType } from '../../../../../../../../../features/helpers/other'

import {
  FILE_NAME,
  FILE_TYPE,
  MEDIA_DURATION,
  MEDIA_FORMAT,
  MEDIA_HEIGHT,
  MEDIA_PHYSICAL_HEIGHT,
  MEDIA_PHYSICAL_WIDTH,
  MEDIA_RATIO,
  MEDIA_SIZE,
  MEDIA_TYPE,
  MEDIA_WIDTH
} from '../../../../../../../../Facebook/AdForms/fields'

import { TOAST_TYPE } from '../../../../../../../../../constants/other'
import { FILE_IS_PROCESSING } from '../../../../../../../../../constants/ads'
import { UPLOADED_MEDIA_TYPE } from '../../../../../../../../ReusableFormFields/AdFileUpload/fields'
import { tabletDownSize } from '../../../../../../../../../styles/const/breakpoints'
import { checkPdfDimensions } from './helpers/parsePdfDimensions'

function SingleFileUploadWithValidation({
  values,
  fileValidationSchema,
  validateForm,
  error,
  onFileUploaded,
  onFileRemove,
  mediaType,
  fileName,
  fileURL,
  isPrivateUpload,
  isDraggable = true,
  accept,
  maxSize,
  buttonText
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    selectedFile,
    setSelectedFile,
    fileURLBlob,
    setFileURLBlob,
    isFileLoading,
    fileIsUploaded,
    fileUploadProgress,
    uploadedFileData,
    handleRemoveFile
  } = useFileUpload({
    onFileRemove,
    onFileUploaded
  })

  const [fileWasUploaded, setFileWasUploaded] = useState(false)

  const fileUploadError = useSelector(fileUploadErrorSelector)

  const isFileProcessing = uploadedFileData && uploadedFileData.status === FILE_IS_PROCESSING

  const showPreview = !!(fileIsUploaded || fileURL)

  const startFileUploading = file => {
    // set selected file only if there are no errors after validation
    setSelectedFile(file)
    // set file values to formik
    dispatch(fileUpload(file, { isPrivate: isPrivateUpload }))
    setFileURLBlob(URL.createObjectURL(file))
  }

  const validateFile = (newFileValues, file) => {
    if (fileValidationSchema) {
      fileValidationSchema
        .validate(
          newFileValues,
          // Went through all validation rules and collect all errors and return all
          { abortEarly: false }
        )
        .then(() => startFileUploading(file))
        .catch(({ errors }) => {
          showToasts({
            type: TOAST_TYPE.error,
            message: errors
          })
        })
    } else {
      validateForm({ ...values, ...newFileValues }).then(errors => {
        const noError = !getFileDetailsError(errors)
        if (noError && file) {
          startFileUploading(file, newFileValues)
        }
      })
    }
  }

  const handleFileChange = file => {
    const url = URL.createObjectURL(file)
    const size = file.size
    const fileName = file.name
    const type = getFileType(file)
    const format = getFileFormat(file)

    const newFileValues = {
      [FILE_NAME]: fileName,
      [MEDIA_TYPE]: type,
      [FILE_TYPE]: format,
      [UPLOADED_MEDIA_TYPE]: mediaType
    }

    if (type === 'video') {
      const video = document.createElement('video')
      video.src = url
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src)

        const newVideoValues = {
          [MEDIA_SIZE]: size,
          [MEDIA_WIDTH]: video.videoWidth,
          [MEDIA_HEIGHT]: video.videoHeight,
          [MEDIA_FORMAT]: format,
          [MEDIA_RATIO]: video.videoWidth / video.videoHeight,
          [MEDIA_DURATION]: video.duration
        }
        validateFile({ ...newFileValues, ...newVideoValues }, file)
      }
    } else if (type === 'image') {
      const image = new Image()
      image.src = url
      image.onload = function () {
        const newImageValues = {
          [MEDIA_SIZE]: size,
          [MEDIA_WIDTH]: image.width,
          [MEDIA_HEIGHT]: image.height,
          [MEDIA_FORMAT]: format,
          [MEDIA_RATIO]: image.width / image.height
        }
        validateFile({ ...newFileValues, ...newImageValues }, file)
      }
    } else if (format === 'pdf') {
      checkPdfDimensions(url)
        .then(pageSizes => {
          console.log('pageSizes', pageSizes)
          const newPdfValues = {
            [MEDIA_SIZE]: size,
            [MEDIA_FORMAT]: format,
            // for the PDF we have 2 types of width and height checking
            // it could be physical width and height in mm or width and height in pixels
            [MEDIA_WIDTH]: pageSizes.pageWidthInPixels,
            [MEDIA_PHYSICAL_WIDTH]: pageSizes.widthMm,
            [MEDIA_HEIGHT]: pageSizes.pageHeightInPixels,
            [MEDIA_PHYSICAL_HEIGHT]: pageSizes.heightMm,
            [MEDIA_RATIO]: pageSizes.pageWidthInPixels / pageSizes.pageHeightInPixels
            // [MEDIA_DPI]: pageSizes[0].dpi
          }
          validateFile({ ...newFileValues, ...newPdfValues }, file)
        })
        .catch(error => {
          showToasts({
            type: TOAST_TYPE.error,
            message: t(error)
          })
        })
    } else {
      const newCurrentFilesValues = {
        [MEDIA_SIZE]: size
      }
      validateFile({ ...newFileValues, ...newCurrentFilesValues }, file)
    }
  }

  const removeFile = useCallback(() => {
    setSelectedFile({})
    dispatch(updateFileUploadProgress({ [fileName]: 0 }))
    dispatch(clearUploadedFile(fileName))
    // callback for parent components
    onFileRemove && onFileRemove()
  }, [dispatch, onFileRemove, fileName, setSelectedFile])

  useEffect(() => {
    if (uploadedFileData) {
      setFileWasUploaded(true)
    }
  }, [uploadedFileData])

  useEffect(() => {
    // when file successfully uploaded make a callback to Parent component
    if (fileWasUploaded) {
      onFileUploaded(uploadedFileData)
      setFileWasUploaded(false)
    }
  }, [fileWasUploaded, uploadedFileData, onFileUploaded])

  useEffect(() => {
    if (fileUploadError) {
      // remove file when the uploading failed
      removeFile()
    }
  }, [removeFile, fileUploadError])

  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const isDragAndDrop = isDraggable && !isTablet

  if (showPreview || isFileLoading) {
    return (
      <FilePreviewOrIsLoading
        selectedFile={selectedFile}
        showPreview={showPreview}
        handleRemoveFile={handleRemoveFile}
        isDragAndDrop={isDragAndDrop}
        fileURLBlob={fileURLBlob}
        fileUploadProgress={fileUploadProgress}
        fileURL={fileURL}
        fileName={fileName}
        isFileLoading={isFileLoading}
      />
    )
  }

  return (
    <>
      {isDragAndDrop ? (
        <DropFileUploader onUpload={handleFileChange} />
      ) : (
        <FileUploadInput
          name="single_file_upload"
          buttonText={buttonText}
          onFileUpload={handleFileChange}
          accept={accept}
          maxSize={maxSize}
        />
      )}
      {error && !fileIsUploaded && (
        <ErrorMessage error={isFileProcessing ? 'Please wait while the video file is processing' : error} />
      )}
    </>
  )
}

SingleFileUploadWithValidation.propTypes = {
  values: PropTypes.object.isRequired,
  validateForm: PropTypes.func,
  fileValidationSchema: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onFileUploaded: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func,
  fileNameKey: PropTypes.string,
  fileURLKey: PropTypes.string,
  fileTypeKey: PropTypes.string,
  recommendedSize: PropTypes.string,
  maxSize: PropTypes.number,
  isDraggable: PropTypes.bool,
  isPrivateUpload: PropTypes.bool
}

export default SingleFileUploadWithValidation
