import { COMMENT, SELECTED_FILES } from './fields'

export const transformValuesToBE = ({ values, selectedFileId, uploadedFiles }) => {
  const ids =
    uploadedFiles.length === 1
      ? [selectedFileId]
      : Object.keys(values[SELECTED_FILES])
          .filter(key => values[SELECTED_FILES][key])
          .map(item => Number(item))

  return {
    ids,
    comment: values[COMMENT]
  }
}
