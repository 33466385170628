import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../../../../components/Icon'
import ButtonIcon from '../../../../../../features/components/Buttons/ButtonIcon'

import { useHandleAutoSave } from '../../useHandleAutoSave'

import { ReactComponent as PencilIcon } from '../../../../../../assets/icons/pencil.svg'
import { ReactComponent as CheckmarkIcon } from '../../../../../../assets/icons/checked-circle.svg'
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close-circle-2.svg'

import { BRAND, CAMPAIGN_NAME } from '../../fields'

import useStyles from './styles'

const Actions = ({ formik, defaultValues, isEditMode, allowAutoSave, setIsEditMode }) => {
  const classes = useStyles()
  const { setFieldValue, validateForm, submitForm, setFieldTouched } = formik

  // set productValues as default values to avoid case when newly product is selected and there is no data yet
  const [initialValues, setInitialValues] = useState(defaultValues)

  const handleStartEditProduct = useCallback(() => {
    // save the values before edit start, to be able to reset on these
    setInitialValues(defaultValues)
    setIsEditMode(true)
  }, [defaultValues, setIsEditMode])

  const onSuccessSave = useCallback(() => {
    setIsEditMode(false)
  }, [setIsEditMode])

  const handleSaveProduct = useHandleAutoSave({
    onSuccessSave,
    setFieldTouched,
    validateForm,
    submitForm,
    allowAutoSave
  })

  const handleResetProduct = useCallback(() => {
    setFieldValue(BRAND, initialValues[BRAND])
    setFieldValue(CAMPAIGN_NAME, initialValues[CAMPAIGN_NAME])
    setIsEditMode(false)
  }, [initialValues, setIsEditMode, setFieldValue])

  if (!isEditMode) {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonIcon className={classes.smallButton} Icon={PencilIcon} onClick={handleStartEditProduct} />
      </div>
    )
  }

  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.bigButton} onClick={handleResetProduct}>
        <Icon>
          <CloseIcon className={classes.cancelBtn} />
        </Icon>
      </div>
      <div className={classes.bigButton} onClick={handleSaveProduct}>
        <Icon>
          <CheckmarkIcon className={classes.saveBtn} />
        </Icon>
      </div>
    </div>
  )
}

Actions.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  allowAutoSave: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired
}

export default Actions
