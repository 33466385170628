import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import PropTypes from 'prop-types'

import Select from '../Select'
import PaginatedMenuList from './PaginatedMenuList'
import HighlightedValueSelect from '../HighlightedValueSelect'

import { useDidMount } from '../../hooks/useDidMount'

const initialOptions = []
const SelectPaginated = ({
  resetOptionsKey,
  selectedOptionsIds,
  defaultOptionsList = initialOptions,
  shouldFetchDefaultOptions,
  onInputChange,
  allowSelectAll,
  ...props
}) => {
  const didMount = useDidMount()
  // options need to be stored on the component level,
  // it is used to pass it to original Select for data formatting onChange
  // options which are represented in the SelectMenu are handled internally by AsyncPaginate and are passing
  // according to the search functionality
  const [allFetchedOptions, setAllFetchedOptions] = useState(defaultOptionsList)
  const customComponents = useMemo(
    () => ({
      // Covering the case of the custom MenuList according to the library documentation:
      MenuList: PaginatedMenuList
    }),
    []
  )

  const [hasOptionsCorrespondingToSearch, setHasOptionsCorrespondingToSearch] = useState({})
  const [nextValuesCorrespondingToSearch, setNextValuesCorrespondingToSearch] = useState({})
  const [searchValue, setSearchValue] = useState('')

  const showSelectAllOptionsButton = useMemo(() => {
    return hasOptionsCorrespondingToSearch[searchValue] && !nextValuesCorrespondingToSearch[searchValue]
  }, [hasOptionsCorrespondingToSearch, nextValuesCorrespondingToSearch, searchValue])

  const shouldLoadMore = useCallback((scrollHeight, clientHeight, scrollTop) => {
    // determine the position when the new part of data should be fetched
    return scrollHeight - clientHeight - scrollTop < 200
  }, [])

  const reduceOptions = useCallback(
    (previousOptions, loadedOptions, nextAdditional) => {
      // Assuming the goal is to merge previous and loaded options,
      // and possibly modify them based on 'nextAdditional' if needed.
      // This example simply concatenates previous and loaded options without any modifications.
      // Modify this logic as needed, especially if 'nextAdditional' should influence the result.

      // Create a Set from the values of loaded options for quick lookup
      const loadedValuesSet = new Set(loadedOptions.map(option => option.value))

      setHasOptionsCorrespondingToSearch(prevState => ({
        ...prevState,
        [searchValue]: !!loadedOptions.length
      }))
      setNextValuesCorrespondingToSearch(prevState => ({
        ...prevState,
        [searchValue]: nextAdditional.next
      }))

      // Filter out the options that are already loaded
      const newOptions = [...previousOptions.filter(option => !loadedValuesSet.has(option.value)), ...loadedOptions]

      // get options which are not added to allFetchedOptions and push new options to allFetchedOptions:
      const allFetchedOptionsSet = new Set(allFetchedOptions.map(option => option.value))
      const newOptionsToAll = newOptions.filter(option => !allFetchedOptionsSet.has(option.value))
      // push new options to allFetchedOptions:
      setAllFetchedOptions([...allFetchedOptions, ...newOptionsToAll])

      return newOptions
    },
    [allFetchedOptions, searchValue]
  )

  const SelectComponent = props.isHighlighted ? HighlightedValueSelect : Select

  const filterOption = useCallback(
    // Filter out selected options if selectedOptionsIds are passed
    option => !selectedOptionsIds.includes(option.value),
    [selectedOptionsIds]
  )

  const onInputChangeHandler = useCallback(
    (newValue, data) => {
      onInputChange && onInputChange(newValue, data)
      setSearchValue(newValue)
    },
    [onInputChange]
  )

  useEffect(() => {
    // Reset allFetchedOptions when resetOptionsKey is changed
    if (didMount) {
      setAllFetchedOptions(defaultOptionsList)
    }
    // avoid invoke useEffect on initial rendering for didMount
    // eslint-disable-next-line
  }, [resetOptionsKey, defaultOptionsList])

  return (
    <SelectComponent
      {...props}
      key={resetOptionsKey}
      options={allFetchedOptions}
      customComponentsReset={customComponents}
      onSelectAll={allowSelectAll && showSelectAllOptionsButton ? props.onChange : undefined}
      onInputChange={onInputChangeHandler}
    >
      <AsyncPaginate
        reduceOptions={reduceOptions}
        filterOption={selectedOptionsIds?.length ? filterOption : null}
        debounceTimeout={1000}
        loadOptions={props.loadOptions}
        key={resetOptionsKey}
        shouldLoadMore={shouldLoadMore}
        additional={{ next: '' }}
        defaultOptions={shouldFetchDefaultOptions}
      />
    </SelectComponent>
  )
}

SelectPaginated.propTypes = {
  selectedOptionsIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  loadOptions: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool,
  defaultOptionsList: PropTypes.array,
  shouldFetchDefaultOptions: PropTypes.bool,
  onInputChange: PropTypes.func,
  allowSelectAll: PropTypes.bool
}

export default SelectPaginated
