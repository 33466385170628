import React from 'react'

import ProductsTable from './ProductsTable'

import { MediaProductsDataProvider } from './MediaProductsContext'
import MediaProductsFiltersWrapper from './MediaProductsFiltersWrapper'

export const MEDIA_PRODUCTS_FILTERS_SEARCH = 'MEDIA_PRODUCTS_FILTERS_SEARCH'

const MediaProductsDataContent = () => {
  return (
    <MediaProductsDataProvider>
      <MediaProductsFiltersWrapper />
      <ProductsTable />
    </MediaProductsDataProvider>
  )
}

export default MediaProductsDataContent
