import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  dateRangeDropdown: {
    border: 'none',
    width: '100%'
  },
  range: {
    '& .rdrMonth': {
      paddingBottom: 0
    }
  },
  dateRanges: {
    justifyContent: 'center'
  }
})

export default useStyles
