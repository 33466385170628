import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../modules/selectors/forms'
import { insertIf } from '../../../../../helpers/common'

export function useProgressItems(uploadedFiles) {
  const activeStep = useSelector(activeStepSelector)

  return [
    {
      label: 'Add Comment',
      isActive: activeStep === 0,
      isSuccess: activeStep > 0
    },
    ...insertIf(uploadedFiles.length > 1, {
      label: 'Select files',
      isActive: activeStep === 1,
      isSuccess: activeStep > 1
    })
  ]
}
