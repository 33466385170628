import { showToasts } from '../../helpers/toasts'
import { isDomElementInViewPort } from '../../helpers/common'

import { TOAST_TYPE } from '../../constants/other'

export function getStepWithError(beErrors, errorFields) {
  let scrollToStep = null

  // cover case when BE returns multiple errors
  errorFields.forEach(errorFieldName => {
    // find field element with error and it's step
    let errorField
    try {
      // Uncaught DOMException: Failed to execute 'querySelector' on 'Document': 'input[name^=0]' is not a valid selector.
      // caused by error from BE
      // {
      //   0: 'Not a valid string.',
      //   1: 'Not a valid string.'
      // }
      errorField = document.querySelector(`input[name^=${errorFieldName}]`)
    } catch (e) {
      console.error(e)
    }
    if (errorField) {
      // step saves to data-step attribute in FormDrawerWrapper component
      const stepField = errorField && errorField.closest('div[data-step]')
      let step = stepField && stepField.getAttribute('data-step')
      step = step && parseInt(step)

      if (step !== null && step !== undefined) {
        // skip null/undefined values
        if (scrollToStep === null || step <= scrollToStep) {
          // set the lowest step to push user on the beginning
          scrollToStep = step
        }
      }
    } else {
      // show toasts if error field wasn't found in DOM
      showToasts({
        type: TOAST_TYPE.error,
        message: `${errorFieldName} - ${beErrors[errorFieldName]}`
      })
    }
  })

  return scrollToStep
}

function getTopElementInDom(elements) {
  let topElementInDom = elements[0]
  let topCoordinate = elements[0].getBoundingClientRect().top

  elements.forEach(currElem => {
    const currentElemTopCoordinate = currElem.getBoundingClientRect().top

    if (currentElemTopCoordinate < topCoordinate) {
      topElementInDom = currElem
      topCoordinate = currentElemTopCoordinate
    }
  })

  return topElementInDom
}

export function handleScrollToFieldWithError(formName, activeStep) {
  // querySelector in is covered with try/catch because it throws an error in some cases e.g.
  // Uncaught DOMException: Failed to execute 'querySelector' on 'Document': 'label[for=carousel_items[0].click_through_link]' is not a valid selector.
  let form
  try {
    form = document.querySelector(`form[data-form-name=${formName}]`)
  } catch (e) {}

  if (!form) {
    return
  }

  const steps = Array.from(form.querySelectorAll('div[data-step]'))
  const currentStep = steps.find(step => Number(step.dataset['step']) === activeStep)

  // In StepForm we need to make sure we check errors only in the current step, to not scroll to the wrong step
  const errorsContainer = currentStep || form

  const errorsMessages = Array.from(errorsContainer.querySelectorAll('.error'))

  if (!errorsMessages.length) {
    return
  }

  const topError = getTopElementInDom(errorsMessages)

  if (!isDomElementInViewPort(topError)) {
    const section = topError.closest('section')

    const field = topError.closest('.field')
    const fieldName = field?.querySelector('input')?.getAttribute('name')

    let fieldLabel
    try {
      fieldLabel = document.querySelector(`label[for=${fieldName}]`)
    } catch (e) {}

    const startEndDateRange = topError.closest('.startEndDateRange')

    const radioBlock = topError.closest('.radioBlock')

    const scrollParams = { behavior: 'smooth', block: 'start' }

    if (section) {
      section.scrollIntoView(scrollParams)
    } else if (radioBlock) {
      radioBlock.scrollIntoView(scrollParams)
    } else if (fieldLabel) {
      fieldLabel.scrollIntoView(scrollParams)
    } else if (field) {
      field.scrollIntoView(scrollParams)
    } else if (startEndDateRange) {
      startEndDateRange.scrollIntoView(scrollParams)
    } else {
      topError.scrollIntoView(scrollParams)
    }
  }
}
