import { createStyles } from '../../../../../../../../styles/helpers'
import { transitionShort } from '../../../../../../../../styles/const/common'

export default createStyles({
  buttonsContainer: {
    width: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'center' // don't stretch the cell
  },
  button: {
    // increase size on hover with animation:
    transition: transitionShort,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  smallButton: {
    extend: 'button',
    '& svg': {
      width: '14px',
      height: '14px'
    }
  },
  bigButton: {
    extend: 'button',
    cursor: 'pointer',
    padding: '5px 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '20px',
      height: '20px'
    }
  },
  saveBtn: {},
  cancelBtn: {}
})
