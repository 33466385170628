import React from 'react'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import useSearch from '../../../../../hooks/useSearch'
import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { clearProducts, getProducts } from '../../../../../modules/actions/mediaOrdersProducts'
import { productsNextSelector } from '../../../../../modules/selectors/mediaOrdersProducts'

import { SELECTED_CATEGORIES } from '../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import { SELECTED_SUB_CATEGORIES } from '../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { SELECTED_SUB_SUB_CATEGORIES } from '../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import { SELECTED_LOCATIONS } from '../../../../../features/components/DropdownFilters/components/LocationsFilter'
import { SELECTED_TAG } from '../../../../../features/components/Filters/ProductTagsFilter'
import { MEDIA_PRODUCTS_FILTERS_SEARCH } from '../index'

export const MediaProductsContext = React.createContext()

export function MediaProductsDataProvider({ children }) {
  const controllerId = useSelector(selectedControllerIdSelector)
  const productsNext = useSelector(productsNextSelector)

  const searchTerm = useSearch(MEDIA_PRODUCTS_FILTERS_SEARCH)

  const [filtersState, setFiltersState] = useState({
    [SELECTED_TAG]: '',
    [SELECTED_CATEGORIES]: [],
    [SELECTED_SUB_CATEGORIES]: [],
    [SELECTED_SUB_SUB_CATEGORIES]: [],
    [SELECTED_LOCATIONS]: []
  })

  const {
    [SELECTED_TAG]: selectedTag,
    [SELECTED_CATEGORIES]: selectedCategories,
    [SELECTED_SUB_CATEGORIES]: selectedSubCategories,
    [SELECTED_SUB_SUB_CATEGORIES]: selectedSubSubCategories,
    [SELECTED_LOCATIONS]: selectedLocations
  } = filtersState

  const requestParams = useMemo(() => {
    return {
      controller: controllerId,
      ...(selectedTag && { tags: selectedTag }),
      ...(searchTerm && { search: searchTerm }),
      ...(selectedCategories.length && { media_category: selectedCategories.map(option => option.value).join(',') }),
      ...(selectedSubCategories.length && {
        media_sub_category: selectedSubCategories.map(option => option.value).join(',')
      }),
      ...(selectedSubSubCategories.length && {
        media_sub_sub_category: selectedSubSubCategories.map(option => option.value).join(',')
      }),
      ...(selectedLocations.length && { location: selectedLocations.map(option => option.value).join(',') })
    }
  }, [
    controllerId,
    searchTerm,
    selectedCategories,
    selectedSubCategories,
    selectedSubSubCategories,
    selectedLocations,
    selectedTag
  ])

  const exportAsCsvFilters = useMemo(() => {
    return {
      ...(searchTerm && { search: searchTerm }),
      ...(selectedTag && { tag: selectedTag }),
      ...(selectedLocations.length && { location: selectedLocations.map(option => option.value) }),
      ...(selectedCategories.length && { media_category: selectedCategories.map(option => option.value) }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories.map(option => option.value) }),
      ...(selectedSubSubCategories.length && {
        media_sub_sub_category: selectedSubSubCategories.map(option => option.value)
      })
    }
  }, [searchTerm, selectedTag, selectedCategories, selectedSubCategories, selectedSubSubCategories, selectedLocations])

  const loadMoreProductsHandler = useLoadPaginatedList({
    params: requestParams,
    action: getProducts,
    clearAction: clearProducts,
    next: productsNext
  })

  const mediaProductsContextProps = useMemo(() => {
    return {
      filtersState,
      setFiltersState,
      loadMoreProductsHandler,
      exportAsCsvFilters
    }
  }, [filtersState, setFiltersState, loadMoreProductsHandler, exportAsCsvFilters])

  return <MediaProductsContext.Provider value={mediaProductsContextProps}>{children}</MediaProductsContext.Provider>
}
