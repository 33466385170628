import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'

import { openForm } from '../../../../../../modules/actions/forms'

import useStyles from './styles'

import { MEDIA_PRODUCT_GROUP_CREATE, PRODUCT_CREATE } from '../../../../../../constants/forms'

const CreateProductBtn = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const openCreateProductFormHandler = useCallback(() => dispatch(openForm({ formName: PRODUCT_CREATE })), [dispatch])
  const openCreateProductGroupFormHandler = useCallback(
    () => dispatch(openForm({ formName: MEDIA_PRODUCT_GROUP_CREATE })),
    [dispatch]
  )

  return (
    <>
      <Button onClick={openCreateProductFormHandler} solid>
        {t('Create product')}
      </Button>
      <Button className={classes.createGroupBtn} onClick={openCreateProductGroupFormHandler} solid>
        {t('Create group')}
      </Button>
    </>
  )
}

export default CreateProductBtn
