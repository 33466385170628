import React, { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import UploadedFilesCell from './UploadedFilesCell'
import StatusCell from './StatusCell'
import ProductAndPeriodCell from './ProductAndPeriodCell'
import LocationCell from './LocationCell'
import BookingCell from './BookingCell'
import ActionsCell from './ActionsCell'
import AccountBrandCell from '../../../../../../components/Table/ReusableCells/AccountBrandCell'

import { getMediaOrderOrMediaOrderGroupSelfAccountId } from './helpers'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

export const tableColumnsSize = {
  id: 50,
  booking: 70,
  account: 200,
  asset_id: 150,
  location: 200,
  quantity: 60,
  status: 155,
  actions: 40
}

const getUploadedFilesColumnSize = maxUploadedFilesAmount => {
  switch (maxUploadedFilesAmount) {
    case 1:
    case 0:
      return 80
    case 2:
      return 100
    case 3:
    default:
      return 150
  }
}

export const useInstallationColumns = maxUploadedFilesAmount => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const uploadedFilesColumnSize = useMemo(() => {
    return getUploadedFilesColumnSize(maxUploadedFilesAmount)
  }, [maxUploadedFilesAmount])

  return useMemo(() => {
    return [
      {
        header: 'Booking',
        Cell: ({ booking, media_order: mediaOrderId, account_id: accountId, sequential_list: sequentialList }) => {
          const selfAccountId = getMediaOrderOrMediaOrderGroupSelfAccountId(accountId, sequentialList)

          return <BookingCell booking={booking} mediaOrderId={mediaOrderId} accountId={selfAccountId} />
        },

        style: { maxWidth: tableColumnsSize.booking }
      },
      {
        header: 'Account',
        Cell: ({
          account,
          brand,
          campaign_name: campaignName,
          sequential_list: sequentialList,
          sequential_ids: sequentialIds
        }) => {
          return (
            <AccountBrandCell
              account={account}
              brand={brand}
              campaignName={campaignName}
              sequentialList={sequentialList}
              sequentialIds={sequentialIds}
              isSmall={true}
            />
          )
        },
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.account
        }
      },
      {
        header: 'Asset ID',
        fieldKey: 'asset_id',
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.asset_id }
      },
      {
        header: 'Product and Period',
        Cell: ({ product, date_start, date_end, sequential_list }) => (
          <ProductAndPeriodCell
            isGroup={!!sequential_list}
            product={product}
            date_start={date_start}
            date_end={date_end}
          />
        ),
        showOnMobile: false,
        style: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexGrow: 1
        }
      },
      {
        header: 'Location',
        Cell: ({ location, sub_location }) => <LocationCell location={location} sub_location={sub_location} />,
        style: {
          flexDirection: 'column',
          maxWidth: tableColumnsSize.location,
          whiteSpace: 'normal'
        }
      },
      {
        header: 'Qty',
        fieldKey: 'quantity',
        style: { maxWidth: tableColumnsSize.quantity },
        showOnMobile: false
      },
      {
        header: 'Files',
        Cell: ({
          uploaded_files,
          sequential_ids,
          sequential_list: sequentialList,
          id,
          media_order: mediaOrderId,
          account_id: accountId,
          additional_recipients: additionalRecipients
        }) => {
          const firstSequentialId = sequential_ids?.[0]
          const bookedMediaId = firstSequentialId || id
          const selfAccountId = getMediaOrderOrMediaOrderGroupSelfAccountId(accountId, sequentialList)
          return (
            <UploadedFilesCell
              bookedMediaId={bookedMediaId}
              uploaded_files={uploaded_files}
              sequentialList={sequentialList}
              sequentialIds={sequential_ids}
              id={id}
              mediaOrderId={mediaOrderId}
              accountId={selfAccountId}
              additionalRecipients={additionalRecipients}
            />
          )
        },
        style: {
          maxWidth: uploadedFilesColumnSize
        },
        showOnMobile: false
      },
      {
        header: 'Status',
        Cell: ({ status, sequential_ids: sequentialIds, id }) => {
          return <StatusCell status={status} sequentialIds={sequentialIds} id={id} />
        },
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        Cell: ({ media_order: mediaOrderId, id, account_id: accountId }) => (
          <ActionsCell id={id} mediaOrderId={mediaOrderId} accountId={accountId} />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ]
  }, [uploadedFilesColumnSize, isMobile])
}
