import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FiltersSearch from '../../../../../features/components/Filters/FiltersSearch'
import CategoriesFilter, {
  SELECTED_CATEGORIES
} from '../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import SubCategoriesFilter, {
  SELECTED_SUB_CATEGORIES
} from '../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import SubSubCategoriesFilter, {
  SELECTED_SUB_SUB_CATEGORIES
} from '../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import LocationsFilter, {
  SELECTED_LOCATIONS
} from '../../../../../features/components/DropdownFilters/components/LocationsFilter'
import DropdownFilters from '../../../../../features/components/DropdownFilters'
import ProductTagsFilter, { SELECTED_TAG } from '../../../../../features/components/Filters/ProductTagsFilter'
import ExportProductsAsCsv from './ExportProductsAsCsv'

import { getAllSubSubCategories } from '../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter/helpers'

import { clearGetMediaSubCategories, getMediaSubCategories } from '../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { mediaSubCategoriesSelector } from '../../../../../modules/selectors/mediaOrdersProducts'

import { MediaProductsContext } from '../MediaProductsContext'
import { MEDIA_PRODUCTS_FILTERS_SEARCH } from '../index'

import useStyles from './styles'

const MediaProductsFiltersWrapper = () => {
  const dispatch = useDispatch()
  const controllerId = useSelector(selectedControllerIdSelector)

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const allSubSubCategories = useMemo(() => getAllSubSubCategories(mediaSubCategories), [mediaSubCategories])

  const classes = useStyles()

  useEffect(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])
  useEffect(() => {
    dispatch(clearGetMediaSubCategories())
  }, [dispatch])

  const filters = useMemo(() => {
    return [
      {
        component: <ProductTagsFilter Context={MediaProductsContext} placeholder="Tags" />,
        title: 'Tags',
        filterName: SELECTED_TAG,
        show: true
      },
      {
        component: <CategoriesFilter Context={MediaProductsContext} />,
        title: 'Categories',
        filterName: SELECTED_CATEGORIES,
        show: true
      },
      {
        component: <SubCategoriesFilter Context={MediaProductsContext} />,
        title: 'Asset Type',
        filterName: SELECTED_SUB_CATEGORIES,
        show: !!mediaSubCategories.length
      },
      {
        component: <SubSubCategoriesFilter Context={MediaProductsContext} />,
        title: 'Asset Subtype',
        filterName: SELECTED_SUB_SUB_CATEGORIES,
        show: !!allSubSubCategories.length
      },
      {
        component: <LocationsFilter Context={MediaProductsContext} placeholder="Location" />,
        title: 'Location',
        filterName: SELECTED_LOCATIONS,
        show: true
      }
    ]
  }, [allSubSubCategories, mediaSubCategories])

  return (
    <div className={classes.mediaProductsFiltersWrapper}>
      <ExportProductsAsCsv Context={MediaProductsContext} />
      <DropdownFilters filters={filters} Context={MediaProductsContext} />
      <FiltersSearch searchId={MEDIA_PRODUCTS_FILTERS_SEARCH} />
    </div>
  )
}

export default MediaProductsFiltersWrapper
