import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { CalendarInventoryDataProvider, CalendarInventoryFiltersContext } from './CalendarInventoryFiltersContext'

import LocationsFilter, {
  SELECTED_LOCATIONS
} from '../../../../../../features/components/DropdownFilters/components/LocationsFilter'
import DropdownFilters from '../../../../../../features/components/DropdownFilters'
import CategoriesFilter, {
  SELECTED_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import SubCategoriesFilter, {
  SELECTED_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import SubSubCategoriesFilter, {
  SELECTED_SUB_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import ProductsFilter, {
  SELECTED_PRODUCTS
} from '../../../../../../features/components/DropdownFilters/components/ProductsFilter'

import { getAllSubSubCategories } from '../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter/helpers'

import { mediaSubCategoriesSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import useStyles from './styles'

const CalendarInventoryFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const controllerId = useSelector(selectedControllerIdSelector)

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const allSubSubCategories = useMemo(() => getAllSubSubCategories(mediaSubCategories), [mediaSubCategories])

  const filters = useMemo(() => {
    return [
      {
        component: <LocationsFilter Context={CalendarInventoryFiltersContext} placeholder="Locations" />,
        title: 'Locations',
        filterName: SELECTED_LOCATIONS,
        show: true
      },
      {
        component: <CategoriesFilter Context={CalendarInventoryFiltersContext} />,
        title: 'Categories',
        filterName: SELECTED_CATEGORIES,
        show: true
      },
      {
        component: <SubCategoriesFilter Context={CalendarInventoryFiltersContext} />,
        title: 'Asset Type',
        filterName: SELECTED_SUB_CATEGORIES,
        show: !!mediaSubCategories.length
      },
      {
        component: <SubSubCategoriesFilter Context={CalendarInventoryFiltersContext} />,
        title: 'Asset Subtype',
        filterName: SELECTED_SUB_SUB_CATEGORIES,
        show: !!allSubSubCategories.length
      },
      {
        component: <ProductsFilter Context={CalendarInventoryFiltersContext} />,
        title: 'Products',
        filterName: SELECTED_PRODUCTS,
        show: true
      }
    ]
  }, [mediaSubCategories, allSubSubCategories])

  useEffect(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])
  useEffect(() => {
    dispatch(clearGetMediaSubCategories())
  }, [dispatch])

  return (
    <CalendarInventoryDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <div className={classes.rightFiltersRow}>
          <DropdownFilters filters={filters} Context={CalendarInventoryFiltersContext} />

          {/*this is used for portalled filters - don't remove until check id usage */}
          <div id="booked_media_calendar_view_portal" />
          {/*this is used for pdf generation */}
          <div id="pdf_generation_portal" />
        </div>
      </div>
    </CalendarInventoryDataProvider>
  )
}

CalendarInventoryFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default CalendarInventoryFilters
