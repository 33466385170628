import { createStyles } from '../../../../../styles/helpers'

import { greyOutline, red } from '../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  saveDetailsContainer: {
    fontSize: '12px',
    color: greyOutline,
    listStyle: 'none',
    display: 'flex',
    gap: '16px',
    padding: 0
  },
  detail: {
    display: 'flex',
    alignItems: 'center'
  },
  saveIcon: {
    fontSize: '14px',
    marginRight: '4px'
  },
  saveIconLoading: {
    // flashing color changing animation, write code:
    animation: '$flash 1s infinite'
  },
  '@keyframes flash': {
    '0%': {
      color: greyOutline
    },
    '50%': {
      color: theme.brandPrimary
    },
    '100%': {
      color: theme.brandPrimaryHover
    }
  },
  saveError: {
    color: red
  }
}))

export default useStyles
