import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDER_PRODUCT_LOCATION/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_MEDIA_PRODUCT_LOCATION = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATION')
export const GET_MEDIA_PRODUCT_LOCATION_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATION_SUCCESS')
export const GET_MEDIA_PRODUCT_LOCATION_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATION_FAILURE')
export const CLEAR_GET_MEDIA_PRODUCT_LOCATION = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PRODUCT_LOCATION')

export const GET_MEDIA_PRODUCT_LOCATIONS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATIONS')
export const GET_MEDIA_PRODUCT_LOCATIONS_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATIONS_SUCCESS')
export const GET_MEDIA_PRODUCT_LOCATIONS_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATIONS_FAILURE')
export const CLEAR_GET_MEDIA_PRODUCT_LOCATIONS = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PRODUCT_LOCATIONS')

export const CREATE_MEDIA_PRODUCT_LOCATION = concat(MODULE_NAME, 'CREATE_MEDIA_PRODUCT_LOCATION')
export const CREATE_MEDIA_PRODUCT_LOCATION_SUCCESS = concat(MODULE_NAME, 'CREATE_MEDIA_PRODUCT_LOCATION_SUCCESS')
export const CREATE_MEDIA_PRODUCT_LOCATION_FAILURE = concat(MODULE_NAME, 'CREATE_MEDIA_PRODUCT_LOCATION_FAILURE')
export const CLEAR_CREATE_MEDIA_PRODUCT_LOCATION = concat(MODULE_NAME, 'CLEAR_CREATE_MEDIA_PRODUCT_LOCATION')

export const UPDATE_MEDIA_PRODUCT_LOCATION = concat(MODULE_NAME, 'UPDATE_MEDIA_PRODUCT_LOCATION')
export const UPDATE_MEDIA_PRODUCT_LOCATION_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_PRODUCT_LOCATION_SUCCESS')
export const UPDATE_MEDIA_PRODUCT_LOCATION_FAILURE = concat(MODULE_NAME, 'UPDATE_MEDIA_PRODUCT_LOCATION_FAILURE')
export const CLEAR_UPDATE_MEDIA_PRODUCT_LOCATION = concat(MODULE_NAME, 'CLEAR_UPDATE_MEDIA_PRODUCT_LOCATION')

export const GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS')
export const GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_SUCCESS = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_SUCCESS'
)
export const GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_FAILURE = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_FAILURE'
)
export const CLEAR_GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS = concat(
  MODULE_NAME,
  'CLEAR_GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS'
)

export const GET_BOOKED_MEDIA_ATTACHED_LOCATIONS = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_ATTACHED_LOCATIONS')
export const GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_SUCCESS = concat(
  MODULE_NAME,
  'GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_SUCCESS'
)
export const GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_FAILURE = concat(
  MODULE_NAME,
  'GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_FAILURE'
)
export const CLEAR_GET_BOOKED_MEDIA_ATTACHED_LOCATIONS = concat(
  MODULE_NAME,
  'CLEAR_GET_BOOKED_MEDIA_ATTACHED_LOCATIONS'
)

export const GET_PRODUCT_STORES = concat(MODULE_NAME, 'GET_PRODUCT_STORES')
export const GET_PRODUCT_STORES_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCT_STORES_SUCCESS')
export const GET_PRODUCT_STORES_FAILURE = concat(MODULE_NAME, 'GET_PRODUCT_STORES_FAILURE')
export const CLEAR_GET_PRODUCT_STORES = concat(MODULE_NAME, 'CLEAR_GET_PRODUCT_STORES')

export const GET_MEDIA_PRODUCT_LOCATION_CATEGORIES = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATION_CATEGORIES')
export const GET_MEDIA_PRODUCT_LOCATION_CATEGORIES_SUCCESS = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_LOCATION_CATEGORIES_SUCCESS'
)
export const GET_MEDIA_PRODUCT_LOCATION_CATEGORIES_FAILURE = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_LOCATION_CATEGORIES_FAILURE'
)
export const CLEAR_GET_MEDIA_PRODUCT_LOCATION_CATEGORIES = concat(
  MODULE_NAME,
  'CLEAR_GET_MEDIA_PRODUCT_LOCATION_CATEGORIES'
)

export const GET_MEDIA_PRODUCT_LOCATION_REGIONS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_LOCATION_REGIONS')
export const GET_MEDIA_PRODUCT_LOCATION_REGIONS_SUCCESS = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_LOCATION_REGIONS_SUCCESS'
)
export const GET_MEDIA_PRODUCT_LOCATION_REGIONS_FAILURE = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_LOCATION_REGIONS_FAILURE'
)
export const CLEAR_GET_MEDIA_PRODUCT_LOCATION_REGIONS = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PRODUCT_LOCATION_REGIONS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get media product location
export function getMediaProductLocation(params) {
  return { type: GET_MEDIA_PRODUCT_LOCATION, params }
}

export function getMediaProductLocationSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_SUCCESS, data }
}

export function getMediaProductLocationFailure(error) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_FAILURE, error }
}

export function clearGetMediaProductLocation() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_LOCATION }
}

// Get media product locations
export function getMediaProductLocations(params) {
  return { type: GET_MEDIA_PRODUCT_LOCATIONS, params }
}

export function getMediaProductLocationsSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_LOCATIONS_SUCCESS, data }
}

export function getMediaProductLocationsFailure(error) {
  return { type: GET_MEDIA_PRODUCT_LOCATIONS_FAILURE, error }
}

export function clearGetMediaProductLocations() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_LOCATIONS }
}

// Create media product location
export function createMediaProductLocation(data) {
  return { type: CREATE_MEDIA_PRODUCT_LOCATION, data }
}

export function createMediaProductLocationSuccess(data) {
  return { type: CREATE_MEDIA_PRODUCT_LOCATION_SUCCESS, data }
}

export function createMediaProductLocationFailure(error) {
  return { type: CREATE_MEDIA_PRODUCT_LOCATION_FAILURE, error }
}

export function clearCreateMediaProductLocation() {
  return { type: CLEAR_CREATE_MEDIA_PRODUCT_LOCATION }
}

// Update media order product location
export function updateMediaProductLocation(data, id) {
  return { type: UPDATE_MEDIA_PRODUCT_LOCATION, data, id }
}

export function updateMediaProductLocationSuccess(data) {
  return { type: UPDATE_MEDIA_PRODUCT_LOCATION_SUCCESS, data }
}

export function updateMediaProductLocationFailure(error) {
  return { type: UPDATE_MEDIA_PRODUCT_LOCATION_FAILURE, error }
}

export function clearUpdateMediaProductLocation() {
  return { type: CLEAR_UPDATE_MEDIA_PRODUCT_LOCATION }
}

// Get media product attached locations
export function getMediaProductAttachedLocations(params) {
  return { type: GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS, params }
}

export function getMediaProductAttachedLocationsSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_SUCCESS, data }
}

export function getMediaProductAttachedLocationsFailure(error) {
  return { type: GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_FAILURE, error }
}

export function clearGetMediaProductAttachedLocations() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS }
}

// Get booked media attached locations
export function getBookedMediaAttachedLocations(params) {
  return { type: GET_BOOKED_MEDIA_ATTACHED_LOCATIONS, params }
}

export function getBookedMediaAttachedLocationsSuccess(data) {
  return { type: GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_SUCCESS, data }
}

export function getBookedMediaAttachedLocationsFailure(error) {
  return { type: GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_FAILURE, error }
}

export function clearGetBookedMediaAttachedLocations() {
  return { type: CLEAR_GET_BOOKED_MEDIA_ATTACHED_LOCATIONS }
}

// Get product stores
export function getProductStores(params) {
  return { type: GET_PRODUCT_STORES, params }
}

export function getProductStoresSuccess(data) {
  return { type: GET_PRODUCT_STORES_SUCCESS, data }
}

export function getProductStoresFailure(error) {
  return { type: GET_PRODUCT_STORES_FAILURE, error }
}

export function clearGetProductStores() {
  return { type: CLEAR_GET_PRODUCT_STORES }
}

// Get media product location categories
export function getMediaProductLocationCategories(params) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_CATEGORIES, params }
}

export function getMediaProductLocationCategoriesSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_CATEGORIES_SUCCESS, data }
}

export function getMediaProductLocationCategoriesFailure(error) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_CATEGORIES_FAILURE, error }
}

export function clearGetMediaProductLocationCategories() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_LOCATION_CATEGORIES }
}

// Get media product location regions
export function getMediaProductLocationRegions(params) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_REGIONS, params }
}

export function getMediaProductLocationRegionsSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_REGIONS_SUCCESS, data }
}

export function getMediaProductLocationRegionsFailure(error) {
  return { type: GET_MEDIA_PRODUCT_LOCATION_REGIONS_FAILURE, error }
}

export function clearGetMediaProductLocationRegions() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_LOCATION_REGIONS }
}
