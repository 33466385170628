import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'
import { getLoadOptionsHandler } from '../../../../helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { getProductsService } from '../../../../../modules/services/mediaOrdersProducts'

export const SELECTED_PRODUCTS = 'selectedProducts'

export const ProductsFilter = ({ Context }) => {
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_PRODUCTS]: selectedProducts } = filtersState

  const setSelectedProducts = useCallback(
    newProducts => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_PRODUCTS]: newProducts
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const formatProducts = useCallback(products => {
    return formatOptionsList({
      list: products,
      valueName: 'id',
      labelName: 'name'
    })
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getProductsService,
      params: {
        controller: selfAccountControllerId,
        ordering: 'name',
        fields: ['id', 'name'].join(','),
        limit: 100
      },
      formatOptions: formatProducts
    })
  }, [formatProducts, selfAccountControllerId])

  const selectProductHandler = useCallback(
    data => {
      setSelectedProducts(data)
    },
    [setSelectedProducts]
  )

  const setProductsHandler = useCallback(
    (name, data) => {
      selectProductHandler(data)
    },
    [selectProductHandler]
  )

  return (
    <MultiSelectBox
      name="Product"
      loadOptions={loadOptions}
      value={selectedProducts}
      onChange={selectProductHandler}
      placeholder="Product"
      setFieldValue={setProductsHandler}
      portaled
      allowSelectAll={true}
    />
  )
}

ProductsFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default ProductsFilter
