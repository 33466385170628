import {
  FILES_REQUIREMENTS_HEIGHT,
  FILES_REQUIREMENTS_MAX_FILE_SIZE,
  FILES_REQUIREMENTS_MAX_HEIGHT,
  FILES_REQUIREMENTS_MAX_WIDTH,
  FILES_REQUIREMENTS_MIN_FILE_SIZE,
  FILES_REQUIREMENTS_MIN_HEIGHT,
  FILES_REQUIREMENTS_MIN_WIDTH,
  FILES_REQUIREMENTS_RATIO_FROM,
  FILES_REQUIREMENTS_RATIO_TO,
  FILES_REQUIREMENTS_RESOLUTION_FROM,
  FILES_REQUIREMENTS_RESOLUTION_TO,
  FILES_REQUIREMENTS_WIDTH
} from '../../../../../MediaProductCreate/MediaProductCreateForm/fields'

export const PIXEL_SYMBOL = 'px'
export const specificationsList = [
  {
    value: FILES_REQUIREMENTS_MIN_FILE_SIZE,
    label: 'Min File Size',
    symbol: 'Mb'
  },
  {
    value: FILES_REQUIREMENTS_MAX_FILE_SIZE,
    label: 'Max File Size',
    symbol: 'Mb'
  },
  {
    value: FILES_REQUIREMENTS_WIDTH,
    label: 'Width',
    symbol: PIXEL_SYMBOL
  },
  {
    value: FILES_REQUIREMENTS_HEIGHT,
    label: 'Height',
    symbol: PIXEL_SYMBOL
  },
  {
    value: FILES_REQUIREMENTS_MIN_WIDTH,
    label: 'Min width',
    symbol: PIXEL_SYMBOL
  },
  {
    value: FILES_REQUIREMENTS_MIN_HEIGHT,
    label: 'Min height',
    symbol: PIXEL_SYMBOL
  },
  {
    value: FILES_REQUIREMENTS_MAX_WIDTH,
    label: 'Max width',
    symbol: PIXEL_SYMBOL
  },
  {
    value: FILES_REQUIREMENTS_MAX_HEIGHT,
    label: 'Max height',
    symbol: PIXEL_SYMBOL
  },
  {
    value: FILES_REQUIREMENTS_RATIO_FROM,
    label: 'Ratio from'
  },
  {
    value: FILES_REQUIREMENTS_RATIO_TO,
    label: 'Ratio to'
  },
  {
    value: FILES_REQUIREMENTS_RESOLUTION_FROM,
    label: 'Min Resolution',
    symbol: 'dpi'
  },
  {
    value: FILES_REQUIREMENTS_RESOLUTION_TO,
    label: 'Max Resolution ',
    symbol: 'dpi'
  }
]
