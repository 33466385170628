import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import InfoModal from '../../../../../../features/components/Modals/InfoModalNew'
import Button from '../../../../../../components/Button'

import { BUYER_DISAPPROVED, DISAPPROVED } from '../../../../../../features/components/UploadedFilesField/helpers'
import { areUrlsEqual } from './helpers'

import { bulkUpdateBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'
import { bookedMediaSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'

import { DISAPPROVED_REASON } from '../../../../../../features/components/Modals/AskReasonModal/fields'

import useStyles from './styles'

const FileInstancesModal = ({
  isOpen,
  onClose,
  fileInstancesModalData = {},
  setShowFileInstancesModal,
  setFileInstancesModalData
}) => {
  const dispatch = useDispatch()

  const classes = useStyles()

  const { t } = useTranslation()

  const { status, uploadedFile, [DISAPPROVED_REASON]: disapprovedReason } = fileInstancesModalData

  const bookedMedia = useSelector(bookedMediaSelector)

  const bulkUpdateStatusHandler = useCallback(
    ids => {
      dispatch(
        bulkUpdateBookingMediaFile({
          ids,
          approval_status: status,
          ...(status === DISAPPROVED && { disapproved_reason: disapprovedReason }),
          ...(status === BUYER_DISAPPROVED && { buyer_disapproved_reason: disapprovedReason })
        })
      )

      setShowFileInstancesModal(false)
      setFileInstancesModalData({
        status: '',
        uploadedFile: {},
        [DISAPPROVED_REASON]: ''
      })
    },
    [dispatch, status, setShowFileInstancesModal, setFileInstancesModalData, disapprovedReason]
  )

  const updateOnlyThisAssetHandler = useCallback(() => {
    const parentBookedMedia = bookedMedia.find(media => media.id === uploadedFile.booked_media)
    const parentBookedMediaUploadedFiles = parentBookedMedia.uploaded_files
    const parentBookedMediaUploadedFilesWithSameFileUrl = parentBookedMediaUploadedFiles.filter(file => {
      return areUrlsEqual(file.file_url, uploadedFile.file_url)
    })
    const fileIds = parentBookedMediaUploadedFilesWithSameFileUrl.map(file => file.id)

    bulkUpdateStatusHandler(fileIds)
  }, [bookedMedia, uploadedFile, bulkUpdateStatusHandler])

  const updateAllAssetsHandler = useCallback(() => {
    const allUploadedFilesWithSameFileUrl = bookedMedia.reduce((acc, media) => {
      const uploadedFilesWithSameFileUrl = media.uploaded_files.filter(file => {
        return areUrlsEqual(file.file_url, uploadedFile.file_url)
      })

      return [...acc, ...uploadedFilesWithSameFileUrl]
    }, [])
    const fileIds = allUploadedFilesWithSameFileUrl.map(file => file.id)

    bulkUpdateStatusHandler(fileIds)
  }, [bookedMedia, uploadedFile, bulkUpdateStatusHandler])

  return (
    <InfoModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Apply to all instances of this file"
      ModalMessage={<p>{t('Would you like to apply this status to all instances of this file?')}</p>}
      headerClassName={classes.header}
    >
      <div className={classes.buttonsWrapper}>
        <Button onClick={updateOnlyThisAssetHandler} className={classes.onlyThisAssetButton}>
          {t('Only this asset')}
        </Button>
        <Button onClick={updateAllAssetsHandler} solid={true} className={classes.applyToAllButton}>
          {t('Apply to all')}
        </Button>
      </div>
    </InfoModal>
  )
}

export default FileInstancesModal
