import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import SelectPaginated from '../../../../../../../components/SelectPaginated'

import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'

import useLoadProductOptions from './useLoadProductOptions'
import useFetchProductData from './useFetchProductData'

import { productIsLoadingSelector } from '../../../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'

import useStyles from './styles'

const ProductSelection = ({
  productIndex,
  onAddProduct,
  onAddProductError,
  onSelectProduct,
  selectedProductsIds,
  selectedSubCategory
}) => {
  const classes = useStyles()

  const [selectedProduct, setSelectedProduct] = useState(null)
  const selectedProductIsLoading = useSelector(productIsLoadingSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const { currency } = useContext(MediaOrderFormContext)
  const currencyCode = currency?.code
  const loadOptions = useLoadProductOptions({
    selectedSubCategory,
    currencyCode
  })

  const handleSuccessFetch = useCallback(
    data => {
      onAddProduct(data, productIndex)
    },
    [onAddProduct, productIndex]
  )

  const handleErrorFetch = useCallback(() => {
    onAddProductError(productIndex)
    setSelectedProduct(null)
  }, [onAddProductError, productIndex])

  const productParams = useMemo(() => {
    return {
      id: selectedProduct?.value,
      controller: selfAccountControllerId
    }
  }, [selectedProduct?.value, selfAccountControllerId])

  useFetchProductData({
    onSuccess: handleSuccessFetch,
    onError: handleErrorFetch,
    params: productParams,
    triggerFetch: !!selectedProduct
  })

  const onChangeHandler = useCallback(
    selectedProduct => {
      setSelectedProduct(selectedProduct)
      onSelectProduct(selectedProduct.name, productIndex)
    },
    [onSelectProduct, productIndex]
  )

  const filtersKey = useMemo(() => `${selectedSubCategory}-${uuidv4()}`, [selectedSubCategory])

  if (selectedProduct) {
    return selectedProduct.name
  }

  return (
    <SelectPaginated
      className={classes.productSelect}
      onChange={onChangeHandler}
      loadOptions={loadOptions}
      resetOptionsKey={filtersKey} // reset fetched data on category change
      placeholder="Select media product"
      autoComplete="off"
      portaled={true}
      selectedOptionsIds={selectedProductsIds}
      isDisabled={selectedProductIsLoading}
    />
  )
}

ProductSelection.propTypes = {
  onAddProduct: PropTypes.func.isRequired,
  onSelectProduct: PropTypes.func.isRequired,
  onAddProductError: PropTypes.func.isRequired,
  selectedProductsIds: PropTypes.array,
  selectedSubCategory: PropTypes.string
}

export default ProductSelection
