import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getProductService } from '../../../../../../../modules/services/mediaOrdersProducts'

export default function useFetchProductData({ onSuccess, onError, params, triggerFetch }) {
  const {
    data: selectedProductData,
    error: selectedProductError,
    isError: isSelectedProductError
  } = useQuery({
    queryKey: ['product', params?.id],
    queryFn: async () => getProductService(params),
    enabled: triggerFetch,
    retry: false
  })

  // Handle success side effect
  useEffect(() => {
    if (selectedProductData) {
      onSuccess(selectedProductData)
    }
  }, [selectedProductData, onSuccess])

  // Handle error side effect
  useEffect(() => {
    if (isSelectedProductError) {
      onError(selectedProductError)
    }
  }, [isSelectedProductError, selectedProductError, onError])
}
