import * as Yup from 'yup'
import { COMMENT, SELECTED_FILES } from './fields'

export const getValidationSchema = uploadedFiles => {
  return Yup.object({
    [COMMENT]: Yup.string().required('Please enter a comment'),
    ...(uploadedFiles.length > 1 && {
      [SELECTED_FILES]: Yup.object().test('selectedFiles', 'Please select at least one file', value => {
        return Object.values(value).some(selected => selected)
      })
    })
  })
}
