import { reusableTransformValuesToBE } from '../../formatters'
import {
  INTERNAL_ID,
  MEDIA_PRODUCT_LOCATION_CATEGORY,
  MEDIA_PRODUCT_LOCATION_REGION,
  STORE_NAME,
  STORES
} from '../../fields'

const formatStoresToBE = (values, controllerId) => {
  return values[STORES].filter(store => !!store[STORE_NAME]).map(item => ({
    controller: controllerId,
    [STORE_NAME]: item[STORE_NAME],
    [INTERNAL_ID]: item[INTERNAL_ID]
  }))
}

export const transformValuesToBE = ({ values, controllerTimezone, controllerId }) => {
  const reusableTransformedValues = reusableTransformValuesToBE(values, controllerTimezone)
  const formattedStores = formatStoresToBE(values, controllerId)

  return {
    ...reusableTransformedValues,
    ...(formattedStores.length && { stores: formattedStores }),
    ...(values[INTERNAL_ID] && { [INTERNAL_ID]: values[INTERNAL_ID] }),
    ...(values[MEDIA_PRODUCT_LOCATION_CATEGORY] && {
      [MEDIA_PRODUCT_LOCATION_CATEGORY]: values[MEDIA_PRODUCT_LOCATION_CATEGORY]
    }),
    ...(values[MEDIA_PRODUCT_LOCATION_REGION] && {
      [MEDIA_PRODUCT_LOCATION_REGION]: values[MEDIA_PRODUCT_LOCATION_REGION]
    })
  }
}
