import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import ProductSection from './ProductSection'
import SelectOrDeselectAllButton from './SelectOrDeselectAllButton'

import { bookedMediaSelector } from '../../../../../../../modules/selectors/mediaOrdersBookings'

function SelectFilesStep({ formik, uploadedFiles }) {
  const bookedMedia = useSelector(bookedMediaSelector)

  const bookedMediaWithUploadedFiles = useMemo(
    () => bookedMedia.filter(item => item.uploaded_files.length > 0),
    [bookedMedia]
  )

  const bookedMediaWithUploadedFilesGroupedByProduct = useMemo(() => {
    return Object.values(
      bookedMediaWithUploadedFiles.reduce((acc, media) => {
        if (!acc[media.product]) {
          acc[media.product] = {
            id: media.product,
            name: media.original_product_name,
            bookedMedia: []
          }
        }
        acc[media.product].bookedMedia.push(media)
        return acc
      }, {})
    )
  }, [bookedMediaWithUploadedFiles])

  return (
    <Step
      stepTitle="Select files"
      StepDescriptionRightContent={<SelectOrDeselectAllButton formik={formik} uploadedFiles={uploadedFiles} />}
    >
      {bookedMediaWithUploadedFilesGroupedByProduct.map(bookedMediaGroupedByProduct => {
        return <ProductSection formik={formik} bookedMediaGroupedByProduct={bookedMediaGroupedByProduct} />
      })}
    </Step>
  )
}

SelectFilesStep.propTypes = {
  formik: PropTypes.object,
  uploadedFiles: PropTypes.array.isRequired
}

export default SelectFilesStep
