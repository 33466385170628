import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import classnames from 'classnames'

import UnauthorizedPage from '../UnauthorizedPage'
import RequestedFilesSection from '../../MediaOrderSummary/MediaOrderSummaryContent/RequestedFilesSection'
import MediaOrderInfo from './MediaOrderInfo'

import { useDidMount } from '../../../hooks/useDidMount'
import { createJsonFromQueryString, redirectTo } from '../../../helpers/url'

import { ROUTE_PARAMS, ROUTES } from '../../../constants/routes'

import useAuthStyles from '../../../styles/common/authPages'
import useStyles from './styles'

const MediaOrderRequestedFiles = () => {
  const authClasses = useAuthStyles()
  const classes = useStyles()
  const didMount = useDidMount()

  const { token } = createJsonFromQueryString(window.location.search)
  // when user is not authorized, and don't have token, redirect to login page
  // the useRef is used, because the token can be removed after manipulation with page(drawer opening)
  const blockPageUsageRef = useRef(!token)

  const { [ROUTE_PARAMS.mediaOrderId]: mediaOrderId } = useParams()

  useEffect(() => {
    if (!didMount && blockPageUsageRef.current) {
      redirectTo(ROUTES.login)
    }
  }, [blockPageUsageRef, didMount])

  if (blockPageUsageRef.current) {
    // skip rendering and data fetching before redirect
    return null
  }

  return (
    <UnauthorizedPage
      helmetTitle="helmetTitle.MediaOrderSummary"
      className={classnames(authClasses.authPage, classes.requestedFilesPage)}
    >
      <MediaOrderInfo />
      <RequestedFilesSection mediaOrderId={mediaOrderId} />
    </UnauthorizedPage>
  )
}

export default MediaOrderRequestedFiles
