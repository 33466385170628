import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Field from '../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../features/formatters'

import { mediaProductLocationRegionsSelector } from '../../../../../modules/selectors/mediaOrdersProductLocations'

import { MEDIA_PRODUCT_LOCATION_REGION } from '../../fields'

const LocationRegionSelect = ({ formik }) => {
  const mediaProductLocationRegions = useSelector(mediaProductLocationRegionsSelector)

  const { values, setFieldValue } = formik

  const formattedRegions = useMemo(() => {
    return formatOptionsList({
      list: mediaProductLocationRegions,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaProductLocationRegions])

  const clearRegionCategoryHandler = useCallback(() => {
    setFieldValue(MEDIA_PRODUCT_LOCATION_REGION, null)
  }, [setFieldValue])

  return (
    <Field
      name={MEDIA_PRODUCT_LOCATION_REGION}
      formik={formik}
      placeholder="Region"
      options={formattedRegions}
      portaled={true}
      showClearInputIcon={values[MEDIA_PRODUCT_LOCATION_REGION]}
      onClearInput={clearRegionCategoryHandler}
    />
  )
}

export default LocationRegionSelect
