import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ControllerPermissionsEditFormContent from './ControllerPermissionsEditFormContent'

import {
  clearUpdateControllerMember,
  updateControllerMember,
  updateControllerMemberSuccess
} from '../../../../../modules/actions/controller'
import {
  updatedControllerMemberErrorSelector,
  updatedControllerMemberIsLoadingSelector,
  updatedControllerMemberSelector
} from '../../../../../modules/selectors/controller'
import { updateControllerPermissions } from '../../../../../modules/actions/app'
import { currentUserIdSelector, selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { transformValuesToBE } from './formatters'
import { isEqual } from '../../../../../helpers/common'

import { CONTROLLER_PERMISSIONS_EDIT } from '../../../../../constants/forms'
import { getInitialValues } from './fields'

const ControllerPermissionsEditForm = ({ memberData = {} }) => {
  const dispatch = useDispatch()

  const { id: memberId, user: memberUserId, role: memberRole } = memberData

  const currentUserId = useSelector(currentUserIdSelector)
  const { wasUpdated: controllerMemberWasUpdated } = useSelector(updatedControllerMemberSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const initialValues = useMemo(() => getInitialValues(memberData), [memberData])

  const clearUpdateCategoryPageHandler = useCallback(() => {
    dispatch(clearUpdateControllerMember())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const hasChanges = !isEqual(values, initialValues)
      const formattedData = transformValuesToBE(values)

      if (hasChanges) {
        dispatch(updateControllerMember(memberId, formattedData))
      } else {
        dispatch(updateControllerMemberSuccess(formattedData))
      }
    },
    [dispatch, memberId, initialValues]
  )

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })

  const { values } = formik

  const successSubmitHandler = useCallback(() => {
    if (memberUserId === currentUserId) {
      // When we update permissions for controller member which is current user,
      // we need to update permissions for selected controller as well,
      // because selected controller stores permissions that are relevant for current user
      const { permissions } = transformValuesToBE(values)
      dispatch(updateControllerPermissions(selectedControllerId, permissions))
    }
  }, [dispatch, memberUserId, currentUserId, selectedControllerId, values])

  return (
    <Form
      formName={CONTROLLER_PERMISSIONS_EDIT}
      formik={formik}
      successSubmit={controllerMemberWasUpdated}
      errorSelector={updatedControllerMemberErrorSelector}
      isLoadingSelector={updatedControllerMemberIsLoadingSelector}
      clearHandler={clearUpdateCategoryPageHandler}
      onSuccessSubmit={successSubmitHandler}
    >
      <ControllerPermissionsEditFormContent formik={formik} memberRole={memberRole} />
    </Form>
  )
}

export default ControllerPermissionsEditForm
