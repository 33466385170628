import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Toggle from '../../../../../../../components/Toggle'

import {
  updateProductErrorSelector,
  updateProductIdSelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'
import {
  PRODUCT_STATUS,
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_PAUSED
} from '../../../../../../../forms/Multiplatform/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

const StatusCell = ({ productData, onStatusChange }) => {
  const productId = productData.id
  const updateProductId = useSelector(updateProductIdSelector)
  const statusUpdateError = useSelector(updateProductErrorSelector)
  const isActive = productData[PRODUCT_STATUS] === PRODUCT_STATUS_ACTIVE

  // currently we set UI to new state right after user clicks on toggle
  // but if we get error from server we should reset toggle to previous state
  const resetToggleOnError = productId === updateProductId && !!statusUpdateError

  const handleStatusChange = useCallback(() => {
    onStatusChange({
      id: productId,
      [PRODUCT_STATUS]: isActive ? PRODUCT_STATUS_PAUSED : PRODUCT_STATUS_ACTIVE,
      // do not show skeleton when we update product status on toggle status change
      hideUpdateSkeleton: true
    })
  }, [onStatusChange, productId, isActive])

  return (
    <Toggle
      onToggle={handleStatusChange}
      isInitiallyEnabled={isActive}
      triggerUpdate={resetToggleOnError}
      updatedState={isActive}
    />
  )
}

StatusCell.propTypes = {
  productData: PropTypes.object.isRequired,
  onStatusChange: PropTypes.func.isRequired
}

export default StatusCell
