import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import TabTitle from '../../../../../features/components/ContentTabs/TabTitle'

import usePermissions from '../../../../../hooks/usePermissions'

import {
  clearGetUploadedFilesPendingApprovalCount,
  getUploadedFilesPendingApprovalCount
} from '../../../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import {
  uploadedFilesPendingApprovalItemsCountSelector,
  uploadedFilesPendingApprovalItemsCountWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'

import { UPLOADED_FILES_PENDING_APPROVAL_ITEMS_COUNT_PERMISSION } from '../../../../../constants/permissions'

const ApprovalsTabTitle = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const permissions = usePermissions()
  const showPendingApprovalItemsCount = permissions.can(
    'manage',
    UPLOADED_FILES_PENDING_APPROVAL_ITEMS_COUNT_PERMISSION
  )

  const controllerId = useSelector(selectedControllerIdSelector)
  const uploadedFilesPendingApprovalItemsCount = useSelector(uploadedFilesPendingApprovalItemsCountSelector)
  const uploadedFilesPendingApprovalItemsCountWasLoaded = useSelector(
    uploadedFilesPendingApprovalItemsCountWasLoadedSelector
  )

  const pendingApprovalCountChipText = useMemo(() => {
    return showPendingApprovalItemsCount &&
      uploadedFilesPendingApprovalItemsCountWasLoaded &&
      uploadedFilesPendingApprovalItemsCount > 0
      ? uploadedFilesPendingApprovalItemsCount
      : ''
  }, [
    showPendingApprovalItemsCount,
    uploadedFilesPendingApprovalItemsCountWasLoaded,
    uploadedFilesPendingApprovalItemsCount
  ])

  useEffect(() => {
    dispatch(
      getUploadedFilesPendingApprovalCount({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetUploadedFilesPendingApprovalCount())
    }
  }, [dispatch])

  return (
    <TabTitle
      title={t('Approvals')}
      chipText={pendingApprovalCountChipText}
      showLoadingChip={showPendingApprovalItemsCount && !uploadedFilesPendingApprovalItemsCountWasLoaded}
    />
  )
}

export default ApprovalsTabTitle
