import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'

import { mediaSubCategoriesSelector } from '../../../../../modules/selectors/mediaOrdersProducts'

// default empty object to avoid errors when Context is not provided
const defaultContext = {}

export const SELECTED_SUB_CATEGORIES = 'selectedSubCategories'

export const SubCategoriesFilter = ({ Context = defaultContext }) => {
  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_SUB_CATEGORIES]: selectedSubCategories } = filtersState

  const setSelectedSubCategories = useCallback(
    newSubCategories => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_SUB_CATEGORIES]: newSubCategories
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const formattedMediaSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  const selectSubCategoryHandler = useCallback(
    data => {
      setSelectedSubCategories(data)
    },
    [setSelectedSubCategories]
  )

  const setSubCategoryHandler = useCallback(
    (name, data) => {
      selectSubCategoryHandler(data)
    },
    [selectSubCategoryHandler]
  )

  return (
    <MultiSelectBox
      name="Asset Type"
      options={formattedMediaSubCategoriesOptions}
      value={selectedSubCategories}
      onChange={selectSubCategoryHandler}
      placeholder="Asset Type"
      setFieldValue={setSubCategoryHandler}
      portaled
    />
  )
}

SubCategoriesFilter.propTypes = {
  Context: PropTypes.object
}

export default SubCategoriesFilter
