import React, { memo, useCallback, useContext, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import Col from '../Col'
import MinimisedSection from '../../../../MinimisedSection'

import { TABLE_ROW_CLASSNAME } from '../../../../../constants/pdf'

import useStyles from './styles'
import useCommonRowStyles from '../../../styles'
import { phonesDownSize } from '../../../../../styles/const/breakpoints'

const defaultContext = {}

const TableRowExpandable = memo(
  ({
    cols,
    rowData,
    rowIndex,
    isBigRow,
    renderExpandedContent,
    rowClassName,
    isRowLoading,
    expandableRowsOpenStatesContext
  }) => {
    const classes = useStyles()
    const commonRowClasses = useCommonRowStyles({ isBigRow })

    const { openStates, setOpenStates } = useContext(expandableRowsOpenStatesContext || defaultContext)

    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

    const visibleColumns = useMemo(() => {
      return cols.reduce((result, col, currentIndex) => {
        if (!isMobile || col.showOnMobile !== false) {
          return [
            ...result,
            <Col colData={col} rowData={rowData} rowIndex={rowIndex} key={currentIndex} isColLoading={isRowLoading} />
          ]
        }
        return result
      }, [])
    }, [cols, isMobile, rowData, rowIndex, isRowLoading])

    const onToggleHandler = useCallback(() => {
      if (openStates && setOpenStates) {
        const newOpenStates = { ...openStates }
        newOpenStates[rowIndex] = !newOpenStates[rowIndex]
        setOpenStates(newOpenStates)
      }
    }, [rowIndex, openStates, setOpenStates])

    return (
      <MinimisedSection
        isFullyClickable={false}
        headerClassName={classes.expandableRowHeader}
        headerContent={visibleColumns}
        className={classnames(commonRowClasses[TABLE_ROW_CLASSNAME], classes.expandableRow, rowClassName)}
        toggleWrapperClassName={classes.minimisedSectionButton}
        toggleButtonClassName={classes.minimisedSectionButtonIcon}
        defaultOpened={openStates?.[rowIndex]}
        onToggle={onToggleHandler}
      >
        <div>{renderExpandedContent(rowData)}</div>
      </MinimisedSection>
    )
  }
)

const colsPropTypeShape = {
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableRowExpandable.propTypes = {
  rowData: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  isBigRow: PropTypes.bool,
  rowIndex: PropTypes.number,
  renderExpandedContent: PropTypes.func.isRequired,
  rowClassName: PropTypes.string
}

export default TableRowExpandable
