import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaOrderCreateSimpleForm from './MediaOrderCreateSimpleForm'
import { MediaOrderFormProvider } from '../MediaOrderFormContext'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { clearMembersList, getMembersList } from '../../../../modules/actions/selfAccountTeam'
import { selectedSelfAccountSelector } from '../../../../modules/selectors/app'
import { membersIsLoadingSelector } from '../../../../modules/selectors/selfAccountTeam'

import { MEDIA_ORDER_CREATE_SIMPLE } from '../../../../constants/forms'

function MediaOrderCreateSimple() {
  const dispatch = useDispatch()

  const selfAccount = useSelector(selectedSelfAccountSelector)

  const membersIsLoading = useSelector(membersIsLoadingSelector)

  const onAfterFormClose = useCallback(() => {
    dispatch(clearMembersList())
  }, [dispatch])

  const { isFormOpen } = useManageFormsDrawer({
    formName: MEDIA_ORDER_CREATE_SIMPLE,
    onAfterFormClose
  })

  useEffect(() => {
    // we fetch members with limit 1 just to find out if there are any members,
    // if there are members we preselect radio button for platform users
    // otherwise we preselect radio button for email
    if (isFormOpen) {
      dispatch(getMembersList({ account: selfAccount, limit: 1 }))
    }
  }, [dispatch, selfAccount, isFormOpen])

  return (
    <FormDrawerWrapper
      formName={MEDIA_ORDER_CREATE_SIMPLE}
      openButtonText="New Booking"
      // hide form opening for now
      showOpenButton={false}
      closeOnSubmit={false}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
      isFormLoading={membersIsLoading}
    >
      <MediaOrderFormProvider>
        <MediaOrderCreateSimpleForm />
      </MediaOrderFormProvider>
    </FormDrawerWrapper>
  )
}

export default MediaOrderCreateSimple
