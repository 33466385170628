// get product locations
import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get media product location
export const mediaProductLocationSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getMediaProductLocation.data,
  data => data
)

export const getMediaProductLocationIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getMediaProductLocation.isLoading,
  isLoading => isLoading
)

export const getMediaProductLocationErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getMediaProductLocation.error,
  error => error
)

export const mediaProductLocationWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getMediaProductLocation.wasLoaded,
  wasLoaded => wasLoaded
)

// Get media product locations
export const mediaProductLocationsSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocations.results,
  results => results
)

export const mediaProductLocationsIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocations.isLoading,
  isLoading => isLoading
)

export const mediaProductLocationsErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocations.error,
  error => error
)

export const mediaProductLocationsWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocations.wasLoaded,
  wasLoaded => wasLoaded
)

export const mediaProductLocationsNextSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocations.paging.next,
  next => next
)

// Create media product location
export const createMediaProductLocationSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.createMediaProductLocation.data,
  data => data
)

export const createMediaProductLocationIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.createMediaProductLocation.isLoading,
  isLoading => isLoading
)

export const createMediaProductLocationErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.createMediaProductLocation.error,
  error => error
)

export const createMediaProductLocationWasCreatedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.createMediaProductLocation.wasCreated,
  wasCreated => wasCreated
)

// Update media product location
export const updateMediaProductLocationSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.updateMediaProductLocation.data,
  data => data
)

export const updateMediaProductLocationIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.updateMediaProductLocation.isLoading,
  isLoading => isLoading
)

export const updateMediaProductLocationErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.updateMediaProductLocation.error,
  error => error
)

export const updateMediaProductLocationWasUpdatedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.updateMediaProductLocation.wasUpdated,
  wasUpdated => wasUpdated
)

// Get media product attached locations
export const mediaProductAttachedLocationsSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductAttachedLocations.results,
  results => results
)

export const mediaProductAttachedLocationsIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductAttachedLocations.isLoading,
  isLoading => isLoading
)

export const mediaProductAttachedLocationsErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductAttachedLocations.error,
  error => error
)

export const mediaProductAttachedLocationsWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductAttachedLocations.wasLoaded,
  wasLoaded => wasLoaded
)

// Get booked media attached locations
export const bookedMediaAttachedLocationsSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.bookedMediaAttachedLocations.results,
  results => results
)

export const bookedMediaAttachedLocationsIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.bookedMediaAttachedLocations.isLoading,
  isLoading => isLoading
)

export const bookedMediaAttachedLocationsErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.bookedMediaAttachedLocations.error,
  error => error
)

export const bookedMediaAttachedLocationsWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.bookedMediaAttachedLocations.wasLoaded,
  wasLoaded => wasLoaded
)

// Get product stores
export const getProductStoresSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getProductStores.results,
  results => results
)

export const getProductStoresIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getProductStores.isLoading,
  isLoading => isLoading
)

export const getProductStoresErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getProductStores.error,
  error => error
)

export const getProductStoresWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.getProductStores.wasLoaded,
  wasLoaded => wasLoaded
)

// Get media product location categories
export const mediaProductLocationCategoriesSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationCategories.results,
  results => results
)

export const getMediaProductLocationCategoriesIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationCategories.isLoading,
  isLoading => isLoading
)

export const getMediaProductLocationCategoriesErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationCategories.error,
  error => error
)

export const mediaProductLocationCategoriesWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationCategories.wasLoaded,
  wasLoaded => wasLoaded
)

// Get media product location regions
export const mediaProductLocationRegionsSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationRegions.results,
  results => results
)

export const getMediaProductLocationRegionsIsLoadingSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationRegions.isLoading,
  isLoading => isLoading
)

export const getMediaProductLocationRegionsErrorSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationRegions.error,
  error => error
)

export const mediaProductLocationRegionsWasLoadedSelector = createSelector(
  ({ mediaOrdersProductLocations }) => mediaOrdersProductLocations.mediaProductLocationRegions.wasLoaded,
  wasLoaded => wasLoaded
)
