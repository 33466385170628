import React, { memo, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import { useTranslatedChildren } from '../../../../../hooks/useTranslatedChildren'

import { TABLE_COL_CLASSNAME } from '../../../../../constants/pdf'
import useStyles from './styles'

const Col = memo(function WrappedCol({ colData, rowData, rowIndex, columnClassName, isColLoading = false }) {
  const classes = useStyles({})

  /* return either simple fieldKey value or column component */
  const columnContent = useMemo(
    () => (colData.fieldKey ? rowData[colData.fieldKey] || '-' : colData.Cell(rowData, rowIndex)),
    [colData, rowData, rowIndex]
  )

  const translatedContent = useTranslatedChildren(columnContent)

  return (
    <div
      className={classnames(classes[TABLE_COL_CLASSNAME], columnClassName, colData.className)}
      style={colData.style}
      {...colData.attributes}
    >
      {isColLoading ? <Skeleton width="100%" /> : translatedContent}
    </div>
  )
})

Col.propTypes = {
  colData: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
  columnClassName: PropTypes.string,
  isColLoading: PropTypes.bool
}

export default Col
