import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useDeleteAction from '../../../../../../../features/hooks/useDeleteAction'

import { openForm } from '../../../../../../../modules/actions/forms'
import {
  clearDeleteProduct,
  clearUpdateProduct,
  deleteProduct,
  updateProduct
} from '../../../../../../../modules/actions/mediaOrdersProducts'
import {
  deleteProductErrorSelector,
  deleteProductIsLoadingSelector,
  deleteProductSelector,
  updateProductErrorSelector,
  updateProductWasUpdatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'
import { MANAGE_INVENTORY, MEDIA_PRODUCT_GROUP_EDIT, PRODUCT_EDIT } from '../../../../../../../constants/forms'
import { PRODUCT_STATUS } from '../../../../../../../forms/Multiplatform/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

export default function useMediaProductActions({ products }) {
  const dispatch = useDispatch()
  // we use this state to not show skeleton when we update product status
  const [hideUpdateSkeleton, setHideUpdateSkeleton] = useState(false)

  const updateProductError = useSelector(updateProductErrorSelector)
  const updateProductWasUpdated = useSelector(updateProductWasUpdatedSelector)

  const openMangeInventoryFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: MANAGE_INVENTORY }))
    },
    [dispatch]
  )

  const openEditFormHandler = useCallback(
    (e, id) => {
      const currentProduct = products.find(product => product.id === id)
      const isGroup = !!currentProduct?.group
      const formName = isGroup ? MEDIA_PRODUCT_GROUP_EDIT : PRODUCT_EDIT
      dispatch(openForm({ id, formName }))
    },
    [dispatch, products]
  )

  const handleDeleteProductHandler = useCallback(
    ({ id }) => {
      dispatch(deleteProduct(id))
    },
    [dispatch]
  )

  const clearDeleteHandler = () => {
    dispatch(clearDeleteProduct())
  }

  const updatePackageStatusHandler = useCallback(
    ({ id, [PRODUCT_STATUS]: status, hideUpdateSkeleton }) => {
      hideUpdateSkeleton && setHideUpdateSkeleton(true)
      dispatch(
        updateProduct(id, {
          [PRODUCT_STATUS]: status
        })
      )
    },
    [dispatch, setHideUpdateSkeleton]
  )

  const {
    handleDelete,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    dataWaitingForDelete
  } = useDeleteAction({
    onDelete: handleDeleteProductHandler,
    onClearDelete: clearDeleteHandler,
    deletedItemSelector: deleteProductSelector,
    deleteItemErrorSelector: deleteProductErrorSelector,
    deleteItemIsLoadingSelector: deleteProductIsLoadingSelector
  })

  // function for open delete modal
  const deleteProductHandler = useCallback(
    (e, id) => {
      const foundProduct = products.find(product => product.id === id)
      handleDeleteRequest({ item: foundProduct })
    },
    [products, handleDeleteRequest]
  )

  useEffect(() => {
    // if we have update status error or success we need to clear it
    if (!!updateProductError || updateProductWasUpdated) {
      setHideUpdateSkeleton(false)
      dispatch(clearUpdateProduct())
    }
  }, [dispatch, hideUpdateSkeleton, updateProductError, updateProductWasUpdated])

  return {
    hideUpdateSkeleton,
    handleDelete,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    dataWaitingForDelete,
    openMangeInventoryFormHandler,
    openEditFormHandler,
    deleteProductHandler,
    updatePackageStatusHandler
  }
}
