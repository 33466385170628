import { v4 as uuidv4 } from 'uuid'

import {
  BRAND_ID,
  BRAND_NAME,
  BRANDS,
  CURRENCY,
  CUSTOMER_ID,
  initialBrandValue,
  LANDSCAPE_LOGO,
  LANDSCAPE_LOGO_FILE_NAME,
  OFFSITE_CAMPAIGN_TYPES,
  SQUARE_LOGO,
  SQUARE_LOGO_FILE_NAME
} from '../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import { REPRESENTATIVE } from '../../../../../../constants/mediaOrders'
import { CATEGORY, SUBCATEGORY } from '../../fields'

export const NAME = 'name'
export const TAGS = 'tags'

export const getInitialValues = ({ account = {}, isController, tagsTitles = [] }) => {
  const selectedOffsiteCampaignTypes = account?.[OFFSITE_CAMPAIGN_TYPES]?.map(type => ({
    [type]: true
  }))

  return {
    [NAME]: account[NAME] || '',
    [BRANDS]: account[BRANDS]
      ? account[BRANDS].map(item => ({
          data: item, // keep the original data for the brand
          // map to match the shape of brandShape in case of missing fields
          [BRAND_NAME]: item[BRAND_NAME] || '',
          [BRAND_ID]: item[BRAND_ID] || '',
          [CATEGORY]: item[CATEGORY]
            ? {
                value: item[CATEGORY].id,
                label: item[CATEGORY].name
              }
            : '',
          [SUBCATEGORY]: item[SUBCATEGORY] || '',
          [SQUARE_LOGO]: item[SQUARE_LOGO] || '',
          [SQUARE_LOGO_FILE_NAME]: item[SQUARE_LOGO_FILE_NAME] || '',
          [LANDSCAPE_LOGO]: item[LANDSCAPE_LOGO] || '',
          [LANDSCAPE_LOGO_FILE_NAME]: item[LANDSCAPE_LOGO_FILE_NAME] || '',
          id: uuidv4()
        }))
      : [initialBrandValue],
    ...(isController && {
      // controller type fields
      [TAGS]: account[TAGS].filter(tag => tagsTitles.includes(tag)).map(tag => ({ value: tag, label: tag })) || [],
      [ALLOW_CUSTOM_CLICK_THROUGH_LINK]: account[ALLOW_CUSTOM_CLICK_THROUGH_LINK] || false,
      [OFFSITE_CAMPAIGN_TYPES]: selectedOffsiteCampaignTypes?.reduce((acc, item) => ({ ...acc, ...item }), {}) || {}
    }),
    [REPRESENTATIVE]: account[REPRESENTATIVE] || '',
    [CUSTOMER_ID]: account[CUSTOMER_ID] || '',
    [CURRENCY]: account[CURRENCY] || ''
  }
}
