import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'
import { getAllSubSubCategories } from './helpers'

import { mediaSubCategoriesSelector } from '../../../../../modules/selectors/mediaOrdersProducts'

// default empty object to avoid errors when Context is not provided
const defaultContext = {}

export const SELECTED_SUB_SUB_CATEGORIES = 'selectedSubSubCategories'

export const SubSubCategoriesFilter = ({ Context = defaultContext }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_SUB_SUB_CATEGORIES]: selectedSubSubCategories } = filtersState

  const setSelectedSubSubCategories = useCallback(
    newSubSubCategories => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_SUB_SUB_CATEGORIES]: newSubSubCategories
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const formattedAllSubSubCategoriesOptions = useMemo(() => {
    const allSubSubCategories = getAllSubSubCategories(mediaSubCategories)

    return formatOptionsList({
      list: allSubSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  const selectSubSubCategoryHandler = useCallback(
    data => {
      setSelectedSubSubCategories(data)
    },
    [setSelectedSubSubCategories]
  )

  const setSubSubCategoryHandler = useCallback(
    (name, data) => {
      selectSubSubCategoryHandler(data)
    },
    [selectSubSubCategoryHandler]
  )

  return (
    <MultiSelectBox
      name="Asset Subtype"
      options={formattedAllSubSubCategoriesOptions}
      value={selectedSubSubCategories}
      onChange={selectSubSubCategoryHandler}
      placeholder="Asset Subtype"
      setFieldValue={setSubSubCategoryHandler}
      portaled
    />
  )
}

SubSubCategoriesFilter.propTypes = {
  Context: PropTypes.object
}

export default SubSubCategoriesFilter
