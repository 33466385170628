import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import StopTimeFields from '../../ReusableFormFields/StopTimeFields'

import { STOP_DATE, STOP_TIME } from '../../ReusableFormFields/StopTimeFields/fields'

import useStyles from '../../../styles/common/stepForms'

const stepFields = [STOP_TIME, STOP_DATE]

const LineItemStopTimeStep = ({ formik, handleStepChange, stepTitle, indefiniteOptionDescription }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepTitle}>{t(stepTitle)}</div>
      <StopTimeFields formik={formik} indefiniteOptionDescription={indefiniteOptionDescription} />
      <div className={classes.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default LineItemStopTimeStep
