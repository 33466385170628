import React, { useCallback, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { DateRange as ExternalDateRange } from 'react-date-range'

import SelectDropdown from '../../../components/SelectDropdown'

import useDateRange from './hooks'
import useStartWeekDay from '../../hooks/useStartWeekDay'

import { getInitialDatePeriod } from '../../../helpers/date'

import { initialDatePeriod } from '../../../constants/dates'

import useStyles from './styles'

const inputRanges = []

function DateRange({
  className,
  rangeClassName,
  dateRangesClassName,
  placeholder = 'Start date - end date',
  dateRangeData,
  onDateRangeChange,
  minDate,
  maxDate,
  isSmall = false,
  onStartDateSelect,
  disabledDates,
  isLoading,
  onClose,
  onOpen,
  formatDateRangeLabel,
  isSelectedValueBrandPrimary,
  placement,
  portaled,
  isFloatingDropdown,
  dropdownClassName,
  allowPlacementFlip,
  isClearable = false,
  isHighlighted = false
}) {
  const classes = useStyles()

  const [datePeriod, setDatePeriod] = useState(getInitialDatePeriod(dateRangeData))

  const weekStartsOn = useStartWeekDay()
  const { isOpen, setIsOpen, rangeColors, rangeSelectedTextColor, dateRangeLabel } = useDateRange(datePeriod)

  const formattedDateRangeLabel = formatDateRangeLabel ? formatDateRangeLabel(datePeriod) : undefined

  const shownDate = useMemo(() => (datePeriod && datePeriod.startDate) || minDate, [datePeriod, minDate])

  // ref for tracking start date selection status
  const isStartDateSelected = useRef(false)

  const handleDropdownVisibility = useCallback(
    show => {
      setIsOpen(show)
      if (isOpen && !show) {
        // return to default on close
        onClose && onClose()
        setDatePeriod(getInitialDatePeriod(dateRangeData))

        // reset isStartDateSelected state when user closes without selection
        isStartDateSelected.current = false
      } else {
        onOpen && onOpen()
      }
    },
    [isOpen, setIsOpen, dateRangeData, onOpen, onClose]
  )

  const handleDateRangeChange = useCallback(
    period => {
      setDatePeriod(period.selection)
      if (isStartDateSelected.current) {
        // we want to execute onDateRangeChange callback only when user selects full date range
        // for that reason we add extra state using ref to track double selection
        onDateRangeChange(period.selection)
      } else {
        onStartDateSelect && onStartDateSelect(period?.selection?.startDate)
        isStartDateSelected.current = true
      }
    },
    [onDateRangeChange, onStartDateSelect]
  )

  const handleFocusChange = useCallback(
    focus => {
      if (focus && !focus[1]) {
        // close dropdown and reset isDateSelected when endDate is selected:
        setIsOpen(false)
        onClose && onClose()
        isStartDateSelected.current = false
      }
    },
    [setIsOpen, onClose]
  )

  const clearDateRangeHandler = useCallback(() => {
    setDatePeriod(initialDatePeriod)
    // endDate could be Invalid Date, for this reason before using endDate it should be checked by isValid from date-fns
    onDateRangeChange(initialDatePeriod)
  }, [onDateRangeChange])

  // ExternalDateRange supports to have multiple ranges in one time, as we use only one - we set only first array element make sure it memoized to avoid new array create each time
  const ranges = useMemo(() => [datePeriod], [datePeriod])

  const showClearInputIcon = useMemo(
    () => Boolean(isClearable && datePeriod.startDate && datePeriod.endDate),
    [isClearable, datePeriod.startDate, datePeriod.endDate]
  )

  return (
    <div className={classnames('field', className)}>
      <SelectDropdown
        className={classes.dateRange}
        dropdownClassName={dropdownClassName}
        value={formattedDateRangeLabel || dateRangeLabel || ''}
        placeholder={placeholder}
        isManuallyOpened={isOpen}
        onVisibilityChange={handleDropdownVisibility}
        isSmall={isSmall}
        isLoading={isLoading}
        isSelectedValueBrandPrimary={isSelectedValueBrandPrimary}
        valueContainerClassName={classes.valueContainer}
        placement={placement}
        allowPlacementFlip={allowPlacementFlip}
        portaled={portaled}
        isFloatingDropdown={isFloatingDropdown}
        showClearInputIcon={showClearInputIcon}
        onClearInput={clearDateRangeHandler}
        isHighlighted={isHighlighted}
      >
        <div className={classes.dateRangeDropdown}>
          <div className={classnames(classes.dateRanges, dateRangesClassName)}>
            <ExternalDateRange
              // start week day
              weekStartsOn={weekStartsOn}
              onChange={handleDateRangeChange}
              className={classnames(classes.range, rangeClassName)}
              color={rangeSelectedTextColor}
              showDateDisplay={false}
              ranges={ranges}
              inputRanges={inputRanges}
              rangeColors={rangeColors}
              onRangeFocusChange={handleFocusChange}
              shownDate={shownDate && new Date(shownDate)}
              minDate={minDate && new Date(minDate)}
              maxDate={maxDate && new Date(maxDate)}
              direction="horizontal"
              // disable drag selection if there are disabled dates, because drag selection still selects disabled dates
              dragSelectionEnabled={!disabledDates}
              disabledDates={disabledDates}
            />
          </div>
        </div>
      </SelectDropdown>
    </div>
  )
}

DateRange.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  rangeClassName: PropTypes.string,
  dateRangesClassName: PropTypes.string,
  placeholder: PropTypes.string,
  dateRangeData: PropTypes.object.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  isSmall: PropTypes.bool,
  disabledDates: PropTypes.array,
  isLoading: PropTypes.bool,
  onStartDateSelect: PropTypes.func,
  onClose: PropTypes.func,
  formatDateRangeLabel: PropTypes.func,
  isSelectedValueBrandPrimary: PropTypes.bool,
  placement: PropTypes.string,
  allowPlacementFlip: PropTypes.bool,
  portaled: PropTypes.bool,
  isFloatingDropdown: PropTypes.bool,
  isClearable: PropTypes.bool,
  isHighlighted: PropTypes.bool
}

export default DateRange
