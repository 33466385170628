import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import BookedMediaAndFilesTableData from './BookedMediaAndFilesTableData'
import TableDataLoader from '../../../../components/Table/TableDataLoader'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getOrderBookedMedia } from '../../../../modules/actions/mediaOrdersBookings'
import {
  bookedMediaSelector,
  bookedMediaIsLoadingSelector,
  bookedMediaErrorSelector,
  bookedMediaWasLoadedSelector,
  bookedMediaNextSelector
} from '../../../../modules/selectors/mediaOrdersBookings'
import { controllerMembersWasLoadedSelector } from '../../../../modules/selectors/controller'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useStyles from './styles'
import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  id: 70,
  internalProductId: 140,
  name: 190,
  account: 167,
  quantity: 60,
  cost: 150,
  actions: 40,
  uploadedFiles: 185
}

const BookedMediaAndFilesTable = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const dispatch = useDispatch()
  const bookedMedias = useSelector(bookedMediaSelector)
  const bookedMediaNext = useSelector(bookedMediaNextSelector)
  const controllerMembersWasLoaded = useSelector(controllerMembersWasLoadedSelector)

  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const bookedMediaColumnsSchema = useMemo(
    () => [
      {
        header: 'Booking',
        style: { maxWidth: tableColumnsSize.id }
      },
      {
        header: 'Product ID',
        style: {
          maxWidth: tableColumnsSize.internalProductId
        }
      },
      {
        header: 'Name',
        style: {
          minWidth: tableColumnsSize.name
        }
      },
      {
        header: 'Account',
        style: { maxWidth: tableColumnsSize.account }
      },
      {
        header: 'Qty',
        style: { maxWidth: tableColumnsSize.quantity }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      },
      {
        header: 'Uploaded files',
        style: { maxWidth: tableColumnsSize.uploadedFiles }
      },
      {
        header: 'Cost',
        style: { maxWidth: tableColumnsSize.cost }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContentInTheMiddleOfTheTable}>
        <div>{t('There is no booked media')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContentInTheMiddleOfTheTable, t]
  )

  const loadMoreBookedMediaHandler = useCallback(() => {
    dispatch(getOrderBookedMedia(createJsonFromQueryString(`?${bookedMediaNext.split('?')[1]}`)))
  }, [dispatch, bookedMediaNext])

  const mobileSkeletonColumnsSchema = [
    { style: { maxWidth: tableColumnsSize.id, marginInlineEnd: 10 } },
    { style: { maxWidth: `$calc(100% - ${tableColumnsSize.id}px)` } }
  ]

  return (
    <div className={classes.tableWrapper}>
      <TableDataLoader
        itemsLength={bookedMedias.length}
        errorSelector={bookedMediaErrorSelector}
        wasLoadedSelector={bookedMediaWasLoadedSelector}
        isLoadingSelector={bookedMediaIsLoadingSelector}
        additionalDataIsLoading={!controllerMembersWasLoaded}
        skeletonProps={{ cols: isMobile ? mobileSkeletonColumnsSchema : bookedMediaColumnsSchema }}
        noDataContent={noDataContent}
        loadMore={bookedMediaNext ? loadMoreBookedMediaHandler : null}
      >
        <BookedMediaAndFilesTableData bookedMedias={bookedMedias} />
      </TableDataLoader>
    </div>
  )
}
export default BookedMediaAndFilesTable
