// this is set by devops based
export const REACT_APP_ENVIRONMENTS = {
  dev: 'dev',
  staging: 'staging',
  prod: 'prod'
}

export const CHIP_COLORS = {
  green: 'green',
  yellow: 'yellow',
  grey: 'grey',
  purple: 'purple',
  blue: 'blue',
  red: 'red',
  redWithWhiteColor: 'redWithWhiteColor'
}

export const TOAST_TYPE = {
  success: 'success',
  error: 'error',
  info: 'info'
}

export const MODAL_TYPE = {
  newCampaignCreated: 'newCampaignCreated',
  newLineItemCreated: 'newLineItemCreated',
  automatedLineItemCreated: 'automatedLineItemCreated',
  mediaOrderFacebookCreativeUploaded: 'mediaOrderFacebookCreativeUploaded',
  mediaOrderTikTokCreativeUploaded: 'mediaOrderTikTokCreativeUploaded',
  mediaOrderGoogleCreativeUploaded: 'mediaOrderGoogleCreativeUploaded',
  mediaOrderDv360CreativeUploaded: 'mediaOrderDv360CreativeUploaded',
  quotationWasSent: 'quotationWasSent'
}

export const listItemChangeStatus = {
  active: {
    status: 'active',
    changeTo: 'paused',
    changeLabel: 'Pause'
  },
  paused: {
    status: 'paused',
    changeTo: 'active',
    changeLabel: 'Activate'
  }
}

export const facebookListItemInsights = [
  'impressions',
  'cpm',
  'ctr',
  'clicks',
  'cpc',
  'reach',
  'engagement',
  'frequency',
  'spend',
  'purchase',
  'roas'
]

export const googleListItemInsights = [
  'impressions',
  'cpm',
  'ctr',
  'clicks',
  'cpc',
  'spend',
  'purchase',
  'roas',
  'active_view_impressions',
  'video_view_rate',
  'video_views'
]

export const dv360ListItemInsights = [
  'clicks',
  'cpc',
  'cpm',
  'ctr',
  'impressions',
  'purchase',
  'spend',
  'video_view_rate',
  'video_views'
]

export const tikTokListItemInsights = [
  'clicks',
  'cpc',
  'cpm',
  'ctr',
  'engagement',
  'frequency',
  'impressions',
  'purchase',
  'reach',
  'roas',
  'spend'
]

export const defaultEventColor = '#314D96'
export const eventColors = [
  '#FF4B55',
  '#FF7D97',
  '#C837AB',
  '#7958BE',
  '#6CF5C2',
  '#1778F2',
  '#A7E8A6',
  '#34A853',
  '#AE8A21',
  '#FEE45A',
  '#F9CF48',
  '#FEA02C',
  '#B11010',
  defaultEventColor,
  '#E5E5E5',
  '#929292',
  '#555555',
  '#000000'
]

// invite types which come from 't' query param on /accept-invite page
export const USER_INVITE = 'i'
export const AGENCY_INVITE = 'ai'
export const CONTROLLER_INVITE = 'ci'

// amazon cognito mfa types
export const LOGIN_SMS_MFA = 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
export const LOGIN_TOTP_MFA = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'

// tasks processing states (useTasksProcessing hook)
export const PROCESS = 'process'
export const SUCCESS = 'success'
export const ERROR = 'error'
export const INVISIBLE_SKIP = 'invisible_skip'

export const COMPARISON_OPERATORS = {
  EQUAL: 'equal',
  LESS_THAN: 'lt',
  LESS_THAN_OR_EQUAL: 'lte',
  GREATER_THAN: 'gt',
  GREATER_THAN_OR_EQUAL: 'gte',
  CONTAINS: 'contains'
}

export const SORT = {
  ASC: 'asc',
  DESC: 'desc'
}

export const NEXT = 'next'
export const PREV = 'prev'

export const facebookAdsTableId = 'facebook-ads-table'
export const googleAdsTableId = 'google-ads-table'
export const tiktokAdsTableId = 'tiktok-ads-table'
export const dv360AdsTableId = 'dv360-ads-table'

export const mobileOneLineColumnsSchema = [{ style: { minWidth: '100%', maxWidth: '100%' } }]
