import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  mediaProductsFiltersWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginBottom: 25,
    gap: 10
  }
})

export default useStyles
