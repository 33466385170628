import React, { useMemo } from 'react'
import PdfPageSkeleton from '../../../../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Can from '../../../../../../../features/components/Can'
import ContractReview from '../../../../../../../features/components/Contract/ContractReview'
import QuotationCreate from '../../../../ContractMediaOrderGenerate/SignContractModal/QuotationCreate'
import ContractButtonSection from '../../../../../../../pages/Proposals/QuotationReview/ContractButtonSection'
import ContractMediaOrderGenerate from '../../../../ContractMediaOrderGenerate'

import usePermissions from '../../../../../../../hooks/usePermissions'

import {
  activeContractSelector,
  createContractsErrorSelector,
  deleteContractSignatureIsLoadingSelector,
  updateContractIsLoadingSelector
} from '../../../../../../../modules/selectors/contracts'

import { QUOTATION_PERMISSION, SIGNATORY_PERMISSION } from '../../../../../../../constants/permissions'

const CreatedContract = ({ onSuccessSubmit, isContractSigned }) => {
  const { t } = useTranslation()

  const permissions = usePermissions()
  const allowSignContract = permissions.can('manage', SIGNATORY_PERMISSION)

  const activeContract = useSelector(activeContractSelector)
  const deleteContractSignatureIsLoading = useSelector(deleteContractSignatureIsLoadingSelector)
  const createContractsError = useSelector(createContractsErrorSelector)
  const updateContractIsLoading = useSelector(updateContractIsLoadingSelector)

  // determine if current contract is already created as quotation
  const isQuotation = activeContract?.quotation
  const allowCreateQuotation = !isContractSigned && !isQuotation

  const isPdfGenerating = useMemo(
    () => updateContractIsLoading || deleteContractSignatureIsLoading,
    [updateContractIsLoading, deleteContractSignatureIsLoading]
  )

  const isContractInvalid = activeContract?.status === 'invalid'

  if (isPdfGenerating) {
    return <PdfPageSkeleton />
  }

  if (isContractInvalid) {
    return (
      <p>
        {t(
          'Sorry, there was an error generating this contract. We are looking into this issue and will contact you with an update as soon as possible.'
        )}
      </p>
    )
  }

  if (createContractsError) {
    return null
  }

  return (
    <>
      {allowCreateQuotation && (
        // Save as Proposal button is only for controller users, the other way to create quotation is through the NoSignPermissionsModal
        <Can I="create" a={QUOTATION_PERMISSION}>
          <ContractButtonSection title={t('Save this contract as a proposal')}>
            <QuotationCreate contractId={activeContract?.id} buttonText={t('Save as Proposal')} />
          </ContractButtonSection>
        </Can>
      )}
      {allowSignContract ? (
        <ContractMediaOrderGenerate contract={activeContract} onSuccessSubmit={onSuccessSubmit} />
      ) : (
        <ContractReview
          contract={activeContract}
          allowCreateQuotation={true} // allow to create quotation when no permission to sign contract
        />
      )}
    </>
  )
}

CreatedContract.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  isContractSigned: PropTypes.bool.isRequired
}

export default CreatedContract
