import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  GET_MEDIA_PRODUCT_LOCATIONS,
  getMediaProductLocationsFailure,
  getMediaProductLocationsSuccess,
  CREATE_MEDIA_PRODUCT_LOCATION,
  createMediaProductLocationFailure,
  createMediaProductLocationSuccess,
  UPDATE_MEDIA_PRODUCT_LOCATION,
  updateMediaProductLocationFailure,
  updateMediaProductLocationSuccess,
  GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS,
  getMediaProductAttachedLocationsSuccess,
  getMediaProductAttachedLocationsFailure,
  GET_BOOKED_MEDIA_ATTACHED_LOCATIONS,
  getBookedMediaAttachedLocationsSuccess,
  getBookedMediaAttachedLocationsFailure,
  GET_PRODUCT_STORES,
  getProductStoresSuccess,
  getProductStoresFailure,
  GET_MEDIA_PRODUCT_LOCATION,
  getMediaProductLocationSuccess,
  getMediaProductLocationFailure,
  GET_MEDIA_PRODUCT_LOCATION_CATEGORIES,
  getMediaProductLocationCategoriesSuccess,
  getMediaProductLocationCategoriesFailure,
  GET_MEDIA_PRODUCT_LOCATION_REGIONS,
  getMediaProductLocationRegionsSuccess,
  getMediaProductLocationRegionsFailure
} from '../actions/mediaOrdersProductLocations'
import {
  createMediaProductLocationService,
  getBookedMediaAttachedLocationsService,
  getMediaProductAttachedLocationsService,
  getMediaProductLocationCategoriesService,
  getMediaProductLocationRegionsService,
  getMediaProductLocationService,
  getMediaProductLocationsService,
  getProductStoresService,
  updateMediaProductLocationService
} from '../services/mediaOrdersProductLocations'

function* mediaOrdersProductLocationsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeLatest(GET_MEDIA_PRODUCT_LOCATION, getMediaProductLocationWorker),
    takeEvery(GET_MEDIA_PRODUCT_LOCATIONS, getMediaProductLocationsWorker),
    takeEvery(CREATE_MEDIA_PRODUCT_LOCATION, createMediaProductLocationWorker),
    takeEvery(UPDATE_MEDIA_PRODUCT_LOCATION, updateMediaProductLocationWorker),
    takeEvery(GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS, getMediaProductAttachedLocationsWorker),
    takeEvery(GET_BOOKED_MEDIA_ATTACHED_LOCATIONS, getBookedMediaAttachedLocationsWorker),
    takeEvery(GET_PRODUCT_STORES, getProductStoresWorker),
    takeEvery(GET_MEDIA_PRODUCT_LOCATION_CATEGORIES, getMediaProductLocationCategoriesWorker),
    takeEvery(GET_MEDIA_PRODUCT_LOCATION_REGIONS, getMediaProductLocationRegionsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getMediaProductLocationWorker({ params }) {
  try {
    const response = yield call(getMediaProductLocationService, params)
    yield put(getMediaProductLocationSuccess(response))
  } catch (error) {
    yield put(getMediaProductLocationFailure(error))
  }
}

function* getMediaProductLocationsWorker({ params }) {
  try {
    const response = yield call(getMediaProductLocationsService, params)
    yield put(getMediaProductLocationsSuccess(response))
  } catch (error) {
    yield put(getMediaProductLocationsFailure(error))
  }
}

function* createMediaProductLocationWorker({ data }) {
  try {
    const response = yield call(createMediaProductLocationService, data)
    yield put(createMediaProductLocationSuccess(response))
  } catch (error) {
    yield put(createMediaProductLocationFailure(error))
  }
}

function* updateMediaProductLocationWorker({ data, id }) {
  try {
    const response = yield call(updateMediaProductLocationService, data, id)
    yield put(updateMediaProductLocationSuccess(response))
  } catch (error) {
    yield put(updateMediaProductLocationFailure(error))
  }
}

function* getMediaProductAttachedLocationsWorker({ params }) {
  try {
    const response = yield call(getMediaProductAttachedLocationsService, params)
    yield put(getMediaProductAttachedLocationsSuccess(response))
  } catch (error) {
    yield put(getMediaProductAttachedLocationsFailure(error))
  }
}

function* getBookedMediaAttachedLocationsWorker({ params }) {
  try {
    const response = yield call(getBookedMediaAttachedLocationsService, params)
    yield put(getBookedMediaAttachedLocationsSuccess(response))
  } catch (error) {
    yield put(getBookedMediaAttachedLocationsFailure(error))
  }
}

function* getProductStoresWorker({ params }) {
  try {
    const response = yield call(getProductStoresService, params)
    yield put(getProductStoresSuccess(response))
  } catch (error) {
    yield put(getProductStoresFailure(error))
  }
}

function* getMediaProductLocationCategoriesWorker({ params }) {
  try {
    const response = yield call(getMediaProductLocationCategoriesService, params)
    yield put(getMediaProductLocationCategoriesSuccess(response))
  } catch (error) {
    yield put(getMediaProductLocationCategoriesFailure(error))
  }
}

function* getMediaProductLocationRegionsWorker({ params }) {
  try {
    const response = yield call(getMediaProductLocationRegionsService, params)
    yield put(getMediaProductLocationRegionsSuccess(response))
  } catch (error) {
    yield put(getMediaProductLocationRegionsFailure(error))
  }
}

export default mediaOrdersProductLocationsWatcher
