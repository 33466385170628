import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import Table from '../../../../../../components/Table'
import DueDateField from './DueDateField'
import ModalForDisapprovalReason from './ModalForDisapprovalReason'
import ButtonColumn from './ButtonColumn'
import FileStatusCell from './FileStatusCell'
import RequestedFileCell from './RequestedFileCell'
import FileInstancesModal from '../FileInstancesModal'

import useGetAccessibleUrl from '../../../../../../features/hooks/useGetAccessibleUrl'
import {
  useGetUpdatingId,
  useHandleDeleteUploadFile,
  useOpenFileForm,
  useGetDropdownOptions,
  useGetUpdatingIds
} from './hooks'
import { getStatusForDisapprovalAction } from '../../../../../../features/components/UploadedFilesField/helpers'

import { updateBookedMediaFileWasUpdatedSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'
import { clearUpdateBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector } from '../../../../../../modules/selectors/app'

import { formatDateFullYear } from '../../../../../../constants/dates'

import { tableColumnsSize } from '../index'
import { DISAPPROVED_REASON } from '../../../../../../features/components/Modals/AskReasonModal/fields'

const RequestedFilesTableData = ({ files }) => {
  const dispatch = useDispatch()

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  const [showDisapprovalReasonModal, setShowDisapprovalReasonModal] = useState(false)
  const [showFileInstancesModal, setShowFileInstancesModal] = useState(false)
  const [fileInstancesModalData, setFileInstancesModalData] = useState({
    status: '',
    uploadedFile: {},
    [DISAPPROVED_REASON]: ''
  })

  const [selectedRequirementId, setSelectedRequirementId] = useState('')
  const [selectedUploadedFile, setSelectedUploadedFile] = useState({})

  const { handleFileDownloading } = useGetAccessibleUrl()

  const bookedMediaFileWasUpdated = useSelector(updateBookedMediaFileWasUpdatedSelector)

  const handleFileDownload = useCallback(
    uploadedFile => () => {
      handleFileDownloading(uploadedFile?.file_url)
    },
    [handleFileDownloading]
  )

  const handleShowModal = (requirementId, uploadedFile) => () => {
    setShowDisapprovalReasonModal(true)
    setSelectedRequirementId(requirementId)
    setSelectedUploadedFile(uploadedFile)
  }

  const handleCloseModal = useCallback(() => {
    setShowDisapprovalReasonModal(false)
    setSelectedRequirementId('')
    setSelectedUploadedFile({})
  }, [])

  const handleCloseFileInstancesModal = useCallback(() => {
    setShowFileInstancesModal(false)
  }, [])

  const openFileInstancesModal = useCallback(({ status, uploadedFile }) => {
    setShowFileInstancesModal(true)
    setFileInstancesModalData({
      status,
      uploadedFile
    })
  }, [])

  const openFileInstancesModalFromDisapprovalReasonModal = useCallback(
    ({ uploadedFile, [DISAPPROVED_REASON]: disapprovedReason }) => {
      const disapprovalActionStatus = getStatusForDisapprovalAction(uploadedFile, isControllerBuyerApproval)

      setShowDisapprovalReasonModal(false)
      setShowFileInstancesModal(true)
      setFileInstancesModalData({
        status: disapprovalActionStatus,
        uploadedFile,
        [DISAPPROVED_REASON]: disapprovedReason
      })
    },
    [isControllerBuyerApproval, setShowDisapprovalReasonModal, setShowFileInstancesModal, setFileInstancesModalData]
  )

  const getItemUpdatingId = useGetUpdatingId()
  const updatingIds = useGetUpdatingIds(files)

  const openUploadFileForm = useOpenFileForm()

  const handleDelete = useHandleDeleteUploadFile()

  const getDropdownOptions = useGetDropdownOptions({
    handleDelete,
    openUploadFileForm,
    handleFileDownload,
    handleShowModal,
    openFileInstancesModal
  })

  const requestedFilesColumns = useMemo(
    () => [
      {
        header: 'Requested file',
        Cell: ({ required: requirements, uploaded: uploadedFile, requirementFileId: id, mediaId }) => {
          return (
            <RequestedFileCell
              requirements={requirements}
              uploadedFile={uploadedFile}
              id={id}
              mediaId={mediaId}
              handleFileDownloading={handleFileDownloading}
            />
          )
        }
      },
      {
        header: 'Period Start',
        Cell: ({ dateStart }) => formatDateFullYear(dateStart),
        style: { maxWidth: tableColumnsSize.mediaPeriod }
      },
      {
        header: 'Status',
        Cell: ({ uploaded: uploadedFile, requirementFileId }) => (
          <FileStatusCell
            uploadedFile={uploadedFile}
            requirementFileId={requirementFileId}
            handleShowModal={handleShowModal}
            openFileInstancesModal={openFileInstancesModal}
          />
        ),
        style: { width: tableColumnsSize.status, minWidth: tableColumnsSize.status }
      },
      {
        header: 'Due date',
        Cell: ({ deadline, uploaded: uploadedFile }) => (!uploadedFile ? <DueDateField deadline={deadline} /> : ''),
        style: { maxWidth: tableColumnsSize.dueDate }
      },
      {
        Cell: ({ requirementFileId, mediaId, uploaded: uploadedFile, deadline }) => (
          <ButtonColumn
            requirementFileId={requirementFileId}
            uploadedFile={uploadedFile}
            mediaId={mediaId}
            deadline={deadline}
            handleDelete={handleDelete}
            openUploadFileForm={openUploadFileForm}
          />
        ),
        style: { maxWidth: tableColumnsSize.uploadButton }
      },
      {
        Cell: ({ mediaId, requirementFileId, uploaded: uploadedFile }) => {
          return (
            <ActionsDropdown
              itemId={requirementFileId}
              options={getDropdownOptions({ requirementFileId, mediaId, uploadedFile, openFileInstancesModal })}
            />
          )
        },
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    [openUploadFileForm, handleFileDownloading, getDropdownOptions, handleDelete, openFileInstancesModal]
  )

  useEffect(() => {
    if (bookedMediaFileWasUpdated) {
      dispatch(clearUpdateBookingMediaFile())
    }
  }, [dispatch, bookedMediaFileWasUpdated])

  return (
    <>
      <Table
        hideFooterRow
        data={files}
        cols={requestedFilesColumns}
        itemUpdatingId={getItemUpdatingId}
        updatingIds={updatingIds}
      />

      {showDisapprovalReasonModal && (
        // Don't render modal if it's not open, to reset the form on close
        <ModalForDisapprovalReason
          onSubmit={openFileInstancesModalFromDisapprovalReasonModal}
          requirementFileId={selectedRequirementId}
          uploadedFile={selectedUploadedFile}
          onClose={handleCloseModal}
          isOpen={showDisapprovalReasonModal}
        />
      )}

      <FileInstancesModal
        isOpen={showFileInstancesModal}
        onClose={handleCloseFileInstancesModal}
        fileInstancesModalData={fileInstancesModalData}
        setShowFileInstancesModal={setShowFileInstancesModal}
        setFileInstancesModalData={setFileInstancesModalData}
      />
    </>
  )
}

RequestedFilesTableData.propTypes = {
  files: PropTypes.array.isRequired
}

export default RequestedFilesTableData
