import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import HighlightedValueSelect from '../../../../components/HighlightedValueSelect'

import { formatOptionsList } from '../../../formatters'

import { clearGetProductTags, getProductTags } from '../../../../modules/actions/tags'
import { mediaProductTagsParams } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderAmend/MediaOrderAmendForm/AmendmentContractCreateForm/AddNewProductModal/ProductSelection/MediaProductsFilters'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { productTagsIsLoadingSelector, productTagsSelector } from '../../../../modules/selectors/tags'

export const SELECTED_TAG = 'selectedTag'

const ProductTagsFilter = ({ Context, placeholder = 'Filter' }) => {
  const dispatch = useDispatch()

  const tags = useSelector(productTagsSelector)
  const productTagsIsLoading = useSelector(productTagsIsLoadingSelector)

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_TAG]: selectedTag } = filtersState

  const tagsOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const setSelectedTag = useCallback(
    newTag => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_TAG]: newTag
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const handleChangeFilter = useCallback(
    option => {
      setSelectedTag(option?.value)
    },
    [setSelectedTag]
  )

  useEffect(() => {
    dispatch(getProductTags({ ...mediaProductTagsParams, controller: selfAccountControllerId }))
  }, [dispatch, selfAccountControllerId])

  useEffect(
    () => () => {
      dispatch(clearGetProductTags())
    },
    [dispatch]
  )

  return (
    <HighlightedValueSelect
      value={selectedTag}
      onChange={handleChangeFilter}
      options={tagsOptions}
      placeholder={placeholder}
      isSmall
      isLoading={productTagsIsLoading}
      portaled={true}
    />
  )
}

ProductTagsFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  placeholder: PropTypes.string
}

export default ProductTagsFilter
