import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  errorMessage: {
    whiteSpace: 'normal'
  },
  disapprovedChipContainer: {
    display: 'flex',
    flexDirection: 'column',
    // add padding top to align 'Disapproved' chip close to center of the cell
    paddingTop: 18
  }
})

export default useStyles
