import store from '../../store'
import * as Sentry from '@sentry/react'

import { currentUserIdSelector } from '../../modules/selectors/app'

export const captureErrorToSentry = ({ message, status, method, otherProps, data, params, error, toast }) => {
  const userId = currentUserIdSelector(store.getState())

  // skip network errors to avoid spam of errors
  const isNetworkError = error?.code === 'ERR_NETWORK'
  // skip requests with 401 status when user auth is not set
  const isAuthError = status === 401
  // skip token expiration errors
  const isTokenExpired = error?.message === 'Your session has expired. Please log in again.'
  const skipErrorsTracking = isNetworkError || isTokenExpired || isAuthError

  if (!skipErrorsTracking) {
    // throw all api errors
    Sentry.captureException(message, {
      user: {
        id: userId
      },
      extra: {
        method,
        status,
        url: otherProps.url,
        otherProps,
        dataPayload: JSON.stringify(data, null, 2),
        paramsPayload: params,
        error: error,
        response: error.response,
        responseData: JSON.stringify(error.response?.data, null, 2),
        toast
      }
    })
  }
}

export const captureBulkUpdateBookingMediaFileError = ({ installationReportItem, bookedMediaData, error }) => {
  const userId = currentUserIdSelector(store.getState())

  const data = {
    installationReportItem,
    bookedMediaData,
    error
  }

  Sentry.captureException('Error bulk updating booking media file', {
    user: {
      id: userId
    },
    extra: {
      data: JSON.stringify(data, null, 2)
    }
  })
}
