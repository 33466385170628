import { CHIP_COLORS } from './other'

export const bookedMediaStatuses = {
  awaiting_creatives: 'awaiting_creatives',
  awaiting_set_up: 'awaiting_set_up',
  pending_approval: 'pending_approval',
  creative_updated: 'creative_updated',
  scheduled: 'scheduled',
  remove_creative: 'remove_creative',
  pause_request: 'pause_request',
  paused: 'paused',
  creative_not_supplied: 'creative_not_supplied'
}

export const bookedMediaStatusesLabels = {
  awaiting_creatives: 'Awaiting Creatives',
  awaiting_set_up: 'Awaiting Set Up',
  pending_approval: 'Pending Approval',
  creative_updated: 'Creative Updated',
  scheduled: 'Scheduled',
  remove_creative: 'Remove Creative',
  pause_request: 'Pause Request',
  paused: 'Paused',
  cancelled: 'Canceled',
  creative_not_supplied: 'Creative Not Supplied'
}

export const bookedMediaStatusesArray = Object.keys(bookedMediaStatuses)

export const formattedStatusesOptions = bookedMediaStatusesArray.map(item => {
  return { value: item, label: bookedMediaStatusesLabels[item] }
})

export const statusColors = {
  awaiting_creatives: CHIP_COLORS.grey,
  awaiting_set_up: CHIP_COLORS.purple,
  pending_approval: CHIP_COLORS.yellow,
  creative_updated: CHIP_COLORS.purple,
  scheduled: CHIP_COLORS.green,
  remove_creative: CHIP_COLORS.purple,
  pause_request: CHIP_COLORS.purple,
  paused: CHIP_COLORS.yellow,
  cancelled: CHIP_COLORS.red,
  creative_not_supplied: CHIP_COLORS.yellow
}
