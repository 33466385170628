import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import RadioBlock from '../../../../../components/Form/RadioBlock'

import {
  SHOPPER_TYPE_OPTION,
  BRAND_SHOPPERS,
  CATEGORY_SHOPPERS,
  LIFESTYLE_CATEGORIES,
  COMPETITOR_BUYERS,
  LAPSED_CUSTOMERS
} from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const ShopperTypeStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const { [SHOPPER_TYPE_OPTION]: shopperTypeOption } = values

  const handleContinue = () => {
    // temporary limits
    if (
      shopperTypeOption === BRAND_SHOPPERS ||
      shopperTypeOption === CATEGORY_SHOPPERS ||
      shopperTypeOption === LIFESTYLE_CATEGORIES
    ) {
      handleStepChange()
    }
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Which type of shopper do you want to target?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_brand_shoppers"
          name={SHOPPER_TYPE_OPTION}
          value={BRAND_SHOPPERS}
          selectedValue={values[SHOPPER_TYPE_OPTION]}
          label={t('Brand Shoppers')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_category_shoppers"
          name={SHOPPER_TYPE_OPTION}
          value={CATEGORY_SHOPPERS}
          selectedValue={values[SHOPPER_TYPE_OPTION]}
          label={t('Category Shoppers')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_competitor_buyers"
          name={SHOPPER_TYPE_OPTION}
          value={COMPETITOR_BUYERS}
          selectedValue={values[SHOPPER_TYPE_OPTION]}
          label={t('Competitor Buyers')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_lapsed_customers"
          name={SHOPPER_TYPE_OPTION}
          value={LAPSED_CUSTOMERS}
          selectedValue={values[SHOPPER_TYPE_OPTION]}
          label={t('Lapsed Customers')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_lifestyle_categories"
          name={SHOPPER_TYPE_OPTION}
          value={LIFESTYLE_CATEGORIES}
          selectedValue={values[SHOPPER_TYPE_OPTION]}
          label={t('Lifestyle Categories')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default ShopperTypeStep
