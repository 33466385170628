import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isValid } from 'date-fns'

import { useLoadPaginatedMediaOrders } from '../../../../MediaOrders/hooks'
import { formatSetupFilters } from '../../../../LandingPage/ControllerLandingPageContent/ManagementReporting/Dashboard/helpers'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { validMediaOrdersStatuses } from '../../../../../constants/mediaOrders'
import { formatDateToBE, initialDatePeriod } from '../../../../../constants/dates'
import { DATE_RANGE_FILTER } from '../../../../../features/components/DropdownFilters/components/DateFilter'
import { CREATED_DATE_RANGE_FILTER } from '../index'
import { MULTIPLE_CONTROLLER_MEMBERS_FILTER } from '../../../../../features/components/DropdownFilters/components/MultipleControllerMembersFilter'
import { MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER } from '../../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'

export const BookingsFiltersContext = React.createContext()

export function BookingsDataProvider({ children }) {
  const controllerId = useSelector(selectedControllerIdSelector)

  const [filtersState, setFiltersState] = useState({
    [DATE_RANGE_FILTER]: initialDatePeriod,
    [CREATED_DATE_RANGE_FILTER]: initialDatePeriod,
    [MULTIPLE_CONTROLLER_MEMBERS_FILTER]: [],
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: []
  })

  const {
    [DATE_RANGE_FILTER]: dateRangeFilter,
    [CREATED_DATE_RANGE_FILTER]: createdDateRangeFilter,
    [MULTIPLE_CONTROLLER_MEMBERS_FILTER]: multipleControllerMembersFilter,
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: multipleAccountFilterOption
  } = filtersState

  const filterRequestParams = useMemo(() => {
    const createdStartDate = createdDateRangeFilter.startDate
    const createdEndDate = createdDateRangeFilter.endDate
    const startDate = dateRangeFilter.startDate
    const endDate = dateRangeFilter.endDate

    return {
      controller: controllerId,
      status: validMediaOrdersStatuses,
      // account
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption.map(option => option.value) }),
      // created
      ...(createdStartDate &&
        isValid(createdStartDate) && {
          created_after: formatDateToBE(new Date(createdStartDate))
        }),
      // createdEndDate could be Invalid Date, for this reason we need to check if it's valid
      ...(createdEndDate &&
        isValid(createdEndDate) && {
          created_before: formatDateToBE(new Date(createdEndDate))
        }),
      // start_date
      ...(startDate &&
        isValid(startDate) && {
          start_date_after: formatDateToBE(new Date(startDate))
        }),
      // endDate could be Invalid Date, for this reason we need to check if it's valid
      ...(endDate &&
        isValid(endDate) && {
          start_date_before: formatDateToBE(new Date(endDate))
        }),
      // created_by
      ...(multipleControllerMembersFilter?.length && {
        created_by: multipleControllerMembersFilter.map(option => option.value)
      })
    }
  }, [
    multipleAccountFilterOption,
    controllerId,
    createdDateRangeFilter,
    dateRangeFilter,
    multipleControllerMembersFilter
  ])

  const paginatedMediaOrdersParams = useMemo(() => formatSetupFilters(filterRequestParams), [filterRequestParams])

  const loadMoreMediaOrdersHandler = useLoadPaginatedMediaOrders(paginatedMediaOrdersParams)

  const bookingsFilterProps = useMemo(() => {
    return {
      filterRequestParams,
      loadMoreMediaOrdersHandler,
      filtersState,
      setFiltersState
    }
  }, [filterRequestParams, loadMoreMediaOrdersHandler, filtersState])

  return <BookingsFiltersContext.Provider value={bookingsFilterProps}>{children}</BookingsFiltersContext.Provider>
}
