import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaProductLocationEditForm from './MediaProductLocationEditForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { MEDIA_PRODUCT_LOCATION_EDIT } from '../../../../constants/forms'
import {
  mediaProductLocationCategoriesWasLoadedSelector,
  mediaProductLocationRegionsWasLoadedSelector,
  mediaProductLocationSelector,
  mediaProductLocationWasLoadedSelector
} from '../../../../modules/selectors/mediaOrdersProductLocations'
import { controllerWasLoadedSelector } from '../../../../modules/selectors/controller'
import { clearGetController, getController } from '../../../../modules/actions/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import {
  clearGetMediaProductLocation,
  clearGetMediaProductLocationCategories,
  clearGetMediaProductLocationRegions,
  getMediaProductLocation,
  getMediaProductLocationCategories,
  getMediaProductLocationRegions
} from '../../../../modules/actions/mediaOrdersProductLocations'

const MediaProductLocationEdit = () => {
  const dispatch = useDispatch()
  const controllerWasLoaded = useSelector(controllerWasLoadedSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const mediaProductLocation = useSelector(mediaProductLocationSelector)
  const mediaProductLocationWasLoaded = useSelector(mediaProductLocationWasLoadedSelector)
  const mediaProductLocationCategoriesWasLoaded = useSelector(mediaProductLocationCategoriesWasLoadedSelector)
  const mediaProductLocationRegionsWasLoaded = useSelector(mediaProductLocationRegionsWasLoadedSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: MEDIA_PRODUCT_LOCATION_EDIT
  })

  const isFormLoading = useMemo(
    () =>
      !mediaProductLocation ||
      !mediaProductLocationWasLoaded ||
      !controllerWasLoaded ||
      !mediaProductLocationCategoriesWasLoaded ||
      !mediaProductLocationRegionsWasLoaded,
    [
      mediaProductLocation,
      mediaProductLocationWasLoaded,
      controllerWasLoaded,
      mediaProductLocationCategoriesWasLoaded,
      mediaProductLocationRegionsWasLoaded
    ]
  )

  const getDataHandler = useCallback(() => {
    dispatch(getController({ id: selectedControllerId }))
    dispatch(getMediaProductLocation({ id: selectedEditItemId }))
    dispatch(
      getMediaProductLocationCategories({
        controller: selectedControllerId,
        ordering: 'name',
        limit: 999
      })
    )
    dispatch(
      getMediaProductLocationRegions({
        controller: selectedControllerId,
        ordering: 'name',
        limit: 999
      })
    )
  }, [dispatch, selectedControllerId, selectedEditItemId])

  const clearDataHandler = useCallback(() => {
    dispatch(clearGetController())
    dispatch(clearGetMediaProductLocation())
    dispatch(clearGetMediaProductLocationCategories())
    dispatch(clearGetMediaProductLocationRegions())
  }, [dispatch])

  useManageEditFormData({
    formName: MEDIA_PRODUCT_LOCATION_EDIT,
    loadedDataId: mediaProductLocation.id,
    getDataHandler,
    clearDataHandler
  })

  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_LOCATION_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media product location"
      showOpenButton={false}
    >
      <MediaProductLocationEditForm editProductLocationData={mediaProductLocation} />
    </FormDrawerWrapper>
  )
}

export default MediaProductLocationEdit
