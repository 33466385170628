import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'

import { selfAccountSubCategoriesSelector } from '../../../../../modules/selectors/selfAccounts'

export const BRAND_SUB_CATEGORY = 'brandSubCategory'

export const BrandSubCategoryFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [BRAND_SUB_CATEGORY]: brandSubCategory } = filtersState

  const setBrandSubCategory = useCallback(
    newBrandSubCategory => {
      const updatedFiltersState = {
        ...filtersState,
        [BRAND_SUB_CATEGORY]: newBrandSubCategory
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const selfAccountSubCategories = useSelector(selfAccountSubCategoriesSelector)

  const formattedSelfAccountSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountSubCategories])

  const selectBrandSubCategoryHandler = useCallback(
    data => {
      setBrandSubCategory(data)
    },
    [setBrandSubCategory]
  )

  const setSelectBrandSubCategoryHandler = useCallback(
    (name, data) => {
      selectBrandSubCategoryHandler(data)
    },
    [selectBrandSubCategoryHandler]
  )

  return (
    <MultiSelectBox
      name="Brand Sub Category"
      options={formattedSelfAccountSubCategoriesOptions}
      value={brandSubCategory}
      onChange={selectBrandSubCategoryHandler}
      placeholder="Brand Sub Category"
      setFieldValue={setSelectBrandSubCategoryHandler}
      portaled
    />
  )
}

BrandSubCategoryFilter.propTypes = {
  Context: PropTypes.object.isRequired
}
