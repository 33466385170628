import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-jss'

import ActionText from '../../../../../../../../components/ActionText'

import { SELECTED_FILES } from '../../../fields'

import useStyles from './styles'

const SelectOrDeselectAllButton = ({ formik, uploadedFiles }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const theme = useTheme()

  const customColorSchema = useMemo(() => {
    return {
      color: theme.brandPrimary,
      hoverColor: theme.brandPrimaryHover,
      touchedColor: theme.brandPrimaryHover
    }
  }, [theme])

  const filesWithoutComment = useMemo(() => {
    return uploadedFiles.filter(file => !file.comment)
  }, [uploadedFiles])

  const isAllSelected = useMemo(() => {
    return filesWithoutComment.every(file => {
      return values[SELECTED_FILES][file.id]
    })
  }, [filesWithoutComment, values])

  const selectAllFilesHandler = useCallback(() => {
    const updatedSelectedFiles = filesWithoutComment.reduce((result, file) => {
      result[file.id] = true
      return result
    }, {})

    setFieldValue(SELECTED_FILES, updatedSelectedFiles)
  }, [setFieldValue, filesWithoutComment])

  const deselectAllFilesHandler = useCallback(() => {
    const updatedSelectedFiles = filesWithoutComment.reduce((result, file) => {
      result[file.id] = false
      return result
    }, {})

    setFieldValue(SELECTED_FILES, updatedSelectedFiles)
  }, [setFieldValue, filesWithoutComment])

  return (
    <>
      {!isAllSelected && (
        <ActionText
          customColorSchema={customColorSchema}
          onClick={selectAllFilesHandler}
          className={classes.mainSelectOrDeselectAllButton}
        >
          {t('Select All')}
        </ActionText>
      )}

      {isAllSelected && (
        <ActionText
          customColorSchema={customColorSchema}
          onClick={deselectAllFilesHandler}
          className={classes.mainSelectOrDeselectAllButton}
        >
          {t('Deselect All')}
        </ActionText>
      )}
    </>
  )
}

SelectOrDeselectAllButton.propTypes = {
  formik: PropTypes.object,
  uploadedFiles: PropTypes.array
}

export default SelectOrDeselectAllButton
