import * as Yup from 'yup'

import { checkIsPdfOnly } from './helpers'

import { FILE_URL, FILE_SELECTED_URL, FILE_SELECT_EXISTING } from './fields'
import { FILE_UPLOAD, FILE_UPLOAD_OPTION } from './fields'
import {
  MEDIA_SIZE,
  FILE_TYPE,
  MEDIA_HEIGHT,
  MEDIA_RATIO,
  MEDIA_WIDTH
} from '../../../../ReusableFormFields/AdFileUpload/fields'
import { MEDIA_PHYSICAL_HEIGHT, MEDIA_PHYSICAL_WIDTH } from '../../../../Facebook/AdForms/fields'

export const validationSchema = Yup.object({
  [FILE_URL]: Yup.string().when(FILE_UPLOAD_OPTION, {
    is: FILE_UPLOAD,
    then: () => Yup.string().required('Please select file')
  }),
  [FILE_SELECTED_URL]: Yup.string().when(FILE_UPLOAD_OPTION, {
    is: FILE_SELECT_EXISTING,
    then: () => Yup.string().required('Please select file')
  })
})

const getWidthValidation = ({ fieldName = MEDIA_WIDTH, minWidth, maxWidth, width, dimension = 'px' }) => {
  const validationRow = {
    [fieldName]: Yup.number()
  }
  if (width) {
    const formattedRequirement = Number(width)
    validationRow[fieldName] = validationRow[fieldName].test(
      '',
      `The file width should be ${formattedRequirement}${dimension}`,
      val => val === formattedRequirement
    )
  }
  if (minWidth) {
    const formattedRequirement = Number(minWidth)
    validationRow[fieldName] = validationRow[fieldName].min(
      Number(formattedRequirement),
      `The minimum supported file width is ${formattedRequirement}${dimension}`
    )
  }
  if (maxWidth) {
    const formattedRequirement = Number(maxWidth)
    validationRow[fieldName] = validationRow[fieldName].max(
      formattedRequirement,
      `The maximum supported file width is ${formattedRequirement}${dimension}`
    )
  }
  return validationRow
}

const getHeightValidation = ({ fieldName = MEDIA_HEIGHT, minHeight, maxHeight, height, dimension = 'px' }) => {
  const validationRow = {
    [fieldName]: Yup.number()
  }
  if (height) {
    const formattedRequirement = Number(height)
    validationRow[fieldName] = validationRow[fieldName].test(
      '',
      `The file height should be ${formattedRequirement}${dimension}`,
      val => val === formattedRequirement
    )
  }
  if (minHeight) {
    const formattedRequirement = Number(minHeight)
    validationRow[fieldName] = validationRow[fieldName].min(
      formattedRequirement,
      `The minimum supported file height is ${formattedRequirement}${dimension}`
    )
  }
  if (maxHeight) {
    const formattedRequirement = Number(maxHeight)
    validationRow[fieldName] = validationRow[fieldName].max(
      formattedRequirement,
      `The maximum supported file height is ${formattedRequirement}${dimension}`
    )
  }
  return validationRow
}

const getRatioValidation = ({ minRatio, maxRatio }) => {
  const validationRow = {
    [MEDIA_RATIO]: Yup.number()
  }
  if (minRatio) {
    validationRow[MEDIA_RATIO] = validationRow[MEDIA_RATIO].min(
      minRatio,
      `The minimum supported file ratio is ${minRatio}`
    )
  }
  if (maxRatio) {
    validationRow[MEDIA_RATIO] = validationRow[MEDIA_RATIO].max(
      maxRatio,
      `The maximum supported file ratio is ${maxRatio}`
    )
  }
  return validationRow
}

const getSizeValidation = ({ minSize, maxSize }) => {
  const maxSizeInBits = Number(maxSize) * 1024 * 1024
  const minSizeInBits = Number(minSize) * 1024 * 1024
  const validationRow = {
    [MEDIA_SIZE]: Yup.number()
  }
  if (minSize) {
    validationRow[MEDIA_SIZE] = validationRow[MEDIA_SIZE].min(
      minSizeInBits,
      `The minimum supported file size is ${Number(minSize)}Mb`
    )
  }
  if (maxSize) {
    validationRow[MEDIA_SIZE] = validationRow[MEDIA_SIZE].max(
      maxSizeInBits,
      `The maximum supported file size is ${Number(maxSize)}Mb`
    )
  }
  return validationRow
}

const getSupportedTypes = supportedFileTypes => {
  const fileFormatPairs = {
    jpg: 'jpeg',
    jpeg: 'jpg'
    // add other pairs here
  }

  if (supportedFileTypes?.length) {
    Object.entries(fileFormatPairs).forEach(([key, value]) => {
      // add the value to the supportedFileTypes if it is not already there
      if (supportedFileTypes.includes(key) && !supportedFileTypes.includes(value)) {
        supportedFileTypes.push(value)
      }
    })

    return {
      [FILE_TYPE]: Yup.string().oneOf(
        supportedFileTypes,
        `The file should be: ${supportedFileTypes?.length > 1 ? supportedFileTypes.join(',') : supportedFileTypes[0]}`
      )
    }
  }
  return {}
}

export const getFileValidationSchema = fileRequirements => {
  const {
    height,
    max_file_size: maxSize,
    max_height: maxHeight,
    max_width: maxWidth,
    min_file_size: minSize,
    min_height: minHeight,
    min_width: minWidth,
    ratio_from: ratioFrom,
    ratio_to: ratioTo,
    supported_file_types: supportedFileTypes,
    width
  } = fileRequirements

  const isPdfOnly = checkIsPdfOnly(supportedFileTypes)

  return Yup.object().shape({
    ...((maxWidth || minWidth || width) &&
      getWidthValidation({
        fieldName: isPdfOnly ? MEDIA_PHYSICAL_WIDTH : MEDIA_WIDTH,
        minWidth,
        maxWidth,
        width,
        dimension: isPdfOnly ? 'mm' : 'px'
      })),
    ...((minHeight || maxHeight || height) &&
      getHeightValidation({
        fieldName: isPdfOnly ? MEDIA_PHYSICAL_HEIGHT : MEDIA_HEIGHT,
        minHeight,
        maxHeight,
        height,
        dimension: isPdfOnly ? 'mm' : 'px'
      })),
    // create new supportedFileTypes to avoid data mutation
    ...(supportedFileTypes && getSupportedTypes([...supportedFileTypes])),
    ...((ratioFrom || ratioTo) && getRatioValidation({ minRatio: ratioFrom, maxRatio: ratioTo })),
    ...((minSize || maxSize) && getSizeValidation({ minSize, maxSize }))
  })
}
