export const COMMENT = 'comment'
export const SELECTED_FILES = 'selectedFiles'

export const getInitialValues = (uploadedFiles, selectedFileId) => {
  const selectedUploadedFile = uploadedFiles.find(file => file.id === selectedFileId)

  return {
    [COMMENT]: selectedUploadedFile.comment || '',
    [SELECTED_FILES]: {
      [selectedFileId]: true
    }
  }
}
