import React from 'react'
import { useTranslation } from 'react-i18next'

import AllCampaignsMargin from '../../ReusableFormFields/MarginFields/AllCampaignsMargin'
import Button from '../../../components/Button'

import { MARGIN_OPTION, GENERAL_MARGIN, MARGINS } from '../../ReusableFormFields/MarginFields/fields'
import { FACEBOOK_PLATFORM } from '../../../constants/selectLists/platformList'

import useCommonStyles from '../../../styles/common/stepForms'

const stepFields = [MARGIN_OPTION, MARGINS, `${MARGINS}.${FACEBOOK_PLATFORM}`, GENERAL_MARGIN]

function AllCampaignsMarginStep({ formik, handleStepChange, subTitle }) {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const handleContinue = () => {
    handleStepChange(stepFields, formik.values)
  }

  return (
    <>
      <AllCampaignsMargin formik={formik} subTitle={subTitle} />
      <div className={commonClasses.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default AllCampaignsMarginStep
