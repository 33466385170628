import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import Regions from '../../../../../../../../../ReusableFormFields/GeolocationsFields/Regions'
import Locations from '../../../../../../../../../ReusableFormFields/GeolocationsFields/Locations'
import Button from '../../../../../../../../../../components/Button'

import { formatOptionsList } from '../../../../../../../../../../features/formatters'
import usePreselectUserCountry from '../../../../../../../../../../features/hooks/usePreselectUserCountry'

import {
  choicesCountriesSelector,
  choicesCountriesWasLoadedSelector
} from '../../../../../../../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector } from '../../../../../../../../../../modules/selectors/app'
import { getChoicesCountries } from '../../../../../../../../../../modules/actions/choices'

import {
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_REGIONS
} from '../../../../../../../../../ReusableFormFields/GeolocationsFields/fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../../../../constants/selectLists/platformList'

import useStyles from '../../../../../../../../../../styles/common/stepForms'

const stepFields = [SHOW_COUNTRIES, SHOW_REGIONS, LOCATION_LIST]

const GeolocationsStep = ({ formik, handleStepChange }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { t } = useTranslation()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const countries = useSelector(choicesCountriesSelector)
  const countriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)

  const formattedCountries = useMemo(
    () =>
      formatOptionsList({
        list: countries,
        labelName: 'name',
        valueName: 'id'
      }),
    [countries]
  )
  usePreselectUserCountry({ formik, formattedCountries })

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  useEffect(() => {
    if (!countriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, countriesWasLoaded])

  return (
    <>
      <div className={classes.stepTitle}>{t('Where would you like the ads to be shown?')}</div>
      <div className={classes.stepBody}>
        <Locations formik={formik} />
        <Regions
          formik={formik}
          showRegionsCountryFilter={true}
          formattedCountries={formattedCountries}
          adAccountId={selectedAdAccountId}
          platform={FACEBOOK_PLATFORM}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default GeolocationsStep
