export const isFilterSelected = (filtersState, filter) => {
  if (filter.hasOwnProperty('getIsFilterSelected')) {
    // If filter has its own helper to determine if it's initially open - use it
    return filter.getIsFilterSelected(filtersState, filter)
  } else {
    // otherwise (if it's array or string) - check if it's not empty
    return !!filtersState[filter.filterName]?.length
  }
}

export const sortFiltersByUsage = (filtersState, filters) => {
  // Then if there are any filters that are in use (i.e. there is a selection and it’s possible to “x” the filter)
  // then show those at the top of the list of filters

  return [...filters].sort((a, b) => {
    const aInUse = isFilterSelected(filtersState, a)
    const bInUse = isFilterSelected(filtersState, b)

    if (aInUse && !bInUse) {
      return -1
    }

    if (!aInUse && bInUse) {
      return 1
    }

    return 0
  })
}
