import { compose, createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { fork } from 'redux-saga/effects'

// import selectors setup
import './modules/selectors'

import adAccounts from './modules/reducers/adAccounts'
import ads from './modules/reducers/ads'
import agencies from './modules/reducers/agencies'
import agencyTeam from './modules/reducers/agencyTeam'
import app from './modules/reducers/app'
import assets from './modules/reducers/assets'
import audiences from './modules/reducers/audiences'
import auth from './modules/reducers/auth'
import automationRules from './modules/reducers/automationRules'
import amendments from './modules/reducers/amendments'
import batchInfo from './modules/reducers/batchInfo'
import calendarPlanner from './modules/reducers/calendarPlanner'
import campaigns from './modules/reducers/campaigns'
import campaignsSummary from './modules/reducers/campaignsSummary'
import choices from './modules/reducers/choices'
import cognito from './modules/reducers/cognito'
import contracts from './modules/reducers/contracts'
import combinedData from './modules/reducers/combinedData'
import controller from './modules/reducers/controller'
import discounts from './modules/reducers/discounts'
import doohly from './modules/reducers/doohly'
import dv360Constants from './modules/reducers/dv360Constants'
import files from './modules/reducers/files'
import forms from './modules/reducers/forms'
import googleConstants from './modules/reducers/googleConstants'
import lineItems from './modules/reducers/lineItems'
import lineItemsSummary from './modules/reducers/lineItemsSummary'
import location from './modules/reducers/location'
import margins from './modules/reducers/margins'
import mediaFiles from './modules/reducers/mediaFiles'
import mediaOrderOptimisations from './modules/reducers/mediaOrderOptimisations'
import mediaOrders from './modules/reducers/mediaOrders'
import mediaOrdersBookings from './modules/reducers/mediaOrdersBookings'
import mediaOrdersProducts from './modules/reducers/mediaOrdersProducts'
import mediaOrdersProductLocations from './modules/reducers/mediaOrdersProductLocations'
import mediaOrdersProductVariables from './modules/reducers/mediaOrdersProductVariables'
import mediaPackages from './modules/reducers/mediaPackages'
import quotations from './modules/reducers/quotations'
import selfAccounts from './modules/reducers/selfAccounts'
import socialAccounts from './modules/reducers/socialAccounts'
import statements from './modules/reducers/statements'
import tags from './modules/reducers/tags'
import selfAccountTeam from './modules/reducers/selfAccountTeam'
import tikTokConstants from './modules/reducers/tikTokConstants'
import tools from './modules/reducers/tools'
import triggers from './modules/reducers/triggers'

import adAccountsWatcher from './modules/sagas/adAccounts'
import adsWatcher from './modules/sagas/ads'
import agenciesWatcher from './modules/sagas/agencies'
import agencyTeamWatcher from './modules/sagas/agencyTeam'
import appWatcher from './modules/sagas/app'
import assetsWatcher from './modules/sagas/assets'
import audiencesWatcher from './modules/sagas/audiences'
import authWatcher from './modules/sagas/auth'
import automationRulesWatcher from './modules/sagas/automationRules'
import amendmentsWatcher from './modules/sagas/amendments'
import batchInfoWatcher from './modules/sagas/batchInfo'
import calendarPlannerWatcher from './modules/sagas/calendarPlanner'
import campaignsSummaryWatcher from './modules/sagas/campaignsSummary'
import campaignsWatcher from './modules/sagas/campaigns'
import choicesWatcher from './modules/sagas/choices'
import cognitoWatcher from './modules/sagas/cognito'
import contractsWatcher from './modules/sagas/contracts'
import dv360ConstantsWatcher from './modules/sagas/dv360Constants'
import discountsWatcher from './modules/sagas/discounts'
import doohlyWatcher from './modules/sagas/doohly'
import combinedDataWatcher from './modules/sagas/combinedData'
import controllerWatcher from './modules/sagas/controller'
import filesWatcher from './modules/sagas/files'
import googleConstantsWatcher from './modules/sagas/googleConstants'
import lineItemsSummaryWatcher from './modules/sagas/lineItemsSummary'
import lineItemsWatcher from './modules/sagas/lineItems'
import locationWatcher from './modules/sagas/location'
import quotationsWatcher from './modules/sagas/quotations'
import marginsWatcher from './modules/sagas/margins'
import mediaFilesWatcher from './modules/sagas/mediaFiles'
import mediaOrderOptimisationsWatcher from './modules/sagas/mediaOrderOptimisations'
import mediaOrdersWatcher from './modules/sagas/mediaOrders'
import mediaOrdersBookingsWatcher from './modules/sagas/mediaOrdersBookings'
import mediaOrdersProductsWatcher from './modules/sagas/mediaOrdersProducts'
import mediaOrdersProductLocationsWatcher from './modules/sagas/mediaOrdersProductLocations'
import mediaOrdersProductVariablesWatcher from './modules/sagas/mediaOrdersProductVariables'
import mediaPackagesWatcher from './modules/sagas/mediaPackages'
import selfAccountsWatcher from './modules/sagas/selfAccounts'
import socialAccountsWatcher from './modules/sagas/socialAccounts'
import statementsWatcher from './modules/sagas/statements'
import selfAccountTeamWatcher from './modules/sagas/selfAccountTeam'
import tagsWatcher from './modules/sagas/tags'
import tikTokConstantsWatcher from './modules/sagas/tikTokConstants'
import toolsWatcher from './modules/sagas/tools'
import triggersWatcher from './modules/sagas/triggers'

import { SUBMIT_LOGOUT_SUCCESS } from './modules/actions/cognito'

const appReducer = combineReducers({
  adAccounts,
  ads,
  agencies,
  agencyTeam,
  app,
  assets,
  audiences,
  auth,
  automationRules,
  amendments,
  batchInfo,
  calendarPlanner,
  campaigns,
  campaignsSummary,
  choices,
  cognito,
  contracts,
  combinedData,
  controller,
  discounts,
  doohly,
  dv360Constants,
  files,
  forms,
  googleConstants,
  lineItems,
  lineItemsSummary,
  location,
  margins,
  mediaFiles,
  mediaOrderOptimisations,
  mediaOrders,
  mediaOrdersBookings,
  mediaOrdersProductLocations,
  mediaOrdersProductVariables,
  mediaOrdersProducts,
  mediaPackages,
  quotations,
  selfAccounts,
  socialAccounts,
  statements,
  tags,
  selfAccountTeam,
  tikTokConstants,
  tools,
  triggers
})

const rootReducer = (state, action) => {
  if (action.type === SUBMIT_LOGOUT_SUCCESS) {
    state = undefined
  }

  return appReducer(state, action)
}

function* rootSaga() {
  yield fork(adAccountsWatcher)
  yield fork(adsWatcher)
  yield fork(agenciesWatcher)
  yield fork(agencyTeamWatcher)
  yield fork(appWatcher)
  yield fork(assetsWatcher)
  yield fork(audiencesWatcher)
  yield fork(authWatcher)
  yield fork(automationRulesWatcher)
  yield fork(amendmentsWatcher)
  yield fork(batchInfoWatcher)
  yield fork(calendarPlannerWatcher)
  yield fork(campaignsSummaryWatcher)
  yield fork(campaignsWatcher)
  yield fork(choicesWatcher)
  yield fork(combinedDataWatcher)
  yield fork(cognitoWatcher)
  yield fork(contractsWatcher)
  yield fork(controllerWatcher)
  yield fork(discountsWatcher)
  yield fork(dv360ConstantsWatcher)
  yield fork(doohlyWatcher)
  yield fork(filesWatcher)
  yield fork(googleConstantsWatcher)
  yield fork(lineItemsSummaryWatcher)
  yield fork(lineItemsWatcher)
  yield fork(locationWatcher)
  yield fork(marginsWatcher)
  yield fork(mediaFilesWatcher)
  yield fork(mediaOrderOptimisationsWatcher)
  yield fork(mediaOrdersWatcher)
  yield fork(mediaOrdersBookingsWatcher)
  yield fork(mediaOrdersProductLocationsWatcher)
  yield fork(mediaOrdersProductVariablesWatcher)
  yield fork(mediaOrdersProductsWatcher)
  yield fork(mediaPackagesWatcher)
  yield fork(quotationsWatcher)
  yield fork(selfAccountsWatcher)
  yield fork(socialAccountsWatcher)
  yield fork(statementsWatcher)
  yield fork(tagsWatcher)
  yield fork(selfAccountTeamWatcher)
  yield fork(tikTokConstantsWatcher)
  yield fork(toolsWatcher)
  yield fork(triggersWatcher)
}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export default store
