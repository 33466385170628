import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import usePermissions from '../../../../../../hooks/usePermissions'

import { getRowIdByMediaIdAndRequiredFileId } from '../../helpers'
import { insertIf } from '../../../../../../helpers/common'
import {
  APPROVED,
  BUYER_DISAPPROVED,
  DISAPPROVED,
  getApprovalActionStatusForBuyerApproval,
  getStatusForDisapprovalAction
} from '../../../../../../features/components/UploadedFilesField/helpers'

import {
  bulkUpdateBookingMediaFileIsLoadingSelector,
  bulkUpdateBookingMediaFileSelector,
  deleteBookingMediaFileIsLoadingSelector,
  deleteBookingMediaFileSelector,
  updateBookedMediaFileIsLoadingSelector,
  updateBookedMediaFileSelector,
  uploadBookingMediaFileIsLoadingSelector,
  uploadBookingMediaFileSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import { openForm } from '../../../../../../modules/actions/forms'
import { deleteBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector, isUserControllerSelector } from '../../../../../../modules/selectors/app'

import { ADD_COMMENTS_TO_UPLOADED_FILES, UPLOAD_BOOKED_MEDIA_FILES } from '../../../../../../constants/forms'
import { MEDIA_ORDER_FILES_PERMISSIONS } from '../../../../../../constants/permissions'

import { getAllowApprove, getAllowDisapprove } from './helpers'

export const useGetUpdatingId = () => {
  const { requestedFileId: updatedId } = useSelector(uploadBookingMediaFileSelector)
  const uploadBookingMediaFileIsLoading = useSelector(uploadBookingMediaFileIsLoadingSelector)
  const { requirementFileId, booked_media: mediaId } = useSelector(deleteBookingMediaFileSelector)
  const deleteBookingMediaFileIsLoading = useSelector(deleteBookingMediaFileIsLoadingSelector)
  const { requirementFileId: updatingRequirementFileId, booked_media: updatingMediaId } =
    useSelector(updateBookedMediaFileSelector)
  const updateBookedMediaFileIsLoading = useSelector(updateBookedMediaFileIsLoadingSelector)

  return useMemo(() => {
    if (uploadBookingMediaFileIsLoading) {
      return updatedId
    } else if (deleteBookingMediaFileIsLoading) {
      return getRowIdByMediaIdAndRequiredFileId(mediaId, requirementFileId)
    } else if (updateBookedMediaFileIsLoading) {
      return getRowIdByMediaIdAndRequiredFileId(updatingMediaId, updatingRequirementFileId)
    }

    return undefined
  }, [
    uploadBookingMediaFileIsLoading,
    updatedId,
    deleteBookingMediaFileIsLoading,
    mediaId,
    requirementFileId,
    updateBookedMediaFileIsLoading,
    updatingMediaId,
    updatingRequirementFileId
  ])
}

export const useGetUpdatingIds = files => {
  const bulkUpdateBookingMediaFileIsLoading = useSelector(bulkUpdateBookingMediaFileIsLoadingSelector)
  const bulkUpdateBookingMediaFile = useSelector(bulkUpdateBookingMediaFileSelector)

  if (bulkUpdateBookingMediaFileIsLoading) {
    const { ids } = bulkUpdateBookingMediaFile
    const updatingFiles = files.filter(file => ids.includes(file.uploadedFileId))
    const updatingFileIds = updatingFiles.map(file => file.id)
    return updatingFileIds
  } else {
    return []
  }
}

export const useOpenFileForm = () => {
  const dispatch = useDispatch()

  return useCallback(
    (requestedFileId, mediaId) => () => {
      dispatch(
        openForm({
          id: requestedFileId,
          formName: UPLOAD_BOOKED_MEDIA_FILES,
          otherParams: `mediaId=${mediaId}`
        })
      )
    },
    [dispatch]
  )
}

export const useHandleDeleteUploadFile = () => {
  const dispatch = useDispatch()

  return useCallback(
    ({ requirementFileId, uploadedFile }) =>
      () => {
        dispatch(
          deleteBookingMediaFile(uploadedFile.id, {
            booked_media: uploadedFile.booked_media,
            requirementFileId
          })
        )
      },
    [dispatch]
  )
}

export const useGetApprovalOptions = ({ handleShowModal, openFileInstancesModal }) => {
  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  return useCallback(
    ({ uploadedFile, requirementFileId }) => {
      const fileStatus = uploadedFile?.approval_status
      if (isControllerBuyerApproval) {
        const allowDisapprove = getAllowDisapprove(fileStatus)
        const allowApprove = getAllowApprove(fileStatus)

        const approveStatus = getApprovalActionStatusForBuyerApproval(uploadedFile)
        const disapprovalActionStatus = getStatusForDisapprovalAction(uploadedFile, isControllerBuyerApproval)

        const approveClickHandler = () => openFileInstancesModal({ status: approveStatus, uploadedFile })
        const disapproveClickHandler = handleShowModal(requirementFileId, uploadedFile)

        return [
          ...insertIf(allowApprove, {
            text: 'Approve',
            onClickHandler: approveClickHandler,
            status: approveStatus
          }),
          ...insertIf(allowDisapprove, {
            text: 'Disapprove',
            onClickHandler: disapproveClickHandler,
            status: disapprovalActionStatus
          })
        ]
      } else {
        const approveOnClickHandler = () => openFileInstancesModal({ status: APPROVED, uploadedFile })
        const disapproveClickHandler = handleShowModal(requirementFileId, uploadedFile)

        return [
          ...insertIf(fileStatus !== APPROVED, {
            text: 'Approve',
            onClickHandler: approveOnClickHandler,
            status: APPROVED
          }),
          ...insertIf(fileStatus !== DISAPPROVED, {
            text: 'Disapprove',
            onClickHandler: disapproveClickHandler,
            status: DISAPPROVED
          })
        ]
      }
    },
    [isControllerBuyerApproval, handleShowModal, openFileInstancesModal]
  )
}

export const useUpdateFileStatusHandler = ({
  handleShowModal,
  openFileInstancesModal,
  requirementFileId,
  uploadedFile
}) => {
  return useCallback(
    status => {
      if (status === DISAPPROVED || status === BUYER_DISAPPROVED) {
        const showDisapprovalReasonModalHandler = handleShowModal(requirementFileId, uploadedFile)
        showDisapprovalReasonModalHandler()
      } else {
        openFileInstancesModal({ status, uploadedFile, requirementFileId })
      }
    },
    [handleShowModal, openFileInstancesModal, uploadedFile, requirementFileId]
  )
}

export const useGetDropdownOptions = ({
  handleDelete,
  openUploadFileForm,
  handleFileDownload,
  handleShowModal,
  openFileInstancesModal
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const permissions = usePermissions()

  const isUserController = useSelector(isUserControllerSelector)

  const openAddCommentsToUploadedFilesForm = useCallback(
    (fileId, mediaId) => () => {
      dispatch(
        openForm({
          id: fileId,
          formName: ADD_COMMENTS_TO_UPLOADED_FILES,
          otherParams: `mediaId=${mediaId}`
        })
      )
    },
    [dispatch]
  )

  const hasApprovePermissions = permissions && permissions.can('manage', MEDIA_ORDER_FILES_PERMISSIONS)

  const getApprovalOptions = useGetApprovalOptions({ handleShowModal, openFileInstancesModal })

  return useCallback(
    ({ requirementFileId, mediaId, uploadedFile }) => {
      if (uploadedFile) {
        return [
          ...insertIf(uploadedFile.approval_status !== APPROVED, {
            text: t('Replace file'),
            onClickHandler: handleDelete({ requirementFileId, uploadedFile })
          }),
          {
            text: t('Upload another file'),
            onClickHandler: openUploadFileForm(requirementFileId, mediaId)
          },
          {
            text: t('Download file'),
            onClickHandler: handleFileDownload(uploadedFile)
          },
          ...insertIf(hasApprovePermissions, ...getApprovalOptions({ uploadedFile, requirementFileId })),
          ...insertIf(isUserController, {
            text: !!uploadedFile.comment ? t('Edit comment') : t('Add comment'),
            onClickHandler: openAddCommentsToUploadedFilesForm(uploadedFile.id, mediaId)
          })
        ]
      } else {
        return [
          {
            text: t('Upload'),
            onClickHandler: openUploadFileForm(requirementFileId, mediaId)
          }
        ]
      }
    },
    [
      t,
      handleDelete,
      openUploadFileForm,
      handleFileDownload,
      hasApprovePermissions,
      getApprovalOptions,
      openAddCommentsToUploadedFilesForm,
      isUserController
    ]
  )
}
