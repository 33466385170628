import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import Checkbox from '../../../../../../../../components/Form/Checkbox'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'
import SelectOrDeselectAllButton from './SelectOrDeselectAllButton'

import { getFormattedMediaDate } from '../../../../../../../../pages/MediaOrderSummary/MediaOrderSummaryContent/RequestedFilesSection/helpers'

import { SELECTED_FILES } from '../../../fields'

import useStyles from './styles'

const ProductSection = ({ formik, bookedMediaGroupedByProduct }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, errors, touched, setFieldValue } = formik

  const selectedFilesError = getIn(errors, SELECTED_FILES)
  const selectedFilesTouched = getIn(touched, SELECTED_FILES)

  const allUploadedFilesFromBookedMediaGroupedByProduct = useMemo(() => {
    return bookedMediaGroupedByProduct.bookedMedia.reduce((acc, media) => {
      return acc.concat(media.uploaded_files)
    }, [])
  }, [bookedMediaGroupedByProduct])

  const filesWithoutComment = useMemo(() => {
    return allUploadedFilesFromBookedMediaGroupedByProduct.filter(file => !file.comment)
  }, [allUploadedFilesFromBookedMediaGroupedByProduct])

  return (
    <FieldsSection
      title={bookedMediaGroupedByProduct.name}
      rightColumn={
        !!filesWithoutComment.length && (
          <SelectOrDeselectAllButton formik={formik} filesWithoutComment={filesWithoutComment} />
        )
      }
    >
      {allUploadedFilesFromBookedMediaGroupedByProduct.map(file => {
        const parentBookedMedia = bookedMediaGroupedByProduct.bookedMedia.find(media => {
          return media.uploaded_files.some(uploadedFile => uploadedFile.id === file.id)
        })

        const formattedPeriod = getFormattedMediaDate(parentBookedMedia.date_start, parentBookedMedia.period)

        return (
          <Checkbox
            key={file.id}
            checked={getIn(values, `${SELECTED_FILES}.${file.id}`)}
            id={`checkbox_${file.id}`}
            title={file.name}
            isLarge={true}
            onCheck={() => {
              setFieldValue(`${SELECTED_FILES}.${file.id}`, !getIn(values, `${SELECTED_FILES}.${file.id}`))
            }}
          >
            <>
              <div>{formattedPeriod}</div>
              {file.comment && <div className={classes.commentExists}>{t('Comment already exists')}</div>}
            </>
          </Checkbox>
        )
      })}
      {selectedFilesError && selectedFilesTouched && <ErrorMessage error={selectedFilesError} />}
    </FieldsSection>
  )
}

ProductSection.propTypes = {
  formik: PropTypes.object,
  bookedMediaGroupedByProduct: PropTypes.object
}

export default ProductSection
