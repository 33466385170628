import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import RadioBlock from '../../../../../components/Form/RadioBlock'

import useStyles from '../../../../../styles/common/stepForms'

import { DEEP_COPY_NO, DEEP_COPY_OPTION, DEEP_COPY_YES } from '../fields'

function DeepCopyStep({ formik, handleStepChange }) {
  const { t } = useTranslation()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  const handleContinue = () => {
    handleStepChange()
  }

  return (
    <>
      <div className={classes.stepTitle}>{t('Do you want to also duplicate the ads in this ad set?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_display_deep_copy_yes"
          name={DEEP_COPY_OPTION}
          value={DEEP_COPY_YES}
          selectedValue={values[DEEP_COPY_OPTION]}
          label={t('Yes, duplicate the ads too')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_display_deep_copy_no"
          name={DEEP_COPY_OPTION}
          value={DEEP_COPY_NO}
          selectedValue={values[DEEP_COPY_OPTION]}
          label={t('No, just duplicate the ad set')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default DeepCopyStep
