import { createStyles } from '../../../styles/helpers'

import { phonesDown } from '../../../styles/const/breakpoints'
import { transitionShort, zIndex10, zIndex9 } from '../../../styles/const/common'
import { mediumGrey, textGrey, white } from '../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  filtersButton: {
    minWidth: 100
  },
  closeFiltersButton: {
    padding: 0,
    '& path': {
      // reset default ButtonIcon styles
      fill: [theme.brandPrimary, '!important']
    }
  },
  arrowIcon: {
    width: 12,
    height: 8,
    display: 'inline-block',
    marginLeft: 10,
    transition: transitionShort,
    transform: ({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')
  },
  filters: {
    maxWidth: 350,
    width: '100%',
    overflowX: 'hidden',
    [`@media screen and (${phonesDown})`]: {
      width: '100%',
      height: '100%',
      maxHeight: 'none',
      maxWidth: 'none',
      position: 'fixed',
      left: '0 !important',
      transform: 'none !important',
      overflowY: 'scroll',
      zIndex: zIndex10,
      paddingBottom: 20
    }
  },
  filtersHeader: {
    display: 'flex',
    backgroundColor: white,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 14,
    borderBottom: `1px solid ${mediumGrey}`,
    zIndex: zIndex9,
    top: 0,
    left: 0
  },
  filtersTitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '15.4px',
    color: textGrey
  }
}))

export default useStyles
