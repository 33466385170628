import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import HighlightedValueSelect from '../../../../../../../../../components/HighlightedValueSelect'
import MultipleCheckboxesSelect from '../../../../../../../../../components/MultipleCheckboxesSelect'
import FiltersSearch from '../../../../../../../../../features/components/Filters/FiltersSearch'
import MediaProductsFiltersSkeleton from './MediaProductsFiltersSkeleton'
import MultipleCategoriesFilters from '../../../../../../../../../features/components/Filters/MultipleCategoriesFilters'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import {
  mediaProductAttachedLocationsIsLoadingSelector,
  mediaProductAttachedLocationsSelector
} from '../../../../../../../../../modules/selectors/mediaOrdersProductLocations'
import { productTagsIsLoadingSelector, productTagsSelector } from '../../../../../../../../../modules/selectors/tags'
import {
  clearGetMediaProductAttachedLocations,
  getMediaProductAttachedLocations
} from '../../../../../../../../../modules/actions/mediaOrdersProductLocations'
import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'
import { clearGetProductTags, getProductTags } from '../../../../../../../../../modules/actions/tags'

import { MEDIA_PRODUCTS_FILTERS_SEARCH } from '../../../../../../../../../pages/Settings/MediaProducts/MediaProductsDataContent'

import useSettingsClasses from '../../../../../../../../../pages/Settings/styles'
import useStyles from './styles'

export const mediaProductTagsParams = {
  ordering: 'title'
}

const MediaProductsFilters = ({ filters, showSearchField }) => {
  const { categoryFilterOption, selectedLocations, setSelectedLocations, selectedTag, setSelectedTag } = filters

  const settingsClasses = useSettingsClasses()
  const classes = useStyles()

  const dispatch = useDispatch()

  const tags = useSelector(productTagsSelector)
  const productTagsIsLoading = useSelector(productTagsIsLoadingSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const attachedProductsLocations = useSelector(mediaProductAttachedLocationsSelector)
  const mediaProductAttachedLocationsIsLoading = useSelector(mediaProductAttachedLocationsIsLoadingSelector)

  const formattedAttachedProductLocations = useMemo(() => {
    return formatOptionsList({
      list: attachedProductsLocations,
      valueName: 'id',
      labelName: 'name'
    })
  }, [attachedProductsLocations])

  const tagsOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const handleChangeFilter = useCallback(
    option => {
      setSelectedTag(option?.value)
    },
    [setSelectedTag]
  )

  const selectLocationHandler = useCallback(
    data => {
      setSelectedLocations(data)
    },
    [setSelectedLocations]
  )

  useEffect(() => {
    if (controllerId) {
      dispatch(
        getMediaProductAttachedLocations({
          controller: controllerId,
          ordering: 'name',
          ...(categoryFilterOption && { media_category: categoryFilterOption })
        })
      )
    }
  }, [dispatch, controllerId, categoryFilterOption])

  useEffect(() => {
    dispatch(getProductTags({ ...mediaProductTagsParams, controller: controllerId }))
  }, [dispatch, controllerId])

  useEffect(
    () => () => {
      dispatch(clearGetMediaProductAttachedLocations())
    },
    [dispatch]
  )

  useEffect(
    () => () => {
      dispatch(clearGetProductTags())
    },
    [dispatch]
  )

  if (productTagsIsLoading || mediaProductAttachedLocationsIsLoading) {
    return <MediaProductsFiltersSkeleton showSearchField={showSearchField} />
  }

  return (
    <div className={settingsClasses.filtersContainer}>
      {!!tagsOptions.length && (
        <HighlightedValueSelect
          className={classes.tagsFilter}
          value={selectedTag}
          onChange={handleChangeFilter}
          options={tagsOptions}
          placeholder="Tags"
          isSmall
          portaled
        />
      )}
      {!!formattedAttachedProductLocations.length && (
        <div className={settingsClasses.selectWrapper}>
          <MultipleCheckboxesSelect
            options={formattedAttachedProductLocations}
            value={selectedLocations}
            onChange={selectLocationHandler}
            placeholder="Locations"
            multipleOptionsLabel="Locations"
            isSearchable={true}
          />
        </div>
      )}
      <MultipleCategoriesFilters filters={filters} />
      {showSearchField && <FiltersSearch searchId={MEDIA_PRODUCTS_FILTERS_SEARCH} />}
    </div>
  )
}

MediaProductsFilters.propTypes = {
  filters: PropTypes.object,
  showSearchField: PropTypes.bool
}

export default MediaProductsFilters
