import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  permissionCheckbox: {
    '&:not(:last-child)': {
      marginBottom: 16
    }
  }
})

export default useStyles
