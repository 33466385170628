import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback } from 'react'

import ApprovalsFilters from './ApprovalsFilters'
import InstallationReportTable from '../InstallationReport/InstallationReportTable'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { getInstallationBookedMediaReport } from '../../../../../modules/actions/mediaOrdersBookings'

const Approvals = () => {
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const dispatch = useDispatch()

  const handleFilterChange = useCallback(
    filters => {
      dispatch(
        getInstallationBookedMediaReport({
          controller: selectedControllerId,
          ...filters
        })
      )
    },
    [dispatch, selectedControllerId]
  )

  return (
    <>
      <ApprovalsFilters onFiltersChange={handleFilterChange} />
      <br />
      <InstallationReportTable noDataContentText="There are no files to be approved with the applied filters" />
    </>
  )
}

export default Approvals
