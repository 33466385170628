import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import QuotationSendForm from './QuotationSendForm'

import useManageFormsDrawer from '../../../hooks/formHooks/useManageFormsDrawer'

import { quotationsSelector } from '../../../modules/selectors/quotations'

import { QUOTATION_SEND } from '../../../constants/forms'

function QuotationSend() {
  const quotations = useSelector(quotationsSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: QUOTATION_SEND
  })

  const quotationData = useMemo(
    () => quotations.find(item => item.id === Number(selectedEditItemId)),
    [quotations, selectedEditItemId]
  )

  return (
    <FormDrawerWrapper
      formName={QUOTATION_SEND}
      title="Send Proposal"
      isFormLoading={!quotationData}
      showOpenButton={false}
    >
      <QuotationSendForm quotationData={quotationData} />
    </FormDrawerWrapper>
  )
}

export default QuotationSend
