import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'
import { getLoadOptionsHandler } from '../../../../helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { getControllerMembersService } from '../../../../../modules/services/controller'

import { BUYER } from '../../../../../constants/permissions'

export const BUYER_FILTER = 'buyer'

export const BuyersFilter = ({ Context }) => {
  const controllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [BUYER_FILTER]: buyers } = filtersState

  const setBuyers = useCallback(
    newBuyers => {
      const updatedFiltersState = {
        ...filtersState,
        [BUYER_FILTER]: newBuyers
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const formatBuyers = useCallback(
    buyers =>
      formatOptionsList({
        list: buyers,
        valueName: 'user',
        labelName: 'full_name'
      }),
    []
  )

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getControllerMembersService,
      params: {
        controller: controllerId,
        ordering: 'name',
        role: BUYER,
        fields: ['id', 'full_name', 'role'].join(',')
      },
      formatOptions: formatBuyers
    })
  }, [controllerId, formatBuyers])

  const onBuyersChange = useCallback(
    selectedOptions => {
      setBuyers(selectedOptions)
    },
    [setBuyers]
  )

  const setBuyersHandler = useCallback(
    (name, data) => {
      setBuyers(data)
    },
    [setBuyers]
  )

  return (
    <MultiSelectBox
      name="Buyers"
      value={buyers}
      onChange={onBuyersChange}
      loadOptions={loadOptions}
      placeholder="Buyers"
      setFieldValue={setBuyersHandler}
      portaled
    />
  )
}

BuyersFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default BuyersFilter
