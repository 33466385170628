import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import ExportInstallationReportAsCsv from '../ExportInstallationReportAsCsv'
import RefreshButton from '../../../../../../features/components/Filters/RefreshButton'
import SubCategoriesFilter, {
  SELECTED_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import SubSubCategoriesFilter, {
  SELECTED_SUB_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import {
  BRAND_SUB_CATEGORY,
  BrandSubCategoryFilter
} from '../../../../../../features/components/DropdownFilters/components/BrandSubCategoryFilter'
import DateFilter, {
  DATE_RANGE_FILTER
} from '../../../../../../features/components/DropdownFilters/components/DateFilter'
import DropdownFilters from '../../../../../../features/components/DropdownFilters'
import {
  BRAND_CATEGORY,
  BrandCategory
} from '../../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import CategoriesFilter, {
  SELECTED_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import FileStatusesFilter, {
  SELECTED_FILE_STATUSES
} from '../../../../../../features/components/DropdownFilters/components/FileStatusesFilter'
import LocationsFilter, {
  SELECTED_LOCATIONS
} from '../../../../../../features/components/DropdownFilters/components/LocationsFilter'
import ProductsFilter, {
  SELECTED_PRODUCTS
} from '../../../../../../features/components/DropdownFilters/components/ProductsFilter'
import StatusesFilter, {
  SELECTED_STATUSES
} from '../../../../../../features/components/DropdownFilters/components/StatusesFilter'

import usePermissions from '../../../../../../hooks/usePermissions'
import { formatDateRangeLabel } from '../../../../../../features/components/Filters/DateRangeFilter/helpers'
import { getAllSubSubCategories } from '../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter/helpers'
import { getIsDateRangeFilterSelected } from '../../../../../../features/components/DropdownFilters/components/DateFilter/helpers'

import { mediaSubCategoriesSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { selfAccountSubCategoriesSelector } from '../../../../../../modules/selectors/selfAccounts'
import {
  clearGetSelfAccountSubCategories,
  getSelfAccountSubCategories
} from '../../../../../../modules/actions/selfAccounts'

import { InstallationReportDataProvider, InstallationReportFiltersContext } from './InstallationReportFiltersContext'
import { MEDIA_CATEGORIES_FILTERS_PERMISSION } from '../../../../../../constants/permissions'

import useStyles from './styles'

const InstallationReportFilters = ({ onFiltersChange }) => {
  const permissions = usePermissions()
  const classes = useStyles()

  const dispatch = useDispatch()
  const controllerId = useSelector(selectedControllerIdSelector)
  const selfAccountSubCategories = useSelector(selfAccountSubCategoriesSelector)

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const allSubSubCategories = useMemo(() => getAllSubSubCategories(mediaSubCategories), [mediaSubCategories])

  useEffect(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    dispatch(
      getSelfAccountSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    dispatch(clearGetMediaSubCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(clearGetSelfAccountSubCategories())
  }, [dispatch])

  const filters = useMemo(() => {
    return [
      {
        component: <LocationsFilter Context={InstallationReportFiltersContext} placeholder="Location" />,
        title: 'Location',
        filterName: SELECTED_LOCATIONS,
        show: true
      },
      {
        component: <ProductsFilter Context={InstallationReportFiltersContext} />,
        title: 'Products',
        filterName: SELECTED_PRODUCTS,
        show: true
      },
      {
        component: <CategoriesFilter Context={InstallationReportFiltersContext} />,
        title: 'Categories',
        filterName: SELECTED_CATEGORIES,
        show: permissions && permissions.can('manage', MEDIA_CATEGORIES_FILTERS_PERMISSION)
      },
      {
        component: <SubCategoriesFilter Context={InstallationReportFiltersContext} />,
        title: 'Asset Type',
        filterName: SELECTED_SUB_CATEGORIES,
        show:
          permissions && permissions.can('manage', MEDIA_CATEGORIES_FILTERS_PERMISSION) && !!mediaSubCategories.length
      },
      {
        component: <SubSubCategoriesFilter Context={InstallationReportFiltersContext} />,
        title: 'Asset Subtype',
        filterName: SELECTED_SUB_SUB_CATEGORIES,
        show:
          permissions && permissions.can('manage', MEDIA_CATEGORIES_FILTERS_PERMISSION) && !!allSubSubCategories.length
      },
      {
        component: <FileStatusesFilter Context={InstallationReportFiltersContext} />,
        title: 'File Status',
        filterName: SELECTED_FILE_STATUSES,
        show: true
      },
      {
        component: <StatusesFilter Context={InstallationReportFiltersContext} />,
        title: 'Status',
        filterName: SELECTED_STATUSES,
        show: true
      },
      {
        component: <BrandCategory Context={InstallationReportFiltersContext} />,
        title: 'Brand Category',
        filterName: BRAND_CATEGORY,
        show: true
      },
      {
        component: <BrandSubCategoryFilter Context={InstallationReportFiltersContext} />,
        title: 'Brand Sub Category',
        filterName: BRAND_SUB_CATEGORY,
        show: !!selfAccountSubCategories.length
      },
      {
        component: (
          <DateFilter Context={InstallationReportFiltersContext} formatDateRangeLabel={formatDateRangeLabel} />
        ),
        filterName: DATE_RANGE_FILTER,
        title: 'Date',
        show: true,
        allowReset: false,
        getIsFilterSelected: getIsDateRangeFilterSelected
      }
    ]
  }, [permissions, mediaSubCategories.length, allSubSubCategories.length, selfAccountSubCategories.length])

  return (
    <InstallationReportDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <RefreshButton refreshDataHandler={onFiltersChange} Context={InstallationReportFiltersContext} />

        <ExportInstallationReportAsCsv />

        <DropdownFilters filters={filters} Context={InstallationReportFiltersContext} />
      </div>
    </InstallationReportDataProvider>
  )
}

InstallationReportFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default InstallationReportFilters
