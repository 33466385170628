import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formattedStatusesOptions } from '../../../../../constants/bookedMedia'

export const SELECTED_STATUSES = 'selectedStatuses'

export const StatusesFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_STATUSES]: selectedStatuses } = filtersState

  const setSelectedStatuses = useCallback(
    newStatuses => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_STATUSES]: newStatuses
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const selectStatusHandler = useCallback(
    data => {
      setSelectedStatuses(data)
    },
    [setSelectedStatuses]
  )

  const setStatusesHandler = useCallback(
    (name, data) => {
      selectStatusHandler(data)
    },
    [selectStatusHandler]
  )

  return (
    <MultiSelectBox
      name="Status"
      options={formattedStatusesOptions}
      value={selectedStatuses}
      onChange={selectStatusHandler}
      placeholder="Status"
      isSearchable={false}
      setFieldValue={setStatusesHandler}
      portaled
    />
  )
}

StatusesFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default StatusesFilter
