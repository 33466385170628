import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../components/Form/Field'
import Button from '../../../../../components/Button'

import { formatOptionsList } from '../../../../../features/formatters'

import { choicesPixelsSelector } from '../../../../../modules/selectors/choices'

import { PIXEL_CHOICE } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const EventSourceStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const pixels = useSelector(choicesPixelsSelector)

  const formattedPixels = useMemo(
    () =>
      formatOptionsList({
        list: pixels,
        labelName: 'name',
        valueName: 'id'
      }),
    [pixels]
  )

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Select the pixel that is installed on your website')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Event Source')}</h4>
          <Field
            placeholder="Event Source"
            formik={formik}
            name={PIXEL_CHOICE}
            options={formattedPixels}
            preselectFirstOptionValue
          />
        </section>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" onClick={() => handleStepChange()}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default EventSourceStep
