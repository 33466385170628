import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'

import { selectedControllerRelatedSelfAccountsSelector } from '../../../../../modules/selectors/app'

export const MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER = 'multipleControllerSelfAccountsFilter'

const MultipleControllerSelfAccountsFilter = ({ Context }) => {
  const selectedControllerRelatedSelfAccounts = useSelector(selectedControllerRelatedSelfAccountsSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: multipleAccountFilterOption } = filtersState

  const formattedSelfAccounts = useMemo(() => {
    return formatOptionsList({
      list: selectedControllerRelatedSelfAccounts,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selectedControllerRelatedSelfAccounts])

  const setMultipleAccountFilterOption = useCallback(
    newMultipleAccountFilterOption => {
      const updatedFiltersState = {
        ...filtersState,
        [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: newMultipleAccountFilterOption
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const onMultipleAccountFilterChange = useCallback(
    data => {
      setMultipleAccountFilterOption(data)
    },
    [setMultipleAccountFilterOption]
  )

  const setMultipleAccountFilterOptionHandler = useCallback(
    (name, data) => {
      setMultipleAccountFilterOption(data)
    },
    [setMultipleAccountFilterOption]
  )

  return (
    <MultiSelectBox
      name="Accounts"
      options={formattedSelfAccounts}
      value={multipleAccountFilterOption}
      onChange={onMultipleAccountFilterChange}
      placeholder="Accounts"
      setFieldValue={setMultipleAccountFilterOptionHandler}
      portaled
    />
  )
}

MultipleControllerSelfAccountsFilter.propTypes = {
  Context: PropTypes.object
}

export default MultipleControllerSelfAccountsFilter
