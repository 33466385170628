import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'

import { getSelfAccountCategories } from '../../../../../modules/actions/selfAccounts'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { selfAccountCategoriesSelector } from '../../../../../modules/selectors/selfAccounts'

export const BRAND_CATEGORY = 'brandCategory'

export const BrandCategory = ({ Context }) => {
  const dispatch = useDispatch()

  const { filtersState, setFiltersState } = useContext(Context)

  const { [BRAND_CATEGORY]: brandCategory } = filtersState

  const setBrandCategory = useCallback(
    newBrandCategory => {
      const updatedFiltersState = {
        ...filtersState,
        [BRAND_CATEGORY]: newBrandCategory
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const controllerId = useSelector(selectedControllerIdSelector)
  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)

  const formattedSelfAccountCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountCategories])

  const selectBrandCategoryHandler = useCallback(
    data => {
      setBrandCategory(data)
    },
    [setBrandCategory]
  )

  useEffect(() => {
    dispatch(
      getSelfAccountCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  const setSelectBrandCategoryHandler = useCallback(
    (name, data) => {
      selectBrandCategoryHandler(data)
    },
    [selectBrandCategoryHandler]
  )

  return (
    <MultiSelectBox
      name="Brand Category"
      options={formattedSelfAccountCategoriesOptions}
      value={brandCategory}
      onChange={selectBrandCategoryHandler}
      placeholder="Brand Category"
      setFieldValue={setSelectBrandCategoryHandler}
      portaled
    />
  )
}

BrandCategory.propTypes = {
  Context: PropTypes.object.isRequired
}
