import { createStyles } from '../../../../../../styles/helpers'
import { darkGrey } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  requested: {
    color: darkGrey
  }
})

export default useStyles
