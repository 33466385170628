import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { mediaCategories } from '../../../../../constants/mediaOrders'

export const SELECTED_CATEGORIES = 'selectedCategories'

export const CategoriesFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_CATEGORIES]: selectedCategories } = filtersState

  const setSelectedCategories = useCallback(
    newCategories => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_CATEGORIES]: newCategories
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const selectCategoryHandler = useCallback(
    data => {
      setSelectedCategories(data)
    },
    [setSelectedCategories]
  )

  const setCategoryHandler = useCallback(
    (name, data) => {
      selectCategoryHandler(data)
    },
    [selectCategoryHandler]
  )

  return (
    <MultiSelectBox
      name="Category"
      options={mediaCategories}
      value={selectedCategories}
      onChange={selectCategoryHandler}
      placeholder="Category"
      setFieldValue={setCategoryHandler}
      portaled
    />
  )
}

CategoriesFilter.propTypes = {
  Context: PropTypes.object
}

export default CategoriesFilter
