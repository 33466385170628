import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { SELECTED_LOCATIONS } from '../../../../../../../features/components/DropdownFilters/components/LocationsFilter'
import { SELECTED_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import { SELECTED_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { SELECTED_SUB_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import { SELECTED_PRODUCTS } from '../../../../../../../features/components/DropdownFilters/components/ProductsFilter'

export const CalendarInventoryFiltersContext = React.createContext()

export function CalendarInventoryDataProvider({ children, onFiltersChange }) {
  const [filtersState, setFiltersState] = useState({
    [SELECTED_LOCATIONS]: [],
    [SELECTED_CATEGORIES]: [],
    [SELECTED_SUB_CATEGORIES]: [],
    [SELECTED_SUB_SUB_CATEGORIES]: [],
    [SELECTED_PRODUCTS]: []
  })

  const {
    [SELECTED_LOCATIONS]: selectedLocations,
    [SELECTED_CATEGORIES]: selectedCategories,
    [SELECTED_SUB_CATEGORIES]: selectedSubCategories,
    [SELECTED_SUB_SUB_CATEGORIES]: selectedSubSubCategories,
    [SELECTED_PRODUCTS]: productFilterOption
  } = filtersState

  const filterRequestParams = useMemo(
    () => ({
      ...(selectedCategories.length && { media_category: selectedCategories.map(option => option.value).join(',') }),
      ...(selectedSubCategories.length && {
        media_sub_category: selectedSubCategories.map(option => option.value).join(',')
      }),
      ...(selectedSubSubCategories.length && {
        media_sub_sub_category: selectedSubSubCategories.map(option => option.value).join(',')
      }),
      ...(productFilterOption.length && { media_product: productFilterOption.map(option => option.value).join(',') }),
      ...(selectedLocations.length && { location: selectedLocations.map(option => option.value).join(',') })
    }),
    [productFilterOption, selectedLocations, selectedCategories, selectedSubCategories, selectedSubSubCategories]
  )

  const bookedMediaFilterProps = useMemo(
    () => ({
      filtersState,
      setFiltersState,
      filterRequestParams
    }),
    [filterRequestParams, filtersState]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedMediaFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [filterRequestParams, onFiltersChange])

  return (
    <CalendarInventoryFiltersContext.Provider value={bookedMediaFilterProps}>
      {children}
    </CalendarInventoryFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  media_category: PropTypes.string,
  media_product: PropTypes.string,
  media_sub_category: PropTypes.string,
  media_sub_sub_category: PropTypes.string,
  location: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes
}
