import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import Select from '../../Select'
import SelectCreatable from '../../SelectCreatable'
import SelectPaginated from '../../SelectPaginated'

function SelectField(props) {
  const {
    setFieldValue,
    setFieldTouched,
    isMulti,
    name,
    value,
    options,
    preselectFirstOptionValue,
    onValueChange,
    setFullOptionObject
  } = props

  const selectFieldProps = useMemo(
    () => ({
      ...props,
      formatValue: !setFullOptionObject
    }),
    [props, setFullOptionObject]
  )

  const handleChange = useCallback(
    (value, selectData) => {
      const newValue = setFullOptionObject ? value : value.value

      setFieldValue(selectData.name, newValue)
      setFieldTouched(selectData.name, true, false)
      // callback to handle some actions from outside
      onValueChange && onValueChange(newValue)
    },
    [setFieldValue, setFieldTouched, onValueChange, setFullOptionObject]
  )

  const handleMultiChange = useCallback(
    (value, selectData) => {
      const newValue = value || []

      setFieldValue(selectData.name, newValue)
      setFieldTouched(selectData.name, true, false)
      // callback to handle some actions from outside
      onValueChange && onValueChange(newValue)
    },
    [setFieldValue, setFieldTouched, onValueChange]
  )

  const handleClear = useCallback(() => {
    setFieldValue(name, setFullOptionObject ? undefined : '')
  }, [setFieldValue, name, setFullOptionObject])

  // If preselectFirstOptionValue prop is passed to the component
  // this useEffect preselects automatically preselects first option from the options list
  useEffect(() => {
    if (preselectFirstOptionValue && options.length && !value) {
      if (setFullOptionObject) {
        setFieldValue(name, options[0])
      } else {
        setFieldValue(name, options[0].value)
      }
    }
  }, [preselectFirstOptionValue, options, name, value, setFieldValue, setFullOptionObject])

  if (props.loadOptions) {
    // don't pass isLoading prop to SelectPaginated as it reset internal loading management
    const { isLoading, ...restProps } = { ...selectFieldProps }

    return (
      <SelectPaginated
        onClearInput={handleClear}
        onChange={isMulti ? handleMultiChange : handleChange}
        {...restProps}
      />
    )
  }
  return props.onCreateOption ? (
    <SelectCreatable
      onClearInput={handleClear}
      {...selectFieldProps}
      onChange={isMulti ? handleMultiChange : handleChange}
    />
  ) : (
    <Select onClearInput={handleClear} {...selectFieldProps} onChange={isMulti ? handleMultiChange : handleChange} />
  )
}

export default SelectField

SelectField.propTypes = {
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  preselectFirstOptionValue: PropTypes.bool,
  onCreateOption: PropTypes.func,
  isLoading: PropTypes.bool
}
