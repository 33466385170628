import { race, take } from 'typed-redux-saga'
import { call, put, all, takeEvery, takeLatest, select } from 'redux-saga/effects'

import { isEqual } from '../../helpers/common'
import { captureBulkUpdateBookingMediaFileError } from '../../helpers/api/requestErrorsCapturing'

import {
  GET_ORDER_BOOKED_MEDIA,
  getOrderBookedMediaSuccess,
  getOrderBookedMediaFailure,
  GET_BOOKING_MEDIA_FILES,
  getBookingMediaFilesSuccess,
  getBookingMediaFilesFailure,
  UPLOAD_BOOKING_MEDIA_FILE,
  uploadBookingMediaFileSuccess,
  uploadBookingMediaFileFailure,
  DELETE_BOOKING_MEDIA_FILE,
  deleteBookingMediaFileSuccess,
  deleteBookingMediaFileFailure,
  UPDATE_BOOKING_MEDIA_FILE,
  updateBookingMediaFileSuccess,
  updateBookingMediaFileFailure,
  GET_MEDIA_ORDERS_BOOKINGS_AS_CSV,
  getMediaOrdersBookingsAsCsvSuccess,
  getMediaOrdersBookingsAsCsvFailure,
  GET_BOOKED_MEDIA_REPORT,
  getBookedMediaReportSuccess,
  getBookedMediaReportFailure,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT,
  getInstallationBookedMediaReportSuccess,
  getInstallationBookedMediaReportFailure,
  getInstallationBookedMediaReportAsCsvSuccess,
  getInstallationBookedMediaReportAsCsvFailure,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV,
  bulkUpdateBookingMediaFileSuccess,
  bulkUpdateBookingMediaFileFailure,
  BULK_UPDATE_BOOKING_MEDIA_FILE,
  BULK_UPDATE_BOOKED_MEDIA,
  bulkUpdateBookedMediaSuccess,
  bulkUpdateBookedMediaFailure,
  GET_BOOKED_MEDIA,
  getBookedMediaSuccess,
  getBookedMediaFailure,
  CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV,
  getBookedRevenueReportFailure,
  getBookedRevenueReportSuccess,
  GET_BOOKED_REVENUE_REPORT,
  CLEAR_GET_BOOKED_MEDIA_REPORT,
  CLEAR_GET_BOOKED_REVENUE_REPORT,
  GET_BRAND_REVENUE_REPORT,
  getBrandRevenueReportSuccess,
  getBrandRevenueReportFailure,
  GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT,
  getUploadedFilesPendingApprovalCountSuccess,
  getUploadedFilesPendingApprovalCountFailure,
  CLEAR_GET_BOOKED_MEDIA
} from '../actions/mediaOrdersBookings'

import {
  getOrderBookedMediaService,
  getBookingMediaFilesService,
  uploadBookingMediaFileService,
  deleteBookingMediaFileService,
  updateBookingMediaFileService,
  getMediaOrdersBookingsAsCsvService,
  getBookedMediaReportService,
  getInstallationBookedMediaReportService,
  getInstallationBookedMediaReportAsCsvService,
  bulkUpdateBookingMediaFileService,
  bulkUpdateBookedMediaService,
  getBookedMediaService,
  getBookedRevenueReportService,
  getBrandRevenueReportService,
  getUploadedFilesPendingApprovalCountService
} from '../services/mediaOrdersBookings'
import { handleWebSocketRequest } from '../../helpers/modules/saga'
import {
  installationBookedMediaReportSelector,
  installationBookedMediaReportWasLoadedSelector
} from '../selectors/mediaOrdersBookings'

function* mediaOrdersBookingsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_MEDIA_ORDERS_BOOKINGS_AS_CSV, getMediaOrdersBookingsAsCsvWorker),
    takeEvery(UPDATE_BOOKING_MEDIA_FILE, updateBookingMediaFileWorker),
    takeEvery(GET_BOOKING_MEDIA_FILES, getBookingMediaFilesWorker),
    takeEvery(DELETE_BOOKING_MEDIA_FILE, deleteBookingMediaFileWorker),
    takeEvery(UPLOAD_BOOKING_MEDIA_FILE, uploadBookingMediaFileWorker),
    takeLatest(GET_ORDER_BOOKED_MEDIA, getOrderBookedMediaWorker),
    takeEvery(
      action => action.type === GET_BOOKED_REVENUE_REPORT && !action.loadOptions.shouldClearExistingState,
      getBookedRevenueReportWorker
    ),
    // And only the latest getBookedMediaReportWorker task will be running when shouldClearExistingState is true, cancelling any previous tasks.
    takeLatest(
      action => action.type === GET_BOOKED_REVENUE_REPORT && action.loadOptions.shouldClearExistingState,
      getBookedRevenueReportWorker
    ),
    // Once filters shouldClearExistingState is true, the previous getOrderBookedMediaWorker task should be
    // cancelled to avoid fetched data duplications(i.e. it may load the same data again).
    // This way, getBookedMediaReportWorker will be started for each GET_BOOKED_MEDIA_REPORT action,
    // allowing multiple tasks to run in parallel when shouldClearExistingState is false.
    takeEvery(
      action => action.type === GET_BOOKED_MEDIA_REPORT && !action.loadOptions.shouldClearExistingState,
      getBookedMediaReportWorker
    ),
    // And only the latest getBookedMediaReportWorker task will be running when shouldClearExistingState is true, cancelling any previous tasks.
    takeLatest(
      action => action.type === GET_BOOKED_MEDIA_REPORT && action.loadOptions.shouldClearExistingState,
      getBookedMediaReportWorker
    ),
    takeLatest(GET_INSTALLATION_BOOKED_MEDIA_REPORT, getInstallationBookedMediaReportWorker),
    takeEvery(GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV, getInstallationBookedMediaReportAsCsvWorker),
    takeEvery(BULK_UPDATE_BOOKING_MEDIA_FILE, bulkUpdateBookingMediaFileWorker),
    takeEvery(BULK_UPDATE_BOOKED_MEDIA, bulkUpdateBookedMediaWorker),
    takeEvery(GET_BOOKED_MEDIA, getBookedMediaWorker),
    takeEvery(GET_BRAND_REVENUE_REPORT, getBrandRevenueReportWorker),
    takeEvery(GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT, getUploadedFilesPendingApprovalCountWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getMediaOrdersBookingsAsCsvWorker({ params }) {
  yield call(handleWebSocketRequest, {
    service: getMediaOrdersBookingsAsCsvService,
    serviceProps: {
      params
    },
    cancelActionType: CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV,
    successAction: getMediaOrdersBookingsAsCsvSuccess,
    failureAction: getMediaOrdersBookingsAsCsvFailure
  })
}

function* updateBookingMediaFileWorker({ id, data }) {
  try {
    const response = yield call(updateBookingMediaFileService, id, data)
    yield put(updateBookingMediaFileSuccess(response))
  } catch (error) {
    yield put(updateBookingMediaFileFailure(error))
  }
}

function* getBookingMediaFilesWorker({ params }) {
  try {
    const response = yield call(getBookingMediaFilesService, params)
    yield put(getBookingMediaFilesSuccess(response))
  } catch (error) {
    yield put(getBookingMediaFilesFailure(error))
  }
}

function* deleteBookingMediaFileWorker({ id, params }) {
  try {
    yield call(deleteBookingMediaFileService, id, params)
    yield put(deleteBookingMediaFileSuccess({ id, booked_media: params.booked_media }))
  } catch (error) {
    yield put(deleteBookingMediaFileFailure(error))
  }
}

function* uploadBookingMediaFileWorker({ data }) {
  try {
    const response = yield call(uploadBookingMediaFileService, data)
    yield put(uploadBookingMediaFileSuccess(response))
  } catch (error) {
    yield put(uploadBookingMediaFileFailure(error))
  }
}

function* getOrderBookedMediaWorker({ params }) {
  try {
    const response = yield call(getOrderBookedMediaService, params)
    yield put(getOrderBookedMediaSuccess(response))
  } catch (error) {
    yield put(getOrderBookedMediaFailure(error))
  }
}

function* getBookedMediaReportWorker({ params, period }) {
  try {
    const { response } = yield race({
      response: call(getBookedMediaReportService, params),
      // need to cancel previous task, if user change the page, but task still in progress
      cancel: take(CLEAR_GET_BOOKED_MEDIA_REPORT)
    })
    if (response) {
      yield put(getBookedMediaReportSuccess(response, period))
    }
  } catch (error) {
    yield put(getBookedMediaReportFailure(error, period))
  }
}

function* getBookedRevenueReportWorker({ params, period }) {
  try {
    const { response } = yield race({
      response: call(getBookedRevenueReportService, params),
      // need to cancel previous task, if user change the page, but task still in progress
      cancel: take(CLEAR_GET_BOOKED_REVENUE_REPORT)
    })
    if (response) {
      yield put(getBookedRevenueReportSuccess(response, period))
    }
  } catch (error) {
    yield put(getBookedRevenueReportFailure(error, period))
  }
}

function* getInstallationBookedMediaReportWorker({ params }) {
  try {
    const response = yield call(getInstallationBookedMediaReportService, params)
    yield put(getInstallationBookedMediaReportSuccess(response))
  } catch (error) {
    yield put(getInstallationBookedMediaReportFailure(error))
  }
}

function* getInstallationBookedMediaReportAsCsvWorker({ params }) {
  try {
    const response = yield call(getInstallationBookedMediaReportAsCsvService, params)
    yield put(getInstallationBookedMediaReportAsCsvSuccess(response))
  } catch (error) {
    yield put(getInstallationBookedMediaReportAsCsvFailure(error))
  }
}

function* bulkUpdateBookingMediaFileWorker({ data: { booked_media, bookedMediaData, sequentialIds, ...data } }) {
  try {
    const response = yield call(bulkUpdateBookingMediaFileService, {
      ...data
    })
    yield put(
      bulkUpdateBookingMediaFileSuccess({
        ...response,
        booked_media,
        ...data
      })
    )
  } catch (error) {
    const installationBookedMediaReportWasLoaded = yield select(installationBookedMediaReportWasLoadedSelector)

    if (installationBookedMediaReportWasLoaded && error.code === 400) {
      const installationBookedMediaReport = yield select(installationBookedMediaReportSelector)
      const installationReportItem = installationBookedMediaReport.find(item => {
        return isEqual(item.sequential_ids, data.sequentialIds)
      })

      captureBulkUpdateBookingMediaFileError({
        installationReportItem,
        bookedMediaData,
        error
      })
    }

    yield put(bulkUpdateBookingMediaFileFailure(error))
  }
}

function* bulkUpdateBookedMediaWorker({ data }) {
  const { ids, status } = data
  try {
    const response = yield call(bulkUpdateBookedMediaService, data)
    yield put(
      bulkUpdateBookedMediaSuccess({
        ...response,
        ids,
        status
      })
    )
  } catch (error) {
    yield put(bulkUpdateBookedMediaFailure(error))
  }
}

function* getBookedMediaWorker({ params }) {
  try {
    const { response } = yield race({
      response: call(getBookedMediaService, params),
      // need to cancel previous task, if user change the booked_media, but task still in progress
      cancel: take(CLEAR_GET_BOOKED_MEDIA)
    })

    if (response) {
      yield put(getBookedMediaSuccess(response))
    }
  } catch (error) {
    yield put(getBookedMediaFailure(error))
  }
}

function* getBrandRevenueReportWorker({ params }) {
  try {
    const response = yield call(getBrandRevenueReportService, params)
    yield put(getBrandRevenueReportSuccess(response))
  } catch (error) {
    yield put(getBrandRevenueReportFailure(error))
  }
}

function* getUploadedFilesPendingApprovalCountWorker({ params }) {
  try {
    const response = yield call(getUploadedFilesPendingApprovalCountService, params)
    yield put(getUploadedFilesPendingApprovalCountSuccess(response))
  } catch (error) {
    yield put(getUploadedFilesPendingApprovalCountFailure(error))
  }
}

export default mediaOrdersBookingsWatcher
