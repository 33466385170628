import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths, startOfMonth } from 'date-fns'
import PropTypes from 'prop-types'

import useSearch from '../../../../../hooks/useSearch'

import {
  selectedControllerDataSelector,
  selectedControllerRelatedSelfAccountsSelector,
  selectedSelfAccountSelector
} from '../../../../../modules/selectors/app'

import { formatDateToBE } from '../../../../../constants/dates'
import { BOOKED_MEDIA_AND_FILES_SEARCH } from '../index'
import {
  STATUS_FILTER_AWAITING_FILES,
  STATUS_FILTER_FILES_OVERDUE,
  STATUS_FILTER_FILES_PENDING_REVIEW
} from '../../../../../features/components/Filters/BookedMediaStatusFilter/options'
import { BOOKED_MEDIA_STATUS_FILTER } from '../../../../../features/components/Filters/BookedMediaStatusFilter'
import { MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER } from '../../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'
import { SELECTED_SUB_CATEGORIES } from '../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { SELECTED_SUB_SUB_CATEGORIES } from '../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import { BRAND_SUB_CATEGORY } from '../../../../../features/components/DropdownFilters/components/BrandSubCategoryFilter'
import { SELECTED_TAG } from '../../../../../features/components/Filters/ProductTagsFilter'
import { BRAND_CATEGORY } from '../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import { SELECTED_CATEGORIES } from '../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import { SELECTED_LOCATIONS } from '../../../../../features/components/DropdownFilters/components/LocationsFilter'
import { SELECTED_PRODUCTS } from '../../../../../features/components/DropdownFilters/components/ProductsFilter'
import { DATE_RANGE_FILTER } from '../../../../../features/components/DropdownFilters/components/DateFilter'

export const BookedMediaFiltersContext = React.createContext()

export function BookedMediaDataProvider({ children, onFiltersChange }) {
  const dispatch = useDispatch()

  const searchTerm = useSearch(BOOKED_MEDIA_AND_FILES_SEARCH)
  const selfAccount = useSelector(selectedSelfAccountSelector)
  const selectedControllerRelatedSelfAccounts = useSelector(selectedControllerRelatedSelfAccountsSelector)
  const { category: selectedControllerCategory } = useSelector(selectedControllerDataSelector)

  const formattedSelectedSelfAccount = useMemo(
    () => ({
      value: selfAccount,
      label: selectedControllerRelatedSelfAccounts.find(account => account.id === selfAccount)?.name
    }),
    [selfAccount, selectedControllerRelatedSelfAccounts]
  )

  const formattedSelectedControllerCategory = useMemo(
    () => ({
      value: selectedControllerCategory?.id,
      label: selectedControllerCategory?.name
    }),
    [selectedControllerCategory]
  )

  const nowDate = useMemo(() => new Date(), [])

  const [filtersState, setFiltersState] = useState({
    [BOOKED_MEDIA_STATUS_FILTER]: '',
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: [formattedSelectedSelfAccount],
    [SELECTED_CATEGORIES]: [],
    [SELECTED_SUB_CATEGORIES]: [],
    [SELECTED_SUB_SUB_CATEGORIES]: [],
    [BRAND_CATEGORY]: selectedControllerCategory ? [formattedSelectedControllerCategory] : [],
    [BRAND_SUB_CATEGORY]: [],
    [SELECTED_PRODUCTS]: [],
    [SELECTED_LOCATIONS]: [],
    [SELECTED_TAG]: '',
    [DATE_RANGE_FILTER]: {
      startDate: startOfMonth(nowDate),
      endDate: addMonths(startOfMonth(nowDate), 3)
    }
  })

  const {
    [BOOKED_MEDIA_STATUS_FILTER]: statusFilterOption,
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: multipleAccountFilterOption,
    [SELECTED_CATEGORIES]: selectedCategories,
    [SELECTED_SUB_CATEGORIES]: selectedSubCategories,
    [SELECTED_SUB_SUB_CATEGORIES]: selectedSubSubCategories,
    [BRAND_CATEGORY]: brandCategory,
    [BRAND_SUB_CATEGORY]: brandSubCategory,
    [SELECTED_PRODUCTS]: productFilterOption,
    [SELECTED_LOCATIONS]: selectedLocations,
    [SELECTED_TAG]: selectedTag,
    [DATE_RANGE_FILTER]: dateRangeFilter
  } = filtersState

  const [customBookingDate, setCustomBookingDate] = useState(false)

  const filterRequestParams = useMemo(
    () => ({
      // date
      ...(dateRangeFilter.startDate && { date_start: formatDateToBE(dateRangeFilter.startDate) }),
      ...(dateRangeFilter.endDate && { date_end: formatDateToBE(dateRangeFilter.endDate) }),
      // search
      ...(searchTerm && { search: searchTerm }),
      // status
      ...(statusFilterOption === STATUS_FILTER_AWAITING_FILES && { file_pending: true }),
      ...(statusFilterOption === STATUS_FILTER_FILES_PENDING_REVIEW && { files_pending_review: true }),
      ...(statusFilterOption === STATUS_FILTER_FILES_OVERDUE && { files_overdue: true }),
      // account
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption.map(option => option.value) }),
      // categories
      ...(selectedCategories.length && { media_category: selectedCategories.map(option => option.value) }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories.map(option => option.value) }),
      ...(selectedSubSubCategories.length && {
        media_sub_sub_category: selectedSubSubCategories.map(option => option.value)
      }),
      // media_product
      ...(productFilterOption.length && { media_product: productFilterOption.map(option => option.value) }),
      // location
      ...(selectedLocations.length && { location: selectedLocations.map(option => option.value) }),
      // tag
      ...(selectedTag && { tags: selectedTag }),
      // brand category
      ...(brandCategory.length && { brand_category: brandCategory.map(option => option.value) }),
      // brand sub category
      ...(brandSubCategory.length && { brand_subcategory: brandSubCategory.map(option => option.value) }),
      ...(customBookingDate && { custom_booking_date: true })
    }),
    [
      searchTerm,
      statusFilterOption,
      multipleAccountFilterOption,
      productFilterOption,
      dateRangeFilter,
      selectedCategories,
      selectedSubCategories,
      selectedSubSubCategories,
      selectedLocations,
      selectedTag,
      brandCategory,
      brandSubCategory,
      customBookingDate
    ]
  )

  const bookedMediaFilterProps = useMemo(
    () => ({
      filterRequestParams,
      filtersState,
      setFiltersState,
      customBookingDate,
      setCustomBookingDate
    }),
    [filtersState, setFiltersState, filterRequestParams, customBookingDate]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedMediaFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [dispatch, filterRequestParams, onFiltersChange])

  return (
    <BookedMediaFiltersContext.Provider value={bookedMediaFilterProps}>{children}</BookedMediaFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  search: PropTypes.string,
  file_pending: PropTypes.bool,
  files_pending_review: PropTypes.bool,
  files_overdue: PropTypes.bool,
  account: PropTypes.arrayOf(PropTypes.string),
  media_category: PropTypes.arrayOf(PropTypes.string),
  media_sub_category: PropTypes.arrayOf(PropTypes.string),
  media_sub_sub_category: PropTypes.arrayOf(PropTypes.string),
  media_product: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.string,
  brand_category: PropTypes.arrayOf(PropTypes.string),
  brand_subcategory: PropTypes.arrayOf(PropTypes.string),
  custom_booking_date: PropTypes.bool
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  filtersState: PropTypes.object.isRequired,
  setFiltersState: PropTypes.func.isRequired
}
