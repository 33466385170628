import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { createJsonFromQueryString } from '../../../helpers/url'
import useSearch from '../../../hooks/useSearch'
import { formatProductsFiltersToRequestParams } from './helpers'

import { selectedControllerIdSelector } from '../../../modules/selectors/app'
import { productsNextSelector } from '../../../modules/selectors/mediaOrdersProducts'
import { getProducts, clearProducts } from '../../../modules/actions/mediaOrdersProducts'

import { MEDIA_PRODUCTS_FILTERS_SEARCH } from '../../../pages/Settings/MediaProducts/MediaProductsDataContent'

const initialLoadOptions = { shouldClearExistingState: true }

const defaultProps = {
  initialMediaCategory: '',
  initialLocations: [],
  additionalRequestParams: {},
  allowFetch: true
}
// the hook is used for Media order Create form and MediaOrderAmendForm
export const useLoadFilteredAndPaginatedProducts = (props = defaultProps) => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useRequestHandler')

  const {
    initialMediaCategory,
    availableLocations,
    initialLocations,
    additionalRequestParams,
    allowFetch = true
  } = props
  const dispatch = useDispatch()

  const searchTerm = useSearch(MEDIA_PRODUCTS_FILTERS_SEARCH)

  const controllerId = useSelector(selectedControllerIdSelector)
  const productsNext = useSelector(productsNextSelector)

  const [selectedCategories, setSelectedCategories] = useState(initialMediaCategory ? [initialMediaCategory] : [])
  const [selectedSubCategories, setSelectedSubCategories] = useState([])
  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState([])

  const [selectedLocations, setSelectedLocations] = useState(initialLocations || [])
  const [selectedTag, setSelectedTag] = useState('')

  const formattedMediaProductsFiltersParams = useMemo(() => {
    const params = formatProductsFiltersToRequestParams({
      selectedCategories,
      selectedSubCategories,
      selectedSubSubCategories,
      selectedLocations,
      selectedTag,
      searchTerm
    })

    return {
      ...params,
      // filter should work as OR so we use comma separated values i.e. location=10,20,21
      ...(params.location && { location: params.location.join(',') }),
      ...(params.media_category && { media_category: params.media_category.join(',') }),
      ...(params.media_sub_category && { media_sub_category: params.media_sub_category.join(',') }),
      ...(params.media_sub_sub_category && { media_sub_sub_category: params.media_sub_sub_category.join(',') })
    }
  }, [selectedCategories, selectedSubCategories, selectedSubSubCategories, selectedLocations, selectedTag, searchTerm])

  const mediaProductsParams = useMemo(
    () => ({
      controller: controllerId,
      ordering: 'order',
      ...formattedMediaProductsFiltersParams,
      ...additionalRequestParams
    }),
    [controllerId, formattedMediaProductsFiltersParams, additionalRequestParams]
  )

  const loadMoreProducts = useCallback(() => {
    dispatch(
      getProducts({
        ...mediaProductsParams,
        ...createJsonFromQueryString(`?${productsNext.split('?')[1]}`)
      })
    )
  }, [dispatch, mediaProductsParams, productsNext])

  const resetFilters = useCallback(() => {
    setSelectedCategories([])
    setSelectedLocations([])
    setSelectedTag('')
  }, [setSelectedCategories, setSelectedLocations, setSelectedTag])

  useEffect(() => {
    if (availableLocations && initialLocations?.length > 0) {
      // check if every filter in initialLocations array is available
      const isAllInitialLocationsAvailable = initialLocations.every(initialLocationFilter =>
        availableLocations.some(location => String(location.id) === String(initialLocationFilter))
      )

      // if some of initial location is not available - reset filter and re-fetch data
      !isAllInitialLocationsAvailable && setSelectedLocations(defaultProps.initialLocations)
    }
  }, [availableLocations, initialLocations])

  useEffect(() => {
    if (controllerId && allowFetch) {
      dispatch(
        getProducts(
          {
            ...mediaProductsParams,
            limit: 20
          },
          initialLoadOptions
        )
      )
    }
  }, [dispatch, controllerId, mediaProductsParams, allowFetch])

  useEffect(
    () => () => {
      if (allowFetch) {
        dispatch(clearProducts())
      }
    },
    [dispatch, allowFetch]
  )

  return useMemo(
    () => ({
      loadMoreProducts: productsNext ? loadMoreProducts : null,
      filters: {
        selectedLocations,
        setSelectedLocations,
        selectedTag,
        setSelectedTag,
        selectedCategories,
        setSelectedCategories,
        selectedSubCategories,
        setSelectedSubCategories,
        selectedSubSubCategories,
        setSelectedSubSubCategories
      },
      resetFilters
    }),
    [
      productsNext,
      loadMoreProducts,
      selectedLocations,
      setSelectedLocations,
      selectedTag,
      setSelectedTag,
      selectedCategories,
      setSelectedCategories,
      selectedSubCategories,
      setSelectedSubCategories,
      selectedSubSubCategories,
      setSelectedSubSubCategories,
      resetFilters
    ]
  )
}

const propTypes = {
  initialMediaCategory: PropTypes.string,
  initialLocations: PropTypes.array,
  additionalRequestParams: PropTypes.object,
  isStepActive: PropTypes.bool
}
