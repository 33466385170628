import { getDocument } from 'pdfjs-dist'
import { calc } from '../../../../../../../../../../helpers/numbers'

// const DPI = 50
const MM_PER_INCH = 25.4
const defaultDPI = 72
// const scale = defaultDPI / DPI;

function determinePageDimensions(page) {
  // Get page dimensions in points
  // `page.view` is an array `[xMin, yMin, xMax, yMax]` in points.
  const pageWidthInPixels = calc(page.view[2]).minus(page.view[0]).toNumber()
  const pageHeightInPixels = calc(page.view[3]).minus(page.view[1]).toNumber()

  // Convert points to millimeters
  const POINTS_PER_MM = calc(defaultDPI).div(MM_PER_INCH).toNumber() // 1 mm = 72 / 25.4 points
  const widthMm = calc(pageWidthInPixels).div(POINTS_PER_MM).toDP(0).toNumber()
  const heightMm = calc(pageHeightInPixels).div(POINTS_PER_MM).toDP(0).toNumber()

  // // Get viewport for scale 1 to obtain CSS pixel dimensions
  // const viewport = page.getViewport({ scale: scale });
  // const widthPx = viewport.width;
  // const heightPx = viewport.height;

  // Convert page dimensions to inches
  // const widthInInches = calc(pageWidthInPixels).div(defaultDPI).toDP(0).toNumber()
  // const heightInInches = calc(pageHeightInPixels).div(defaultDPI).toDP(0).toNumber()

  // // Calculate DPI
  // const dpiX = calc(widthPx).toDP(0).div(widthInInches).toNumber()
  // const dpiY = calc(heightPx).toDP(0).div(heightInInches).toNumber()

  return { widthMm, heightMm, pageWidthInPixels, pageHeightInPixels }
}

export const checkPdfDimensions = async pdfPath => {
  try {
    // Load the PDF document
    const pdf = await getDocument(pdfPath).promise

    // Get the first page from document
    const firstPage = await pdf.getPage(1)

    return determinePageDimensions(firstPage)
  } catch (error) {
    console.error('Error fetching PDF width:', error)
    throw error
  }
}
