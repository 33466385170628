import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import PeriodItem from './PeriodItem'

import { isValidPeriods } from '../../../../helpers/periods'
import { generateGroupLabel } from '../../../helpers'

// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
const SelectedPeriodsList = React.memo(
  ({ selectedQuantity, selectedGroupedPeriods, checkInventory, handleRemovePeriodGroup }) => {
    const onSelectedGroupValueRemove = useCallback(
      ({ periodGroup, index }) => handleRemovePeriodGroup({ periodGroup, index }),
      [handleRemovePeriodGroup]
    )

    return selectedGroupedPeriods.map((periodGroup, index) => {
      const label = generateGroupLabel(periodGroup)
      const isAvailable = checkInventory ? isValidPeriods(periodGroup, selectedQuantity) : true

      return (
        <PeriodItem
          value={{ periodGroup, index }}
          isNotValid={!isAvailable}
          key={label}
          label={label}
          deleteHandler={onSelectedGroupValueRemove}
        />
      )
    })
  }
)

SelectedPeriodsList.propTypes = {
  selectedQuantity: PropTypes.number.isRequired,
  selectedGroupedPeriods: PropTypes.array,
  handleRemovePeriodGroup: PropTypes.func,
  checkInventory: PropTypes.bool
}
export default SelectedPeriodsList
