import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ProgressButton from '../../../../../../components/Form/ProgressButton'

import { downloadFileByBrowser } from '../../../../../../helpers/other'

import {
  clearGetMediaProductsAsCsv,
  getMediaProductsAsCsv
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import {
  getMediaProductsAsCsvErrorSelector,
  getMediaProductsAsCsvIsLoadingSelector,
  getMediaProductsAsCsvSelector,
  getMediaProductsAsCsvWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'

import useStyles from './styles'

const ExportProductsAsCsv = ({ Context }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const isLoading = useSelector(getMediaProductsAsCsvIsLoadingSelector)
  const error = useSelector(getMediaProductsAsCsvErrorSelector)
  const wasLoaded = useSelector(getMediaProductsAsCsvWasLoadedSelector)
  const csvData = useSelector(getMediaProductsAsCsvSelector)

  const csvUrl = csvData?.download_url

  const { exportAsCsvFilters } = useContext(Context)

  const handleOnClick = useCallback(() => {
    dispatch(
      getMediaProductsAsCsv({
        ...exportAsCsvFilters,
        controller: selfAccountControllerId
      })
    )
  }, [dispatch, selfAccountControllerId, exportAsCsvFilters])

  const handleDownloadCSVFile = useCallback(() => {
    downloadFileByBrowser({
      url: csvUrl,
      fileName: 'media_products',
      fileFormat: 'csv'
    })
  }, [csvUrl])

  const clearHandler = useCallback(() => {
    dispatch(clearGetMediaProductsAsCsv())
  }, [dispatch])

  useEffect(() => {
    // clear data on page close, to cancel download task in case it started
    return clearHandler
  }, [clearHandler])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

ExportProductsAsCsv.propTypes = {
  Context: PropTypes.object.isRequired
}

export default ExportProductsAsCsv
