import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import UploadedFilesField from '../../../../../../features/components/UploadedFilesField'

import { getApprovalActionStatus } from '../../../../../../features/components/UploadedFilesField/helpers'
import { useChangeFileStatusOnDisapproved } from '../../../../../../features/hooks/bookedMediaUploadedFilesHooks'

import { updateBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector } from '../../../../../../modules/selectors/app'

import useStyles from './styles'

const UploadedFilesCell = ({ uploadedFiles, bookedMediaId, goToMediaOrderSummaryHandler, additionalRecipients }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  const approveFileHandler = useCallback(
    ({ requirementFileId, uploadedFile }) => {
      dispatch(
        updateBookingMediaFile(uploadedFile?.id, {
          booked_media: uploadedFile?.booked_media,
          requirementFileId,
          approval_status: getApprovalActionStatus(isControllerBuyerApproval, uploadedFile)
        })
      )
    },
    [dispatch, isControllerBuyerApproval]
  )

  const handleChangeStatusOnDisapproval = useChangeFileStatusOnDisapproved()

  if (!uploadedFiles.length) {
    if (additionalRecipients) {
      return <div className={classes.requested}>{t('Requested')}</div>
    } else {
      return null
    }
  }

  return (
    <UploadedFilesField
      uploadedFiles={uploadedFiles}
      bookedMediaId={bookedMediaId}
      onApproveHandler={approveFileHandler}
      onDisapproveHandler={handleChangeStatusOnDisapproval}
      goToMediaOrderSummaryHandler={e => goToMediaOrderSummaryHandler(e, bookedMediaId)}
    />
  )
}

UploadedFilesCell.propTypes = {
  uploadedFiles: PropTypes.array,
  bookedMediaId: PropTypes.number,
  goToMediaOrderSummaryHandler: PropTypes.func,
  additionalRecipients: PropTypes.bool
}

export default UploadedFilesCell
