import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import ActionText from '../../../../../../../../../components/ActionText'

import { darkGrey, greyOutline } from '../../../../../../../../../styles/const/colors'

import { SELECTED_FILES } from '../../../../fields'

const customColorSchema = {
  color: greyOutline,
  hoverColor: darkGrey,
  touchedColor: darkGrey
}

const SelectOrDeselectAllButton = ({ formik, filesWithoutComment }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const isAllSelected = useMemo(() => {
    return filesWithoutComment.every(file => {
      return values[SELECTED_FILES][file.id]
    })
  }, [filesWithoutComment, values])

  const selectAllFilesHandler = useCallback(() => {
    const updatedSelectedFiles = filesWithoutComment.reduce((result, file) => {
      result[file.id] = true
      return result
    }, {})

    setFieldValue(SELECTED_FILES, {
      ...values[SELECTED_FILES],
      ...updatedSelectedFiles
    })
  }, [setFieldValue, filesWithoutComment, values])

  const deselectAllFilesHandler = useCallback(() => {
    const updatedSelectedFiles = filesWithoutComment.reduce((result, file) => {
      result[file.id] = false
      return result
    }, {})

    setFieldValue(SELECTED_FILES, {
      ...values[SELECTED_FILES],
      ...updatedSelectedFiles
    })
  }, [setFieldValue, filesWithoutComment, values])

  return (
    <>
      {!isAllSelected && (
        <ActionText customColorSchema={customColorSchema} onClick={selectAllFilesHandler}>
          {t('Select All')}
        </ActionText>
      )}

      {isAllSelected && (
        <ActionText customColorSchema={customColorSchema} onClick={deselectAllFilesHandler}>
          {t('Deselect All')}
        </ActionText>
      )}
    </>
  )
}

SelectOrDeselectAllButton.propTypes = {
  formik: PropTypes.object,
  filesWithoutComment: PropTypes.array
}

export default SelectOrDeselectAllButton
